export const getUserName = () => {
    return localStorage.getItem('CN_ERP_USERNAME');
}

export const getUserId = () => {
    return localStorage.getItem('CN_ERP_USER_ID');
}

export const setUserName = (name) => {
    localStorage.setItem('CN_ERP_USERNAME', name);
}

export const setUserId = (id) => {
    localStorage.setItem('CN_ERP_USER_ID', id)
}

export const setToken = (token) => {
    localStorage.setItem('CN_ERP_TOKEN', token);
}

export const setRole = (id) => {
    localStorage.setItem('CN_ERP_ROLE', id);
}

export const getToken = () => {
   return localStorage.getItem('CN_ERP_TOKEN');
}

export const setExpiresAt = (expiresAt) => {
    let oldDate = new Date();
    let newDate = new Date();

    newDate = oldDate.getTime() + (expiresAt * 1000);

    localStorage.setItem('CN_ERP_EXPIRES_AT', newDate);
}

export const setLoginEmail = (email) => {
    localStorage.setItem('CN_ERP_LOGIN_EMAIL', email);
}

export const getLoginEmail = () => {
    return localStorage.getItem('CN_ERP_LOGIN_EMAIL');
}

export const setLoginPassword = (password) => {
    localStorage.setItem('CN_ERP_LOGIN_PASSWORD', password);
}

export const getLoginPassword = () =>  {
    return localStorage.getItem('CN_ERP_LOGIN_PASSWORD');
}


export const getExpiresAt = () => {
    return localStorage.getItem('CN_ERP_EXPIRES_AT');
}

export const getRole = () => {
    return localStorage.getItem('CN_ERP_ROLE');
}

export const setTeam = (teamId) => {
    return localStorage.setItem('CN_ERP_TEAM_ID', teamId);
}

export const getTeam = () => {
    return localStorage.getItem('CN_ERP_TEAM_ID');
}

export const setTeamName = (teamName) => {
    return localStorage.setItem('CN_ERP_TEAM_NAME', teamName);
}

export const getTeamName = () => {
    return localStorage.getItem('CN_ERP_TEAM_NAME');
}

export const setTeamLead = (teamLead= false) => {
    return localStorage.setItem('CN_ERP_IS_TEAM_LEAD', teamLead);
}

export const getTeamLead = () => {
    return localStorage.getItem('CN_ERP_IS_TEAM_LEAD');
}


export const storeHistoryLocation = (currentUrl) => {
    // console.log('storing current url ', currentUrl);
     localStorage.setItem('CN_ERP_CURRENT_URL', currentUrl);
}

export const getHistoryLocation = () => {
   return  localStorage.getItem('CN_ERP_CURRENT_URL' );
}

export const removeHistoryLocation = () => {
    localStorage.removeItem('CN_ERP_CURRENT_URL');
}

export const clearLocalStorage = () => {
    localStorage.clear();
    removeHistoryLocation();
}