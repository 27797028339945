import moment from 'moment';

export const calcStageTAT = (leadTime, stageLogs) =>{

    let stagesTAT = {};

    console.log('Moment JS at work')
    console.log(leadTime);


    if(stageLogs.length > 0){
    stageLogs.map((stageLog, index, stageLogs)=>{
        if(index === 0){
            let startTime = moment(leadTime)
            let endTime = moment(stageLog.updated_at)
            let timeDifference = endTime.from(startTime, true)
            console.log('This is the end time');
            console.log(stageLog.updated_at);
            console.log('The time difference is ' )
            console.log(timeDifference);
            stagesTAT[index] = timeDifference;
            console.log('First Moment JS at work');
            console.log(stagesTAT[index]);

            // let currentTime = moment(new Date())
            // stagesTAT[index + 1] = currentTime.from(endTime, true);
            // return timeDifference;
            
        }
        // TODO:: It doesn't show the current stage on the TAT
       else if(stageLogs[index]){
            console.log('In the if statement in the stage Logs');
            let endTime = moment(stageLog.updated_at);
            let startTime = moment(stageLogs[index - 1].updated_at);
        //     if(stageLogs[index -1]){
        //     startTime = moment(stageLogs[index].updated_at)
        // } 
            let timeDifference = endTime.from(startTime, true)
            stagesTAT[index] = timeDifference;
        }
        // else{
        //     stagesTAT[index] = `${moment(stageLogs[index].updated_at).format('L')} till date`;
        // }
    })
    stagesTAT[stageLogs.length] = `${moment(stageLogs[stageLogs.length - 1].updated_at).format('L')} till date`;
} else{
    stagesTAT[0] = `${moment(leadTime).format('L')} till date`;
}

    
    console.log('Moment JS at work');
    console.log(stagesTAT)
    // console.log(stagesTAT[returnIndex]);

    // console.log(leadTime);
    return stagesTAT;

}