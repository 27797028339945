import {useState, useEffect} from 'react';
import {addLabelToArray, isStage} from '../helpers';
import {useApi} from '../context';
import {mergeStages} from './useLeads';

export function useTeams(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [teams, setTeams] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getTeams();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setTeams(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [teams, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {teams, isLoading, hasError}
}

export function useTeamDetail({id, refreshPage}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [team, setTeam] = useState({});
    const [users, setUsers] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getTeamDetail(id);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setTeam(data)
                    setUsers(data.users)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [team, users, isLoading, hasError ]
        }
    }, [refreshPage])

    return {team, users, isLoading, hasError}
}

export function useTeamCategory(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [category, setCategory] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getTeamCategories();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    let labelledData = (addLabelToArray(data));
                    setCategory(labelledData)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [category, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {category, isLoading, hasError}
}

export function useOneDepartment({id, refreshPage}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [department, setDepartment] = useState({});
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneTeamCategory(id);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setDepartment(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [department, isLoading, hasError ]
        }
    }, [refreshPage])

    return {department, isLoading, hasError}
}

export function useTeamExpiredLeads(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [leads, setLeads] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getTeamExpiredLead(props.id);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setLeads(mergeStages(data))
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [leads, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {leads, isLoading, hasError}
}

export function useTeamExpiredLeadsRequest(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [leads, setLeads] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getExpiredLeadRequests();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setLeads(mergeStages(data))
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [leads, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {leads, isLoading, hasError}
}

export function useAllTeams(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [teams, setTeams] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAllTeams();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setTeams(data.teams)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [teams, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {teams, isLoading, hasError}
}

export function useAllTeamCategory(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [category, setCategory] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAllTeamCategories();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    let labelledData = (addLabelToArray(data));
                    setCategory(labelledData)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [category, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {category, isLoading, hasError}
}
