import {useState, useEffect} from 'react';
import {addLabelToArray} from '../helpers';
import {useApi} from '../context';

export function useDeadLeads(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [deadLeads, setDeadLeads] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getDeadLeads();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setDeadLeads(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [deadLeads, isLoading, hasError ]
        }
    }, [])

    return {deadLeads, isLoading, hasError}
}

export function useOneDeadLead(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [deadLeads, setDeadLeads] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneDeadLead(props.id);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setDeadLeads(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [deadLeads, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {deadLeads, isLoading, hasError}
}

export function useOneInactiveLead(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [inactiveLeads, setInactiveLeads] = useState({});
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getInactiveLeadComment(props.id);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setInactiveLeads(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [inactiveLeads, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {inactiveLeads, isLoading, hasError}
}

export function useTelesalesMonthlyReport(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [reports, setReports] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getTelesalesMonthlyReport(props.period, props.year);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setReports(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [reports, isLoading, hasError ]
        }
    }, [props.period, props.year])

    return {reports, isLoading, hasError}
}

export function useOldTelesalesMonthlyReport(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [reports, setReports] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOldTelesalesMonthlyReport(props.period, props.year);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setReports(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [reports, isLoading, hasError ]
        }
    }, [props.period, props.year])

    return {reports, isLoading, hasError}
}


export function useInactiveLeads(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [inactiveLeads, setInactiveLeads] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getInactiveLeads(props.status);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setInactiveLeads(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [inactiveLeads, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {inactiveLeads, isLoading, hasError}
}

export function useInactiveReassignmentRequest(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [inactiveLeads, setInactiveLeads] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getInactiveReassignmentRequests();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setInactiveLeads(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [inactiveLeads, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {inactiveLeads, isLoading, hasError}
}
