import {useState, useEffect} from 'react';
import {useApi} from '../context';

export function usePricingPlans(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [pricingPlans, setPricingPlans] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getPricingPlans();

            if(results.statusCode === 200) {
                let {data, message} = results;

                setPricingPlans(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [pricingPlans, isLoading, hasError ]
        }
    }, [])

    return {pricingPlans, isLoading, hasError}
}