import {useState, useEffect} from 'react';
import {addLabelToArray, isStage} from '../helpers';
import {useApi} from '../context';

export function useEmails(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [emails, setEmails] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getEmailCategories();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setIsLoading(false)
                  
                    setEmails(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [emails, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {emails, isLoading, hasError}
}


export function useOneEmail(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState({});
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneEmailCategory(props.id);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    setEmail(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [email, isLoading, hasError ]
        }
    }, [])

    return {email, isLoading, hasError}
}

export function useReminderEmails(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [emails, setEmails] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getReminderEmailDetails(props.accountId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    setEmails(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [emails, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {emails, isLoading, hasError}
}