import React, {useState} from 'react';
import {Form, Alert, Input, Button} from 'antd';
import {useSalesExecutives} from '../../../../hooks';
import {getUserId} from '../../../../helpers'

import {FormGroup} from '../../../units/utils';
import {useApi, usePageValue} from '../../../../context';
import {Select, Loader, Error} from '../../../units/atoms';

const defaultSelectWidth = 300;

export default function AssignLead(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    let api = useApi();
    let {setPageLoading, setPageError, setPageSuccess, setMessage} = usePageValue();
    let {executives} = useSalesExecutives();

    const [form] = Form.useForm();

    setPageLoading(false);
    const onFinish = values => {
        
        let data = {
            lead_id : props.leadId,
            assigner_id: getUserId(),
            assignee: values.assignee
        }
        const postForm = async() => {
            setErrorMessage(false);
            setIsLoading(true)
            let results ;
            if(props.extensionRequest){
                results = await api.assignLead(data, 'request=expired_lead');
            } else if(props.requalificationRequest){
                results = await api.assignLead(data, 'request=requalification');
            } else if (props.reassignment) {
                results = await api.assignLead(data, 'request=reassignment');
            }  else if (props.extension) {
                results = await api.assignLead(data, 'request=extension');
            }
            else{
                results = await api.assignLead(data);
            }
           
            
            let {statusCode} = results;
            
            console.log(statusCode);
            setIsLoading(false);
            if(statusCode === 200) {
                form.resetFields();
                let name = results.data.name;

                if(props.inactiveLead){
                    api.approveReasonForInactive(props.inactiveLeadId);
                }

                setMessage('Lead has been assigned to ' + name);
                setPageSuccess(true);
                props.closeModal(false);
                props.refreshVariable(Math.random() * 100);
            }
            else{
                setErrorMessage(results.error);
            }
        }

        postForm();
    }

    return (
        <div>
            {isLoading && <Loader />}
            {errorMessage && <Error message={errorMessage}/>}
            <Form
      // {...layout}
      id="assignLeadForm"
      layout="vertical"
      name="basic"
      initialValues={{
        remember: true,
      }}
      form={form}
      onFinish={onFinish}
      size={'large'}
      // onFinishFailed={onFinishFailed}
    >

<FormGroup 
    name={'assignee'}
    label={'Assign To'}
    required= {true}>
        
             
        <Select  style={{ width: defaultSelectWidth }}>
                {executives}
    </Select> 
       
            </FormGroup>
    </Form>
            
        </div>
    )
}
