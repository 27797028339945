import React, { Suspense, lazy, useContext } from "react";
// import Login from './components/authentication/Login'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ViewTeams from "./components/pages/team/ViewTeams";

import { useAuth } from "./context";
import { getRole } from "./helpers";
// ================ LOGIN CONTEXT =================
import { LoginProvider } from "./context";
import EditDept from "./components/pages/department/EditDept";
import RenewalAccounts from "./components/pages/renewal/RenewalAccounts";
import ViewContacts from "./components/pages/contacts/ViewContacts";
import RenewalInvoiceDetail from "./components/pages/renewal/RenewalInvoiceDetail";

const Login = lazy(() => import("./components/authentication/Login"));

// ================ LEADS =====================
const CreateLead = lazy(() => import("./components/pages/leads/CreateLead"));
const ViewLeads = lazy(() => import("./components/pages/leads/ViewLeads"));
const LeadDetails = lazy(() => import("./components/pages/leads/LeadDetail"));
const ViewExpiredLeads = lazy(() =>
  import("./components/pages/leads/ViewExpiredLeads")
);
const ViewInactiveLeads = lazy(() =>
  import("./components/pages/leads/ViewInactiveLeads")
);

// ================= SALES ====================
const SalesDetails = lazy(() =>
  import("./components/pages/sales/SalesDetails")
);
const SalesDetailsBC = lazy(() =>
  import("./components/pages/sales/SalesDetailsBC")
);
const ViewSales = lazy(() => import("./components/pages/sales/ViewSales"));
const ExpiredLeads = lazy(() =>
  import("./components/pages/sales/ExpiredLeads")
);
const UrgentLeads = lazy(() => import("./components/pages/sales/UrgentLeads"));

// ================== INVOICE ==================
const CreateInvoice = lazy(() =>
  import("./components/pages/invoice/CreateInvoice")
);
const ViewInvoice = lazy(() =>
  import("./components/pages/invoice/InvoiceDetail")
);

// =============REQUEST=============
const ExtensionRequest = lazy(() =>
  import("./components/pages/requests/ExtensionRequest")
);
const RequalificationRequest = lazy(() =>
  import("./components/pages/requests/RequalificationRequest")
);
const LeadAdvancementRequest = lazy(() =>
  import("./components/pages/requests/LeadAdvancementRequest")
);
const ReassignmentRequest = lazy(() =>
  import("./components/pages/requests/ReassignmentRequest")
);

const ProductChangeRequest = lazy(() => 
    import("./components/pages/requests/ProductChangeRequest")
);

const InactiveLeadRequest = lazy(() => 
    import("./components/pages/requests/InactiveLeadRequests")
);

const InactiveLeadAdvancementRequest = lazy(() => 
      import("./components/pages/requests/InactiveLeadAdvancementRequest"));

      const ViewInactiveReassignmentRequest = lazy(() => import("./components/pages/requests/InactiveReassignmentRequest"));

// ============ TAT ==================
const CreateTat = lazy(() => import("./components/pages/tat/CreateTat"));
const RequalificationTat = lazy(() =>
  import("./components/pages/tat/requalification/CreateTat"));

// ============ USER ==================
const ViewUsers = lazy(() => import("./components/pages/users/ViewUsers"));
const CreateUser = lazy(() => import("./components/pages/users/CreateUser"));
const UserDetail = lazy(() => import("./components/pages/users/UserDetail"));
const EditUser = lazy(() => import("./components/pages/users/EditUser"));

// =============== ROLE ==================
const ViewRoles = lazy(() => import("./components/pages/roles/ViewRoles"));
const CreateRole = lazy(() => import("./components/pages/roles/CreateRole"));

// =============== DASHBOARD =============
const AdminDashboard = lazy(() =>
  import("./components/pages/dashboard/AdminDashboard")
);
const SalesDashboard = lazy(() =>
  import("./components/pages/dashboard/SalesDashboard")
);

// ============== RENEWAL MGT =============
const ViewRenewal = lazy(() =>
  import("./components/pages/renewal/ViewRenewals")
);
const RenewalDetail = lazy(() =>
  import("./components/pages/renewal/RenewalDetail")
);
const RenewalFees = lazy(() =>
  import("./components/pages/renewal/ViewRenewalFees")
);
const EditRenewal = lazy(() =>
  import("./components/pages/renewal/EditRenewalFee")
);
const RenewalInvoice = lazy(() => import("./components/pages/renewal/RenewalInvoiceDetail"));

const PreviewEmail = lazy(() =>
  import("./components/pages/renewal/PreviewEmail")
);

const CustomerChurnRate = lazy(() =>
  import("./components/pages/renewal/CustomerChurnRate")
);

const ViewRenewalAccount = lazy(() =>
  import("./components/pages/renewal/RenewalAccounts")
);

const PendingRenewalDeals = lazy(() =>
  import("./components/pages/renewal/ApproveNewDeal")
);

const CompleteDealSubscriptionInformation = lazy(() =>
  import("./components/pages/renewal/CompleteDealSubscriptionInformation")
);


// ============== REMINDER MGT =============
const CreateReminder = lazy(() =>
  import("./components/pages/reminders/CreateReminder")
);
const ViewReminder = lazy(() =>
  import("./components/pages/reminders/ViewReminders")
);
// =============== TEAM MANAGEMENT =============
const CreateTeam = lazy(() => import("./components/pages/team/CreateTeam"));
const ViewTeam = lazy(() => import("./components/pages/team/ViewTeams"));
const TeamDetail = lazy(() => import("./components/pages/team/TeamDetails"));
const EditTeam = lazy(() => import("./components/pages/team/EditTeam"));
const TeamBudget = lazy(() => import("./components/pages/team/TeamBudget"));

// =============== DEPT MANAGEMENT =============
const ViewDepts = lazy(() => import("./components/pages/department/ViewDepts"));
const CreateDept = lazy(() =>
  import("./components/pages/department/CreateDept")
);

// =============== VARIABLE MANAGEMENT =============
const CreateVariable = lazy(() =>
  import("./components/pages/variables/CreateVariable")
);

// =============== REPORTS =============
const ExtensionReport = lazy(() =>
  import("./components/pages/reports/ExtensionReport")
);
const ReportDashboard = lazy(() =>
  import("./components/pages/reports/ReportDashboard")
);
const TeamExtensionReport = lazy(() =>
  import("./components/pages/reports/TeamExtensionReport")
);

const AllExtensionsReport = lazy(() =>
  import("./components/pages/reports/AllExtensionsReport")
);

const IndividualExtensionReport = lazy(() =>
  import("./components/pages/reports/IndividualExtensionReport")
);

// =============== GRAPHICAL REPORT =============
const InboundAndOutbound = lazy(() =>
  import("./components/pages/graphicalReport/InactiveReportGraphs")
);

const PerformanceReport = lazy(() => import("./components/pages/graphicalReport/PerformanceReport"));

const EngagementReport = lazy(() => import("./components/pages/graphicalReport/MeaningfulEngagementReport"));

// =============== INDUSTRY MGT =============
const ViewIndustries = lazy(() =>
  import("./components/pages/industry/ViewIndustries")
);
const CreateIndustry = lazy(() =>
  import("./components/pages/industry/CreateIndustry")
);
const EditIndustry = lazy(() =>
  import("./components/pages/industry/EditIndustry")
);

// =============== EMAIL TEMPLATES =============
const CreateEmail = lazy(() => import("./components/pages/emails/CreateEmail"));
const ViewCategories = lazy(() =>
  import("./components/pages/emails/ViewCategories")
);
const EditCategories = lazy(() =>
  import("./components/pages/emails/EditEmail")
);

// =============== TEAM EXPIRED LEADS =============
const ViewExpiredTeams = lazy(() =>
  import("./components/pages/expiredleads/ViewTeams")
);
const TeamExpiredLead = lazy(() =>
  import("./components/pages/expiredleads/TeamExpiredLead")
);
const TeamExpiredLeadRequest = lazy(() =>
  import("./components/pages/expiredleads/TeamExpiredLeadRequest")
);

// =============== TELESALES REPORT=============
const TelesalesComment = lazy(() =>
  import("./components/pages/telesales/ViewComments")
);
const TelesalesLeads = lazy(() =>
  import("./components/pages/telesales/ViewLeads")
);
const TelesalesReports = lazy(() =>
  import("./components/pages/telesales/ViewTelesalesReport")
);
const TelesalesOldReports = lazy(() =>
  import("./components/pages/telesales/ViewOldTelesalesReport")
);

const ExpiredControlAccounts = lazy(() => import("./components/pages/telesales/ViewExpiredSubscription"));

const InactiveLeads = lazy(() => import("./components/pages/telesales/inactive/ViewInactiveLeads"));
const ViewInactiveLeadActivities = lazy(() => import("./components/pages/telesales/inactive/ViewLeadActivities"));
const ViewInactiveLeadActivitiesNoEdit = lazy(() => import("./components/pages/telesales/inactive/ViewLeadActivitiesNoEdit"))


// =============== COMPANY =============
const ViewCompanies = lazy(() =>
  import("./components/pages/company/ViewCompanies")
);
// =============== MEDIUM =============
const CreateMedium = lazy(() => import("./components/pages/medium/CreateMedium"));
const ViewMedium = lazy(() => import("./components/pages/medium/ViewMedium"));

// =============== CHANNEL =============
const ViewChannels = lazy(() => import("./components/pages/channels/ViewChannel"));
const CreateChannel = lazy(() => import("./components/pages/channels/CreateChannel"))

// =============== PRODUCT =============
const ViewProducts = lazy(() => import("./components/pages/products/ViewProducts"));
const CreateProduct = lazy(() => import("./components/pages/products/CreateProduct"));
const ViewOneProduct = lazy(() => import("./components/pages/products/ViewOneProduct"));

// =============== CONTROL =============
const CreateDictionary = lazy(() => import("./components/pages/control/CreateDictionary"));
const CreateDictionaryNew = lazy(() => import("./components/pages/control/CreateDirectoryNew"));
const ViewDictionary = lazy(() => import("./components/pages/control/ViewDictionary"));
const ControlAccountDetail = lazy(()=> import("./components/pages/control/ControlAccountDetail"));
const AccountDevices = lazy(()=> import("./components/pages/control/AccountDevices"));
const UploadData = lazy(() => import("./components/pages/control/UploadData"));


// =============== NOT FOUND =============
const NotFound = lazy(() => import("./components/pages/notFound/NotFoundPage"));


function App() {
  const baseUrl = process.env.PUBLIC_URL;

  let { isAuthenticated } = useAuth();

  return (
    <BrowserRouter basename={baseUrl}>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <AuthenticatedRoute path="/" exact>
            {Number(getRole() === 7) ? <AdminDashboard /> : <SalesDashboard />}
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/salesdash" exact>
            <SalesDashboard />
          </AuthenticatedRoute>
          <Route path="/login" exact>
            <Login />
          </Route>

          {/* LEADS ROUTES   */}
          <AuthenticatedRoute path="/lead/create" exact>
            <CreateLead />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/leads" exact>
            <ViewLeads />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/leads/expired" exact>
            <ViewExpiredLeads />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/leads/inactive" exact>
            <ViewInactiveLeads />
          </AuthenticatedRoute>

          <Route
            path="/leads/:leadId"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <>
                  <LeadDetails {...routerProps} />
                </>
              ) : (
                <Login />
              )
            }
          />

          <AuthenticatedRoute path="/sales" exact>
            <ViewSales />
          </AuthenticatedRoute>

          <Route
            path="/sales/:leadId"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <>
                  <SalesDetails {...routerProps} />
                </>
              ) : (
                <Login />
              )
            }
          />

          <Route
            path="/sales/:leadId/reassignment"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <>
                  <SalesDetails {...routerProps} reassign={true} />
                </>
              ) : (
                <Login />
              )
            }
          />

          <AuthenticatedRoute path="/expiredLeads" exact>
            <ExpiredLeads />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/urgentLeads" exact>
            <UrgentLeads />
          </AuthenticatedRoute>

          {/* <Route
            path="/salesBC/:leadId"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <>
                  <SalesDetailsBC {...routerProps} />
                </>
              ) : (
                <Login />
              )
            }
          /> */}

          {/* INVOICE ROUTE */}
          <Route
            path="/invoice/create/:leadId"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <>
                  <CreateInvoice {...routerProps} />
                </>
              ) : (
                <Login />
              )
            }
          />

          <Route
            path="/invoice/:invoiceId"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <>
                  <ViewInvoice {...routerProps} />
                </>
              ) : (
                <Login />
              )
            }
          />

          {/* DASHBOARD ROUTES */}
          <AuthenticatedRoute path="/admin/dashboard" exact>
            <AdminDashboard />
          </AuthenticatedRoute>

          {/* REQUESTS ROUTES   */}
          <AuthenticatedRoute path="/request/extension" exact>
            <ExtensionRequest />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/request/requalification" exact>
            <RequalificationRequest />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/request/advancement" exact>
            <LeadAdvancementRequest />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/request/reassignment" exact>
            <ReassignmentRequest />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/request/product" exact>
            <ProductChangeRequest />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/request/inactive-lead" exact>
            <InactiveLeadRequest />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/request/telerequests" exact>
            <InactiveLeadAdvancementRequest />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/request/inactive-lead-reassignment" exact>
            <ViewInactiveReassignmentRequest />
          </AuthenticatedRoute>

          {/* TAT ROUTES */}
          <AuthenticatedRoute path="/tat/create" exact>
            <CreateTat />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/requalificationTat/create" exact>
            <RequalificationTat />
          </AuthenticatedRoute>

          {/* USER ROUTES */}
          <AuthenticatedRoute path="/users" exact>
            <ViewUsers />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/users/create" exact>
            <CreateUser />
          </AuthenticatedRoute>
          <Route
            path="/users/:id"
            exact
            render={(routerProps) =>
              isAuthenticated() ? <UserDetail {...routerProps} /> : <Login />
            }
          />

          <Route
            path="/users/:id/edit"
            exact
            render={(routerProps) =>
              isAuthenticated() ? <EditUser {...routerProps} /> : <Login />
            }
          />

          {/* ROLE ROUTES */}
          <AuthenticatedRoute path="/roles" exact>
            <ViewRoles />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/roles/create" exact>
            <CreateRole />
          </AuthenticatedRoute>

          {/*======== RENEWALS ========= */}
          <AuthenticatedRoute path="/accounts" exact>
            <ViewRenewal />
          </AuthenticatedRoute>
          {/* <AuthenticatedRoute path="/churnRate" exact>
            <CustomerChurnRate />
          </AuthenticatedRoute> */}

          <Route
            path="/churnRate/:category"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <CustomerChurnRate {...routerProps} />
              ) : (
                <Login />
              )
            }
          />


      <AuthenticatedRoute path="/pending-renewal-deals" exact>
            <PendingRenewalDeals />
          </AuthenticatedRoute>

          <Route
            path="/pending-renewal-deals/:accountDealId"
            exact
            render={(routerProps) =>
              isAuthenticated() ? <CompleteDealSubscriptionInformation {...routerProps} /> : <Login />
            }
          />

          <AuthenticatedRoute path="/renewalAccounts" exact>
            <RenewalAccounts />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/renewals/fees" exact>
            <RenewalFees />
          </AuthenticatedRoute>
          <Route
            path="/renewaldetail/:accountId"
            exact
            render={(routerProps) =>
              isAuthenticated() ? <RenewalDetail {...routerProps} /> : <Login />
            }
          />

          <Route
            path="/editrenewal/:id"
            exact
            render={(routerProps) =>
              isAuthenticated() ? <EditRenewal {...routerProps} /> : <Login />
            }
          />

        <Route
            path="/renewal-invoice/:id"
            exact
            render={(routerProps) =>
              isAuthenticated() ? <RenewalInvoiceDetail {...routerProps} /> : <Login />
            }
          />

          <Route
            path="/renewals/previewEmail"
            exact
            render={(routerProps) =>
              isAuthenticated() ? <PreviewEmail {...routerProps} /> : <Login />
            }
          />

          {/*======== REMINDERS ========= */}
          <AuthenticatedRoute path="/reminder/create" exact>
            <CreateReminder />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/reminders" exact>
            <ViewReminder />
          </AuthenticatedRoute>

          {/*======== TEAM MANAGEMENT ========= */}
          <AuthenticatedRoute path="/createTeam" exact>
            <CreateTeam />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/teams/create-budget" exact>
            <TeamBudget />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/teams" exact>
            <ViewTeams />
          </AuthenticatedRoute>

          <Route
            path="/teams/:id"
            exact
            render={(routerProps) =>
              isAuthenticated() ? <TeamDetail {...routerProps} /> : <Login />
            }
          />

          <Route
            path="/teams/:id"
            exact
            render={(routerProps) =>
              isAuthenticated() ? <TeamDetail {...routerProps} /> : <Login />
            }
          />

          <Route
            path="/editTeam/:id"
            exact
            render={(routerProps) =>
              isAuthenticated() ? <EditTeam {...routerProps} /> : <Login />
            }
          />

          {/*======== MEDIUM MANAGEMENT ========= */}
          <AuthenticatedRoute path="/medium/create" exact>
            <CreateMedium />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/medium" exact>
            <ViewMedium />
          </AuthenticatedRoute>

            {/*======== CHANNEL MANAGEMENT ========= */}
            <AuthenticatedRoute path="/channel/create" exact>
            <CreateChannel />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/channels" exact>
            <ViewChannels />
          </AuthenticatedRoute>

          {/*======== VARIABLES MANAGEMENT ========= */}
          <AuthenticatedRoute path="/variables" exact>
            <CreateVariable />
          </AuthenticatedRoute>

          {/*======== REPORT ========= */}
          <AuthenticatedRoute path="/extensionReports" exact>
            <ExtensionReport />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/reports" exact>
            <ReportDashboard />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/allExtensionReport" exact>
            <AllExtensionsReport />
          </AuthenticatedRoute>

          <Route
            path="/allExtensionReport/:userId/:userName"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <IndividualExtensionReport {...routerProps} />
              ) : (
                <Login />
              )
            }
          />

          <Route
            path="/extensionReport/:teamId/:teamName"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <TeamExtensionReport {...routerProps} />
              ) : (
                <Login />
              )
            }
          />

          {/*======== GRAPHICAL REPORT ========= */}
          <AuthenticatedRoute path="/graph/team" exact>
            <InboundAndOutbound />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/graph/performance" exact>
            <PerformanceReport />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/graph/engagement" exact>
            <EngagementReport />
          </AuthenticatedRoute>
          
          {/*======== INDUSTRIES ========= */}
          <AuthenticatedRoute path="/industries" exact>
            <ViewIndustries />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/industry/create" exact>
            <CreateIndustry />
          </AuthenticatedRoute>

          <Route
            path="/industry/:id/edit"
            exact
            render={(routerProps) =>
              isAuthenticated() ? <EditIndustry {...routerProps} /> : <Login />
            }
          />

          {/*======== EMAILS ========= */}
          <AuthenticatedRoute path="/emails/create" exact>
            <CreateEmail />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/emails/view" exact>
            <ViewCategories />
          </AuthenticatedRoute>

          <Route
            path="/emails/:id/edit"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <EditCategories {...routerProps} />
              ) : (
                <Login />
              )
            }
          />

          {/*======== DEPT MANAGEMENT ========= */}
          <AuthenticatedRoute path="/departments" exact>
            <ViewDepts />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/departments/create" exact>
            <CreateDept />
          </AuthenticatedRoute>

          <Route
            path="/departments/:id/edit"
            exact
            render={(routerProps) =>
              isAuthenticated() ? <EditDept {...routerProps} /> : <Login />
            }
          />

          {/*======== EXPIRED TEAM LEAD ========= */}
          <AuthenticatedRoute path="/expiredTeams" exact>
            <ViewExpiredTeams />
          </AuthenticatedRoute>

          <Route
            path="/expiredTeams/:teamId/:name"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <TeamExpiredLead {...routerProps} />
              ) : (
                <Login />
              )
            }
          />

          {/*======== TELESALES  ========= */}
          <AuthenticatedRoute path="/telesales" exact>
            <TelesalesLeads />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/telesales/expired-control-accounts" exact>
            <ExpiredControlAccounts />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/telesales/report" exact>
            <TelesalesReports />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/telesales/oldReport" exact>
            <TelesalesOldReports />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/telesales/inactive-leads" exact>
            <InactiveLeads />
          </AuthenticatedRoute>

          <Route
            path="/telesales/inactive-leads/:leadId/:name"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <ViewInactiveLeadActivities {...routerProps} />
              ) : (
                <Login />
              )
            }
          />

<Route
            path="/telesales/inactive-leads/:leadId/:name/view"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <ViewInactiveLeadActivitiesNoEdit {...routerProps} />
              ) : (
                <Login />
              )
            }
          />

          <Route
            path="/telesales/:leadId/:name"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <TelesalesComment {...routerProps} />
              ) : (
                <Login />
              )
            }
          />

          <AuthenticatedRoute path="/expiredTeams/requests" exact>
            <TeamExpiredLeadRequest />
          </AuthenticatedRoute>

          {/*======== COMPANIES  ========= */}
          
          <AuthenticatedRoute path="/companies" exact>
            <ViewCompanies />
          </AuthenticatedRoute>


          {/*======== CONTACTS  ========= */}
          
          <AuthenticatedRoute path="/contacts" exact>
            <ViewContacts />
          </AuthenticatedRoute>

          {/* // =============== PRODUCT ============= */}
          <AuthenticatedRoute path="/products" exact>
            <ViewProducts />
          </AuthenticatedRoute>
          <AuthenticatedRoute path="/create-product" exact>
            <CreateProduct />
          </AuthenticatedRoute>

          <Route
            path="/product/:productId"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <ViewOneProduct {...routerProps} />
              ) : (
                <Login />
              )
            }
          />

          {/*======== CONTROL  ========= */}
          <AuthenticatedRoute path="/control/create-dictionary" exact>
            <CreateDictionary />
          </AuthenticatedRoute>

          <AuthenticatedRoute path="/control/upload" exact>
            <UploadData />
          </AuthenticatedRoute>


          <Route
            path="/control/create-dictionary-new/:deviceId"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <CreateDictionaryNew {...routerProps} />
              ) : (
                <Login />
              )
            }
          />

          <AuthenticatedRoute path="/control/view-dictionary" exact>
            <ViewDictionary />
          </AuthenticatedRoute>

          <Route
            path="/control/devices/:renewalAccountId"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <AccountDevices {...routerProps} />
              ) : (
                <Login />
              )
            }
          />

            <Route
            path="/control/detail/:directoryId"
            exact
            render={(routerProps) =>
              isAuthenticated() ? (
                <ControlAccountDetail {...routerProps} />
              ) : (
                <Login />
              )
            }
          />

          {/*========== NOT FOUND ===========*/}
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;

function AuthenticatedRoute({ ...props }) {
  console.log(props);
  let { isAuthenticated } = useAuth();
  {
    return <>{isAuthenticated ? <Route {...props} /> : <Login path={props.path}/>}</>;
  }
}
