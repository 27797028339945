import {useState, useEffect} from 'react';
import {addLabelToArray} from '../helpers';


export default function useFetch ({callbackFunction, refreshHook, queryParams, addLabel, superRefresh, ...props}) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState();
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

            if(queryParams){
               results = await callbackFunction(queryParams);
            }
            else{
                results = await callbackFunction();
            } 

            if(results.statusCode === 200) {
                let {data, message} = results;

                if(addLabel) {
                    setData(addLabelToArray(data));
                } else{
                    setData(data);
                }
            } 
            else {
                let {error} = results;
                setHasError(error);
            }

            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [data, isLoading, hasError ]
        }
    }, [refreshHook, superRefresh])

    return {data, isLoading, hasError}
}