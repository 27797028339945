import React, {useState, useEffect} from 'react';
import 'antd/dist/antd.css';
import {Form, Row, Input, Select} from 'antd';


import {FormGroup, FullFormGroup} from '../../utils'
import {useCountryStates, useIndustries, useFetch} from '../../../../hooks'
import {ModalButton} from '../../atoms'
import CreateTagline from './CreateTagline';
import {useApi, usePageValue, useModalValue} from '../../../../context';
import {Modal} from '../modals'
import {addLabelToArray} from '../../../../helpers';

export default function CreateCompany(props) {
  let api = useApi();

  const [countryId, setCountryId] = useState(160);
  const [refreshHook, setRefreshHook] = useState(0);
  const [countryStates, setCountryStates] = useState([]);
    // const countries = useCountries();
    let {setPageLoading, setPageError, setPageSuccess, setMessage} = usePageValue();
    let countries = useFetch({callbackFunction: api.getCountries, addLabel: true});
   
    let {setIsModalLoading} = useModalValue();

    const {industries} = useIndustries();

    const [taglineModal, setTaglineModal] = useState(false);

    const [form] = Form.useForm();

    useEffect(() => {
      
      return () => {
        return [countries, countryStates, industries]
      }
    }, [])


    const onFinish = values => {
        console.log(values);
      
        let data = {
          name: values.name,
          address: values.address,
          email: values.email,
          telephone: values.phone,
          city: values.city,
          state: values.countryState,
          country: values.country,
          tagline: [values.industries],
          website: values.website
        }

        const postForm = async () => {
          setIsModalLoading(true);
          let results = await api.postCompanies(data);
          let {statusCode} = results;
          setPageLoading(false);
          setIsModalLoading(false);
          if(statusCode === 200) {
            setMessage('Company created');
            setPageSuccess(true);
            form.resetFields();
            props.refreshComp()
               props.closeModal(false);
        } else if(statusCode === 422 ) {
          console.log(results);
          let error = results.error.data;
          // let errorMessage = Object.keys(error)[0];
         console.log(error);
          setMessage(error);
          setPageError(true);
         } else {
          setMessage('An error occurred, try again');
          setPageError(true);
        }
      }

        postForm()
      }

      const { Option } = Select;
      const defaultSelectWidth = 220;

      const handleCountryChange = (selectedOption) => {
        console.log(selectedOption);
        setCountryId(selectedOption);
        // setRefreshHook(refreshHook + 1)
        fetchStates(selectedOption);
      }

      const fetchStates =  async (id) => {
        let results = await api.getStates(id);

        if (results.statusCode === 200) {
          let {data, message} = results;
          setCountryStates(addLabelToArray(data));
        }
      }

    return (
        <div>

<Modal 
 visible={taglineModal}
 title="New Tagline"
//  onOk={this.handleOk}
 onCancel={setTaglineModal}>
 
<CreateTagline />
</Modal>
   
     
                  <Form
    //   {...layout}
    form={form}
      id="companyForm"
      layout="vertical"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      size={'large'}
      // onFinishFailed={onFinishFailed}
    >
        <Row >
        <FullFormGroup 
            name={'name'}
            label={'Company Name'}
            required= {true} >
           <Input />
            </FullFormGroup>
        </Row>

        <Row gutter={4}>
        <FormGroup 
            name={'email'}
            label={'Company Email'}
            >
           <Input />
            </FormGroup>

            <FormGroup 
            name={'phone'}
            label={'Phone Number'}
           >
           <Input />
            </FormGroup>
        </Row>

        <Row gutter={4}>
        <FullFormGroup 
            name={'address'}
            label={'Address'}
            required= {true}>
           <Input style={{width: '100%'}} />
            </FullFormGroup>
        </Row>


        <Row gutter={4}>
        <FormGroup 
            name={'country'}
            label={'Country'}
            required= {true}>

             <Select   style={{ width: defaultSelectWidth }} 
             showSearch
              onChange={handleCountryChange}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              >
                { countries.data &&
                  countries.data.map(cat => (<Option value={cat.value}>{cat.label}</Option>))
                }
    </Select> 
            </FormGroup>

            <FormGroup 
            name={'countryState'}
            label={'State'}
            required= {true}>
              
            <Select   style={{ width: defaultSelectWidth }} 
              // onChange={handleChange}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              >
                { countryStates &&
                  countryStates.map(cat => (<Option value={cat.value}>{cat.label}</Option>))
                }
    </Select> 
            </FormGroup>

            
        </Row>
        
        <Row gutter={4}>
        <FormGroup 
            name={'city'}
            label={'City'}
            required= {true}>
           <Input />
            </FormGroup>

            <FormGroup 
            name={'website'}
            label={'Company Website'}
            required= {false}>
           <Input />
            </FormGroup>
        </Row>

        <Row gutter={4}>
        <FormGroup 
            name={'industries'}
            label={'Tagline'}
            required= {true}>
            <Select   style={{ width: defaultSelectWidth }} 
              // onChange={handleChange}
              >
                {
                  industries.map(cat => (<Option value={cat.value}>{cat.label}</Option>))
                }
    </Select> 
    {/* <ModalButton label="New Tagline" onClick={() => setTaglineModal(!taglineModal)} /> */}
            </FormGroup>
        </Row>
        </Form> 


        </div>
    )
}
