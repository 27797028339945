export function getAvatarText(inputString) {
    if (!inputString){
        return
    }

    let splitString = inputString.split(' ');
    let firstLetters  = splitString.map(string => {
        return string.substring(0, 1);
    })
    return firstLetters.join('').toUpperCase();
}