import React, {useState} from 'react';
import {Form, Alert, Input, Button} from 'antd';

import {FormGroup} from '../../../units/utils';
import { useProducts} from '../../../../hooks'
import {useApi, usePageValue} from '../../../../context';

import {Select, Loader, Error} from '../../../units/atoms';


export default function ProductChange(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    let api = useApi();
    let {setPageLoading, setPageError, setPageSuccess, setMessage} = usePageValue();

    let {products} = useProducts()
    
    const onFinish = values => {
        
        let data = {
            lead_id: props.leadId,
            reason: values.reason,
            new_product_id: values.assigned,
            old_product_id: props.oldProductId
        }

        // console.log(values);

        const postForm = async() => {
            setIsLoading(true)
            setErrorMessage(false);
            let results = await api.postProductChange(data);
            
            let {statusCode} = results;
            setIsLoading(false);
            console.log(results);

            if(statusCode === 200) {
                setMessage('Product change request sent')
                setPageSuccess(true);
                props.closeModal(false)
            }
            else{
                console.log(results.error);
                setErrorMessage(results.error)
                // setMessage(results.error);
                // setPageError(true);
                
            }
        }

        postForm();
    }

    return (
        <div>
            {isLoading && <Loader />}
            {errorMessage && <Error message={errorMessage}/>}
            <Form
      // {...layout}
      id="reassignmentRequest"
      layout="vertical"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      size={'large'}
      // onFinishFailed={onFinishFailed}
    >

<FormGroup 
    name={'assigned'}
    label={'Change Product to'}
    required= {true}>
        
        <Select
          
          size={'400px'}
          placeholder="Select"
        showSearch
          style={{ width: '400px' }}
        >
          
          {products}
        </Select>
       
            </FormGroup>

<FormGroup 
    name={'reason'}
    label={'Enter Reason'}
    required= {true}>
        
        <Input placeholder="My reason is" 
        style={{width: '400px'}}/>
       
            </FormGroup>
    </Form>
            
        </div>
    )
}
