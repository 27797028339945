import React, {useState} from 'react';
import {Form, Alert, Input, Button} from 'antd';
import {Redirect} from 'react-router-dom'

import {FormGroup} from '../../utils';
import {useApi, usePageValue} from '../../../../context';


export default function QuantityAmount(props) {

    let api = useApi();
    let {setPageLoading, setPageError, setPageSuccess, setMessage} = usePageValue();

    const [redirect, setRedirect] = useState(false);
    const onFinish = values => {
        
        const postForm = async() => {
            setPageLoading(true);
            let results = await api.createInvoice(props.leadId, values.quantity);
            
            let {statusCode} = results;
            setPageLoading(false);
            console.log(statusCode);

            if(statusCode === 200) {
                setRedirect(true);
            }
            else{
                setPageError(true);
                setMessage(results.error);
            }
        }

        postForm();
    }

    return (
        <div>
            {redirect && <Redirect to={`/invoice/create/${props.leadId}`} />}
            <Form
      // {...layout}
      id="quantityInvoiceForm"
      layout="vertical"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      size={'large'}
      // onFinishFailed={onFinishFailed}
    >

<FormGroup 
    name={'quantity'}
    label={'Enter Quantity'}
    required= {true}>
        
        <Input placeholder={"eg: 5"} />
       
            </FormGroup>
    </Form>
            
        </div>
    )
}
