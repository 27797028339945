import {useState, useEffect} from 'react';
import {useApi} from '../context';

export function useWeeklySalesQuota(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [weeklyQuota, setWeeklyQuota] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getWeeklySalesQuota();

            if(results.statusCode === 200) {
                let {data, message} = results;

                setWeeklyQuota(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [weeklyQuota, isLoading, hasError ]
        }
    }, [])

    return {weeklyQuota, isLoading, hasError}
}

export function useWeeklySalesQuotaTeams(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [performance, setPerformance] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getWeeklySalesQuotaByTeam(props.team);

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setPerformance(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [performance, isLoading, hasError ]
        }
    }, [props.team])

    return {performance, isLoading, hasError}
}

export function useWeeklySalesQuotaTeamsPercentage(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [performancePercentage, setPerformancePercentage] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getWeeklySalesQuotaByTeamPercentage(props.team);

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setPerformancePercentage(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [performancePercentage, isLoading, hasError ]
        }
    }, [props.team])

    return {performancePercentage, isLoading, hasError}
}

export function useWeeklySalesandTarget(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [target, setTarget] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getWeeklySalesandTarget(props.team);

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setTarget(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [target, isLoading, hasError ]
        }
    }, [props.team])

    return {target, isLoading, hasError}
}

export function useCummulativeSalesandTarget(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [target, setTarget] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getCummulativeSalesandTarget(props.team, '2021');

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setTarget(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [target, isLoading, hasError ]
        }
    }, [props.team])

    return {target, isLoading, hasError}
}

export function useWeeklySalesAndTargetTeam(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [weeklyQuota, setWeeklyQuota] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getWeeklySalesAndTargetTeam();

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setWeeklyQuota(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [weeklyQuota, isLoading, hasError ]
        }
    }, [])

    return {weeklyQuota, isLoading, hasError}
}