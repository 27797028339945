import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types';
import {Form, Row, Input, Select, DatePicker, Divider} from 'antd';
import {FormGroup, FullFormGroup} from '../../utils'
import {useCountries, useCountryStates, useCompanies, useGender} from '../../../../hooks'

import {getCurrentDate, getUserId} from '../../../../helpers';
import moment from 'moment';
import {useApi, usePageValue, useModalValue} from '../../../../context';

const { Option } = Select;
const { TextArea } = Input;
 
function CreateContact(props) {

  let {setIsModalLoading} = useModalValue()

  const [refreshPage, setRefreshPage] = useState(0);

  const [countryId, setCountryId] = useState(1);
    const {countries} = useCountries();
    let {countryStates} = useCountryStates({refreshVariable: refreshPage, countryId});
    const gender = useGender();
    const {companies} = useCompanies();
    let api = useApi();
    let {setPageLoading, setPageError, setPageSuccess, setMessage} = usePageValue();

    
  const [form] = Form.useForm();

    const defaultSelectWidth = 220;
    const onFinish = values => {
        // console.log(values)

        const data = {
          additional_info: values.additionalInformation,
          address: values.address,
          city: values.city,
          company: values.company,
          country_id: values.country,
          dob: new Date(values.date).toISOString().slice(0, 10),
          email: values.email,
          external_link: values.externalLink,
          gender: values.gender,
          name: values.name,
          phone_number: values.phone,
          position: values.position,
          state_id: values.countryState,
          registrar_id: getUserId()
        }
        const postForm = async() => {
          setPageLoading(true);
          setIsModalLoading(true);
         let results = await api.createContact(data);
        
         let {statusCode} = results;
         setPageLoading(false)
         setIsModalLoading(false);
         if(statusCode === 200) {
           setMessage('Contact created');
           setPageSuccess(true);
          form.resetFields();
           props.refreshComp()
              props.closeModal(false)

         }else if(statusCode === 422 ) {
           console.log(results);
           let error = results.error.data;
           let errorMessage = Object.keys(error)[0];
          console.log(error[errorMessage]);
           setMessage(error[errorMessage]);
           setPageError(true);
         } else{
          setMessage('An error occurred, try again');
          setPageError(true);
         }
        }

        postForm();
      }

      useEffect(() => {
        
        return () => {
          return [countries, countryStates, gender, companies] 
        }
      }, [])


      const handleCountryChange = (value) => {
        setCountryId(value);
        setRefreshPage(Math.random * 100)
      }
    return (
        <div>
            <Form
    //   {...layout}
    form={form}
    id={"contactForm"}
      layout="vertical"
      name="contactForm"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      size={'large'}
      // onFinishFailed={onFinishFailed}
    >
        <Row >
        <FullFormGroup 
            name={'name'}
            label={'Full Name'}
            required= {true} >
           <Input />
            </FullFormGroup>
        </Row>

        <Row gutter={4}>
        <FormGroup 
            name={'email'}
            label={'Contact Email'}
            >
           <Input />
            </FormGroup>

            <FormGroup 
            name={'phone'}
            label={'Phone Number'}
            required
            >
           <Input />
            </FormGroup>
        </Row>

        <Row gutter={4}>
        <FullFormGroup 
            name={'address'}
            label={'Address'}
            >
           <Input style={{width: '100%'}} />
            </FullFormGroup>
        </Row>

        <Row gutter={4}>
            <FormGroup 
            name={'gender'}
            label={'Gender'}
            required= {true}>
           <Select   style={{ width: defaultSelectWidth }} 
              // onChange={handleChange}
              >
                {
                  gender.map(cat => (<Option value={cat.value}>{cat.label}</Option>))
                }
    </Select> 
            </FormGroup>

            <FormGroup 
            name={'city'}
            label={'City'}
            required= {true}>
           <Input />
            </FormGroup>
        </Row>



        <Row gutter={4}>
        <FormGroup 
            name={'country'}
            label={'Country'}
            required= {true}>
             <Select   style={{ width: defaultSelectWidth }} 
             showSearch
              onChange={handleCountryChange}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              >
                {
                  countries.map(cat => (<Option value={cat.value}>{cat.label}</Option>))
                }
    </Select> 
            </FormGroup>

            <FormGroup 
            name={'countryState'}
            label={'State'}
            required= {true}>
            <Select   style={{ width: defaultSelectWidth }} 
              // onChange={handleChange}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              >
                {
                  countryStates.map(cat => (<Option value={cat.value}>{cat.label}</Option>))
                }
    </Select> 
            </FormGroup>

            
        </Row>
        
       
       <Row gutter={4}>
       <FormGroup 
            name={'date'}
            label={'Date'}
            required= {true}
            >
           <DatePicker defaultValue={moment(new Date)} />
            </FormGroup>

            <FormGroup 
            name={'externalLink'}
            label={'External Link'}
            required= {false}
            >
           <Input />
            </FormGroup>
       </Row>

       <Row gutter={4}>
       <Divider orientation="left">More Information</Divider>

            <FormGroup 
            name={'position'}
            label={'Position'}
            >
           <Input />
            </FormGroup>


            <FormGroup 
            name={'company'}
            label={'Company'}
            >
           <Select   style={{ width: defaultSelectWidth }} 
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              >
                {
                  companies.map(cat => (<Option value={cat.value}>{cat.label}</Option>))
                }
    </Select> 
            </FormGroup>
        </Row>
       
        <Row gutter={24}>
        <FullFormGroup 
            name={'additionalInformation'}
            label={'Additional Information'}
            required={false}>
              <TextArea placeholder="Brief info about the contact..." allowClear
              //  onChange={onChange} 
              style={{ height: '100px'}}
               />
            </FullFormGroup>
            </Row>
    </Form>
        </div>
    )
}

CreateContact.propTypes = {

}

export default CreateContact

