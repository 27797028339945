import {useState, useEffect} from 'react';
import {addLabelToArray, isStage} from '../helpers';
import {useApi} from '../context';

export function useDashboard (props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [annualBudget, setAnnualBudget] = useState([]);
    const [urgentLeads, setUrgentLeads] = useState(0);
    const [expiredLeads, setExpiredLead] = useState(0);
    const [leadsGenerated, setLeadsGenerated] = useState([]);
    const [achieved, setAchieved] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [salesmanTarget, setSalesmanTarget] = useState([])

    useEffect(() => {
        
        const fetchData = async() => {
            let date = new Date();
            setIsLoading(true)
            let results = null;


                results = await api.getLeadFlowRate(props? props.id: null);
                let getAnnualBudget = await api.getAnnualBudget(date.getFullYear());
                let getUrgentLeads = await api.getUrgentLeads(props? props.id: null);
                let getLeadsGenerated = await api.getLeadsGenerated(props? props.id: null);
                let getAchieved = await api.getMgtAchievedTarget(props? props.id : null);
                let getExpired = await api.getExpiredLeads(props? props.id : null);
                let getSalesmanTarget = await api.getSalesmanTarget(date.getFullYear(), (props? props.id : null))
            if(results.statusCode === 200) {
                let {data, message} = results;
                let annualData = getAnnualBudget.data;
                let urgentData = getUrgentLeads.data;
                let leaddata = getLeadsGenerated.data;
                let achievedData = getAchieved.data;
                let expiredData = getExpired.data;
                let salesmanData = getSalesmanTarget.data;

                setAnnualBudget(annualData);
                setUrgentLeads(Number(urgentData.folCount) + Number(urgentData.introCount) + Number(urgentData.qualCount));
                setLeadsGenerated(leaddata);
                setAchieved(achievedData);
                setExpiredLead(Number(expiredData.folCount) + Number(expiredData.introCount) + Number(expiredData.qualCount));
                setSalesmanTarget(salesmanData);
                   
            } 
            else {
                let {error} = results;
                setHasError(error);
            }

            setIsLoading(false);
        }
    
        fetchData();
        return () => { 
            return [annualBudget, salesmanTarget, urgentLeads, leadsGenerated, achieved, expiredLeads, isLoading, hasError ]
        }
    }, [props? props.id : null])

    return {annualBudget, salesmanTarget, urgentLeads, leadsGenerated, achieved, expiredLeads}
}