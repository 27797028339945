import React from 'react';
import {useAllMeaningfulEngagement, useBiQuestionnaire} from '../../../../hooks';
import {Collapse, Divider} from 'antd';
import { NumberFormat } from '../../atoms';
import { formatDate } from '../../../../helpers';

const {Panel} = Collapse;

export default function ViewMeaningfulEngagements({leadId, dealSize}) {
    let {engagements} = useAllMeaningfulEngagement({leadId});
    let {questionniare, comment, updatedAt} = useBiQuestionnaire({leadId})
    return (
        <div>
           <Collapse defaultActiveKey={['0']}>
           <Panel header={<strong>Deal Size</strong>} key="0">
      <p> <NumberFormat value={dealSize} /></p>
    </Panel>

    <Panel header={<strong>BI Questionnaire</strong>} key="1">
      <p>Is the product going to save the company cost? <strong>{questionniare['question1']}</strong></p>
      <p>Is the product going to enhance the company's operational goals? <strong>{questionniare['question2']}</strong> </p>
      <p>Is the product of strategic advantage to the client? <strong>{questionniare['question3']}</strong></p>
      <p>Is the product going to aid decision making in the company? <strong>{questionniare['question4']}</strong> </p>
      <p>Is the client going to adopt the product within a good time frame? <strong>{questionniare['question5']}</strong></p>
      <p>Is the product targeted at the clients needs? <strong>{questionniare['question6']}</strong></p>
      <p>Is the product going to aid embedded analytics? <strong>{questionniare['question7']}</strong></p>
      <span>Comment</span>
      <br />
      <strong>{comment}</strong>
        <br />
      <span style={{float: "right"}}>{formatDate(updatedAt)}</span>
    </Panel>
               {
                   engagements && engagements.map((dat, index) => (
                   <Panel header={<span><strong> {dat.stage_category_name}</strong></span>} key={index + 2}>
                       <>
                           {Object.keys(dat.questions).map((resp, secondIndex) => (
                               <>
                               {dat.answers? (
                                   <>
                                   <p key={secondIndex}> {dat.questions[resp]} <strong>{dat.answers[resp]}</strong></p>
                               <Divider />
                                   </>
                               ): (<>
                                
                                   <p key={secondIndex}> {dat.questions[resp]} </p>
                               <Divider />
                               </>)}
                               
                               </>
                           ))}
                           {(dat.comments.length > 0)? (
                               <>
                               <span>comments: </span> <br/>
                               <strong>{dat.comments[dat.stage_category_id - 5].subcomment}</strong>
                               </>
                           ): ('')}

                           <span style={{float: "right"}}>{formatDate(dat.updated_at)}</span>
                           </>
                       </Panel>
                   ))
               }
           </Collapse>
        </div>
    )
}

