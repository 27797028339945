import React from 'react';
import { Form, Col,Space } from 'antd';
import 'antd/dist/antd.css';

export function FormGroup({name, label, required, button, ...props}) {
    return (

        <Col span={{xs: 24, md: 12, xl: 12}} >
          <Space align="center">
        <Form.Item
          name={name}
          label={<> {label} &nbsp; {button} </>}
          rules={ required? [
            {
              required: {required},
              message: 'This field is required',
            },
          ] : []}
          initialValue={props.initialValue}
        >
        {props.children}
        </Form.Item>
        
        </Space>
      </Col>
 
    )
}

export function FullFormGroup({name, label, required, ...props}) {

 


  return (

    <Col style={{width: '100%'}} >
    <Form.Item
      name={name}
      label={label}
      // {...layout}
      rules={[
        {
          required: {required},
          message: 'This field is required',
        },
      ]}
      // style={{minWidth: '480px'}}
    >
    {props.children}
    </Form.Item>
  </Col>

)
}