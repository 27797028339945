import React from 'react';
import {Button} from 'antd'



export default function ({onClick, label}) {
    return(
  <Button type="primary" size="small" onClick={onClick} style={{ backgroundColor: 'rgb(50 107 55)', color: '#FFF'}}>
           {label}
          </Button>

    )
  }
