import {useState, useEffect} from 'react';
import {addLabelToArray, formatDate, isStage, stageNames} from '../helpers';
import {useApi, usePageValue} from '../context';
import moment from 'moment';


export function useLeads () {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [formatToSpreadSheet, setFormatToSpreadSheet] = useState([]);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

           
                results = await api.getLeads();
          
            setIsLoading(false);
            if(results.statusCode === 200) {
                let {data, message} = results;

               
                    setData(addLabelToArray(data));
                    setFormatToSpreadSheet(formatLeadTableToSpreadsheet(data));
            } 
            else {
                let {error} = results;
                setHasError(error);
            }

            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [data, formatToSpreadSheet, isLoading, hasError ]
        }
    }, [])

    return {data, formatToSpreadSheet, isLoading, hasError}
}

export function useExecutiveExpiredLeads() {
  let api = useApi();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [formatToSpreadSheet, setFormatToSpreadSheet] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let results = null;

      results = await api.getExecutiveExpiredLeads();

      setIsLoading(false);
      if (results.statusCode === 200) {
        let { data, message } = results;
        console.log(data);

        setData(addLabelToArray(data));
        setFormatToSpreadSheet(formatLeadTableToSpreadsheet(data));
      } else {
        let { error } = results;
        setHasError(error);
      }

      // setIsLoading(false);
    };

    fetchData();
    return () => {
      return [data, formatToSpreadSheet, isLoading, hasError];
    };
  }, []);

  return { data, formatToSpreadSheet, isLoading, hasError };
}

export function useExecutiveInactiveLeads() {
  let api = useApi();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [formatToSpreadSheet, setFormatToSpreadSheet] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      let results = null;

      results = await api.getExecutiveInactiveLeads();

      setIsLoading(false);
      if (results.statusCode === 200) {
        let { data, message } = results;
        console.log(data);

        setData(addLabelToArray(data));
        setFormatToSpreadSheet(formatLeadTableToSpreadsheet(data));
      } else {
        let { error } = results;
        setHasError(error);
      }

      // setIsLoading(false);
    };

    fetchData();
    return () => {
      return [data, formatToSpreadSheet, isLoading, hasError];
    };
  }, []);

  return { data, formatToSpreadSheet, isLoading, hasError };
}

const formatLeadTableToSpreadsheet = (data) => {
    let tempArray = [['Date Added', 'Category', 'Channel', 'Industry', 'Product', 'Service', 'Company', 'Customer', 'Customer Phone', 'Customer Email', 'Added By']];

    if (data.length > 0){
        for (let i =0; i<data.length; i++ ){
            let lead = data[i];
            tempArray.push([formatDate(lead.created_at), lead.category, lead.channel, lead.industry, lead.product, lead.service, lead.company, lead.customer, lead.contact_phone, lead.contact_email, lead.user])
        }
    }
    return tempArray;
}

export function useSaleLeads () {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [sevenDaysReport, setSevenDaysReport] = useState([]);
    const [allTimeReports, setAllTimeReports] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getSaleLeads();
                
            setIsLoading(false);
            if(results.statusCode === 200) {
                let {data, message} = results;
        
                    setData(data);
                    let last7Days = filterLastWeekLeads(data)
                    setSevenDaysReport(formatToSpreadSheetSevenDays(last7Days));
                    setAllTimeReports(formatToSpreadSheet(data));
            } 
            else {
                let {error} = results;
                setHasError(error);
            }

            setIsLoading(false);
        }

        fetchData();
        return () =>  [data, allTimeReports, sevenDaysReport, isLoading, hasError ]
        
    }, [])

    return {data, allTimeReports, sevenDaysReport, isLoading, hasError}
}


export function useOneLead ({id, refreshVariable}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [lead, setLead] = useState({});
    const [inactiveLead, setInactiveLead] = useState(false);
    const [inactiveLeadReasonStatus, setInactiveLeadReason] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [extensionStatus, setExtensionStatus] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneLead(id);
                setIsLoading(false);
 
            if(results.statusCode === 200) {
                let {data, message} = results;
        

                // let innerData = data.leads[0];
                // let leadData = innerData.leads[0]
                // console.log('showing the inactive status ', data.inactivelead_status);
                setInactiveLead(data.inactivelead_status);
                setInactiveLeadReason(data.inactive_reason_status);
                setExtensionStatus(data.extensionstatus);

                let leadData = data.leads[0];
                let stageData= data.substage? data.substage: data.oldleadstage;
                let contacts = data.tags;
                let oldStageData = data.oldleadstage;
                // let stageComments = data.comments;
                let substageComments = data.subcomments;
                
                let approvalResults = await api.getOneAdvancementRequest(id, stageData.stage_id)
                let approvalStatus = approvalResults.data.approval_status;
                

                let finalContacts;
                if(contacts.length > 0) {
                finalContacts = contacts.map(contact => {
                    return{
                        email: contact.c_email? contact.c_email : 'N/A',
                        phoneNumber: contact.contact? contact.contact.phone_number: 'N/A',
                        name: contact.contact? contact.contact.user.name : '',
                        contactType: contact.contact_type? contact.contact_type : "N/A"
                    }
                    });
                }

                if (leadData.category.id === 1 || leadData.category.id === 4) {
                    setLead({
                        mediumName: leadData.medium.name,
                        channelName: leadData.channel.name,
                        dealSize: leadData.deal_size,
                        product: leadData.product,
                        productId: leadData.product.id,
                        categoryId: leadData.category.id,
                        categoryName: leadData.category.name,
                        user: leadData.user,
                        stage: data.substage? data.substage.stage_name : '',
                        qualification: leadData.qualification,
                        company: leadData.company,
                        companyAddress: leadData.company.address ,
                        companyName: leadData.company.name,
                        companyEmail: leadData.company.email,
                        companyPhone: leadData.company.telephone,
                        channel: leadData.channel,
                        backgroundInfo: leadData.background_info,
                        installationReason: leadData.installation_reason,
                        comments: data.comments,
                        contacts: finalContacts,
                        stageId: stageData.stage_id,
                        stageName: stageData.stage_name.name,
                        substageId: stageData.stage_category_id? stageData.stage_category_id: forceToASubstageId(stageData.stage_id),
                        substageName: stageData.stage_category_name? stageData.stage_category_name.name: '',
                        substageComments: substageComments,
                        assignee: data.assignment? data.assignment.assignee.name: '',
                        assigneeId: data.assignment? data.assignment.assignee.id: '',
                        approvalStatus: approvalStatus,
                        createdAt: leadData.created_at,
                        questionnaire: data.questionaire_status,
                        expired: data.expired,
                        productName: leadData.product.name,
                        inactiveLeadStatus: data.inactivelead_status,
                        inactiveReasonStatus: data.inactive_reason_status,
                        extensionStatus: data.extensionstatus
                      });
                
                } 
                else if (leadData.category.id === 2 ){
                    setLead({
                        medium: leadData.medium,
                        mediumName: leadData.medium.name,
                        channelName: leadData.channel.name,
                        dealSize: leadData.deal_size,
                        product: leadData.product,
                        productName: leadData.product.name,
                        productId: leadData.product.id,
                        categoryId: leadData.category.id,
                        categoryName: leadData.category.name,
                        user: leadData.user,
                        // stage: stageData.stage_name,
                        stage: oldStageData.stage_name,
                        qualification: leadData.qualification,
                        customerName: leadData.customer.user.name,
                        customerEmail: leadData.customer.c_email,
                        customerAddress: leadData.customer.address,
                        customerPhone: leadData.customer.phone_number,
                        channel: leadData.channel,
                        backgroundInfo: leadData.background_info,
                        installationReason: leadData.installation_reason,
                        comments: data.comments,
                        contacts: finalContacts,
                        // stageId: stageData.stage_id,
                        stageId: oldStageData.stage_id,
                        // stageName: stageData.stage_name.name,
                        stageName: oldStageData.stage_name.name,
                        substageId: stageData.stage_category_id,
                        substageName: stageData.stage_name.name,
                        assignee:  data.assignment? data.assignment.assignee.name: '',
                        assigneeId: data.assignment? data.assignment.assignee.id: '',
                        approvalStatus: approvalStatus,
                        createdAt: leadData.created_at,
                        questionnaire: data.questionaire_status,
                        expired: data.expired,
                        inactiveLeadStatus: data.inactivelead_status,
                        inactiveReasonStatus: data.inactive_reason_status,
                        extensionStatus: data.extensionstatus

                    })
                } 
                else {
                    setLead({
                        mediumName: leadData.medium.name,
                        channelName: leadData.channel.name,
                        dealSize: leadData.deal_size,
                        product: leadData.product,
                        productName: leadData.product.name,
                        productId: leadData.product.id,
                        categoryId: leadData.category.id,
                        categoryName: leadData.category.name,
                        user: leadData.user,
                        stage: data.substage? data.substage.stage_name : '',
                        qualification: leadData.qualification,
                        company: leadData.company,
                        companyAddress: leadData.company.address ,
                        companyName: leadData.company.name,
                        companyEmail: leadData.company.email,
                        companyPhone: leadData.company.telephone,
                        channel: leadData.channel,
                        backgroundInfo: leadData.background_info,
                        installationReason: leadData.installation_reason,
                        comments: data.comments,
                        contacts: finalContacts,
                        stageId: stageData.stage_id,
                        stageName: stageData.stage_name.name,
                        substageId: stageData.stage_category_id,
                        substageName: stageData.stage_category_name? stageData.stage_category_name.name: '',
                        substageComments: substageComments,
                        assignee: data.assignment? data.assignment.assignee.name: '',
                        assigneeId: data.assignment? data.assignment.assignee.id: '',
                        approvalStatus: approvalStatus,
                        createdAt: leadData.created_at,
                        questionnaire: data.questionaire_status,
                        expired: data.expired,
                        inactiveLeadStatus: data.inactivelead_status,
                        inactiveReasonStatus: data.inactive_reason_status,
                        extensionStatus: data.extensionstatus
                      });
                }
                    
            } 
            else {
                let {error} = results;
                setHasError(error);
            }

            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [lead, isLoading, hasError ]
        
        }
    }, [refreshVariable])


        const forceToASubstageId = (stageId)=>{
            let finalSubstageId = null;
            switch (stageId) {
                case 1:
                    finalSubstageId = 1;
                    break;
                    case 2:
                        finalSubstageId = 2;
                        break;
                        case 3:
                            finalSubstageId = 5;
                            break;
                            case 4:
                                finalSubstageId = 10;
                                break;
                default:
                    finalSubstageId = 13;
                    break;
            }
            return finalSubstageId;
        }
    return {lead, inactiveLead, setInactiveLead, extensionStatus, isLoading, hasError, inactiveLeadReasonStatus}
}


export function useGeneralComments ({id, refreshVariable}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [generalComments, setGeneralComments] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getGeneralComments(id);
 
            if(results.statusCode === 200) {
                let {data, message} = results;
                let genCom =[];
                if(data.length > 0) {
                 genCom = data.map(comment => {
                    return{
                        comment: comment.comment,
                        created_at: comment. created_at,
                        commentBy: comment.user.name
                    }
                    });
                }
                    setGeneralComments(genCom);
         } 
            else {
                let {error} = results;
                setHasError(error);
            }

            setIsLoading(false);
        }
    
        fetchData();
        return () => { 
            return [generalComments, isLoading, hasError ]
        }
    }, [refreshVariable])

    return {generalComments, isLoading, hasError}
}



export function useStageLogs ({id, refreshVariable}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [stageLogs, setStageLogs] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getStageLogs(id);
 
            if(results.statusCode === 200) {
                let {data, message} = results;
                let genCom =[];
                if(data.length > 0) {
                    let tempDataArray = [];
                    let firstIteration = data.filter(dat => {
                        if(!tempDataArray.includes(dat.stage_id)){
                            tempDataArray.push(dat.stage_id);

                            return dat;
                        }
                        
                    });
                    // let newData = new Set(data);
                    // console.log('showing the new data ', newData);
                 genCom = firstIteration.map(comment => {
                    return{
                        comment: comment.comment,
                        created_at: comment. created_at,
                        stageName: comment.stage_name.name,
                        updated_at: comment.updated_at
                    }
                    });
                }

                    setStageLogs(genCom.reverse());
                   
            } 
            else {
                let {error} = results;
                setHasError(error);
            }

            setIsLoading(false);
        }
    
        fetchData();
        return () => { 
            return [stageLogs, isLoading, hasError ]
        }
    }, [refreshVariable])

    return {stageLogs, isLoading, hasError}
}

export function useMarketingMaterials(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [marketingMaterials, setMarketingMaterials] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getMarketingMaterials();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    let labelledData = (addLabelToArray(data));
                    setMarketingMaterials(labelledData)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
        }

        fetchData();
        return () => { 
            return [marketingMaterials, isLoading, hasError ]
        }
    }, [])

    return {marketingMaterials, isLoading, hasError}
}

export function useRequalficationVariables(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [requalificationVariables, setRequalificationVariables] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getControlVariables();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    let labelledData = (addLabelToArray(data));
                    setRequalificationVariables(labelledData)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
        }

        fetchData();
        return () => { 
            return [requalificationVariables, isLoading, hasError ]
        }
    }, [])

    return {requalificationVariables, isLoading, hasError}
}

export function useAssignmentHistory(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [assignmentHistory, setAssignmentHistory] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)

            let results = await api.getAssignmentHistory(props.leadId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    setAssignmentHistory(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
        }

        fetchData();
        return () => { 
            return [assignmentHistory, isLoading, hasError ]
        }
    }, [])

    return {assignmentHistory, isLoading, hasError}
}

export function useUrgentLeads(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [urgentLeads, setUrgentLeads] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)

            let results = await api.getUrgentLeads();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                let parsedArray = mergeStages(data);
                console.log(parsedArray);
                    setUrgentLeads(parsedArray)
              
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
        }

        fetchData();
        return () => { 
            return [urgentLeads, isLoading, hasError ]
        }
    }, [])

    return {urgentLeads, isLoading, hasError}
}

export function useExpiredLeads(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [expiredLeads, setExpiredLeads] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)

            let results = await api.getExpiredLeads();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                let parsedArray = mergeStages(data);
                // console.log(parsedArray);
                    setExpiredLeads(parsedArray)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }

            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [expiredLeads, isLoading, hasError ]
        }
    }, [])

    return {expiredLeads, isLoading, hasError}
}

export function useAdvancementComment(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [comments, setComments] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAdvancementComments(props.id);
          setIsLoading(false);
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // setData(addLabelToArray(data));
                    setComments(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [comments, isLoading, hasError ]
        }
    }, [])

    return {comments, isLoading, hasError}
}


export const mergeStages = (data) => {
    let introduction = data.introduction;
    let follow = data.follow;
    let qualification = data.qualification;
    let leadResponseTime = data.lead_response;

    introduction = introduction.map(introduct => ({...introduct, stage: "Introduction"}));
    follow = follow.map(fol => ({...fol, stage: "Follow Up"}));
    leadResponseTime = leadResponseTime.map(fol => ({...fol, stage: "Lead Response Time"}));
    qualification = qualification.map(qual => ({...qual, stage: "Qualification"}));

    console.log(introduction);
    return leadResponseTime.concat(introduction).concat(qualification).concat(follow);


}

const filterLastWeekLeads =  (leads) => {
    let lastSevenDay = moment().subtract(9, 'd');
    let today = moment.now();
    let finalLead = leads.filter( lead =>{
      let dateUpdated = lead.comment_created_at? lead.comment_created_at: lead.stage_date? lead.stage_date: lead.lead_created_at;

      if(moment(lastSevenDay).isSameOrBefore(dateUpdated) && moment(today).isSameOrAfter(dateUpdated)){
          lead.dateUpdated = dateUpdated;
        return lead
      }
      return
    } );
  
    // console.log('seven days lead ', finalLead)
    // console.log(finalLead.sort(sortByUpdatedAt));
    return finalLead.sort(sortByUpdatedAt);
  }

  const sortByUpdatedAt = (a, b) => {
    if (moment(a.dateUpdated).isBefore(b.dateUpdated) ){
        return 1;
      }
      if ( moment(a.dateUpdated).isAfter(b.dateUpdated)){
        return -1;
      }
      return 0;
  }

  const formatToSpreadSheetSevenDays = (leads) => {
    let tempArray = [['Date', 'Category', 'Product', 'Client', 'Stage', 
    'Qualification',  'Deal Size', 'Assigned']];

  if(leads.length > 0){
      // leads.forEach(lead => {
          for (let i = 0; i < leads.length; i++){
              let lead = leads[i];
          let leadRow = [ formatDate(lead.dateUpdated), 
          lead.category, lead.product, lead.company? lead.company : lead.customer, stageNames[lead.stage_id],
          lead.qualification, lead.deal_size, lead.assignee ];
          tempArray.push(leadRow);
      }
  }

  return tempArray;
}

  const formatToSpreadSheet = (leads) => {
      let tempArray = [['Date', 'Category', 'Product', 'Client', 'Stage', 
      'Qualification',  'Deal Size', 'Assigned']];

      
    if(leads.length > 0){
        // leads.forEach(lead => {
            for (let i = 0; i < leads.length; i++){
                let lead = leads[i];
                let dateUpdated = lead.comment_created_at? lead.comment_created_at: lead.stage_date? lead.stage_date: lead.lead_created_at;

            let leadRow = [formatDate(dateUpdated), 
            lead.category, lead.product, lead.company? lead.company : lead.customer, stageNames[lead.stage_id],
            lead.qualification, lead.deal_size, lead.assignee ];
            tempArray.push(leadRow);
        }
    }

    return tempArray;
  }