import React, {useState, useEffect} from 'react';
import {useApi} from '../context';

export default function useMentions() {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [contacts, setContacts] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getContacts();
 
            if(results.statusCode === 200) {
                let {data, message} = results;
                
                setContacts(parseUsers(data));
                     
            } 
            else {
                let {error} = results;
                setHasError(error);
            }

            setIsLoading(false);
        }
    
        fetchData();
        return () => { 
            return [contacts, isLoading, hasError ]
        }
    }, [])


    const parseUsers = (data) =>{
        let returnUsers = [];
       data.forEach(user=>{
          let id = user.user_id;
          let display = user.name;
          returnUsers.push({'id': id, 'display': display});
  
        })
       return returnUsers;
      }

    return {contacts, isLoading, hasError}
}
