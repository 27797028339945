import React, {useMemo, useState} from 'react'
import {useAllTeams} from '../../../hooks';
import {formatDate} from '../../../helpers';
import {ReactTable, Link} from '../../units/atoms';
import { Drawer, PageTitle } from '../../units/molecules';
import { Button, Row, Col, Modal } from 'antd';
import {useApi, usePageValue} from '../../../context';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export default function ViewTeams() {
   
    const [refreshPage, setRefreshPage] = useState(0);
    let {teams} = useAllTeams({refreshPage});
    let api = useApi();
    let {setPageLoading, setPageError, setPageSuccess, setMessage} = usePageValue();

    const breadcrumbs = [
        {'label': 'Dashboard', path: "/"},
        {'label' : 'View Teams'}
    ]


    const activateTeam = (teamId, name)=> {

        function showConfirm() {
            confirm({
              title: `Do you want to activate team ${name}?`,
              icon: <ExclamationCircleOutlined />,
            //   content: 'T ' + name,
              onOk() {
               postApproval();
              },
              onCancel() {
                console.log('Cancel');
              },
            });
          }

          const postApproval = async() => {
        let results = await api.activateTeam(teamId);
        let {statusCode} = results;

        if(statusCode === 200){

            setMessage('Team activated');
            setPageSuccess(true);
            setRefreshPage(Math.random() * 100);
        }
    }

    showConfirm();
}

const deactivateTeam = (teamId, name)=> {

    function showConfirm() {
        confirm({
          title: `Do you want to deactivate team ${name}?`,
          icon: <ExclamationCircleOutlined />,
        //   content: 'T ' + name,
          onOk() {
           postDeactivate();
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      }

      const postDeactivate = async() => {
    let results = await api.deactivateTeam(teamId);
    let {statusCode} = results;

    if(statusCode === 200){
        setMessage('Team deactivated')
        setPageSuccess(true);
        setRefreshPage(Math.random() * 100);
    }
}

showConfirm();
}

    const columns = useMemo(() => [
        {Header: 'Date Created',
        id: 'dateAdded',
        accessor: d=> (formatDate(d.created_at)),
        Cell: d =><span>{d.value}</span>,
     
    },
        {
        Header: 'Team Name',
        accessor: 'name'
    },
{
    Header: 'Department Name',
    accessor: 'team_category',
    Cell: d => <span>{d.value? d.value.name: ""}</span>
}, 
{
    Header: ' Members',
    accessor: 'team_members_count',
    width: 80
}, 
{
    Header: 'Status',
    accessor: 'status',
    width: 80
},
{id: 'action',
    Header: 'Action',
accessor: d => d,
filterable: false,
Cell: props=> <>
<Link to={{pathname: `/teams/${props.value.id}`}}>
<Button type="primary" size="small"> View
   </Button>
 </Link>
&nbsp;
 <Link to={{pathname: `/editTeam/${props.value.id}`}}>
<Button type="default" size="small"> Edit
   </Button>
 </Link>
&nbsp;
{(props.value.status.toLowerCase() == 'active')? (
 <Button danger size="small" onClick={()=> deactivateTeam(props.value.id, props.value.name)}> Deactivate
 </Button>
): ( <Button type="default" style={{color: '#219319', borderColor: "#219319"}}size="small"
onClick={()=>activateTeam(props.value.id, props.value.name)}> Activate
</Button>)}

 </>
}
], teams)


    return (
        <Drawer>
        <PageTitle title="Teams" breadcrumbs={breadcrumbs} />
       
        <Row>
    <Col span={24}>
    <div style={{float: 'right', display:'block'}}>
        <Link to={{pathname: `/createTeam`}} style={{float: 'right'}}>
      <Button type="primary"> Create new Team
       </Button>
         </Link>
         
         </div>
    </Col>
</Row>

       <ReactTable columns={columns}
       filterable={true}
       data={teams} 
       />

   </Drawer>
    )
}
