import React from 'react'
import 'antd/dist/antd.css';
import { Steps } from 'antd';

const { Step } = Steps;

const steps = [
  {
    title: 'Product & Asset',
    content: 'First-content',
  },
    {
      title: 'Customer Details',
      content: 'Second-content',
    },
    {
      title: 'Installation & Renewal Details',
      content: 'Third-content',
    }

  ];

function Stepper({currentStep}) {

    // const [current, setCurrent] = useState(0)
    return (
        
              <Steps current={Number(currentStep) - 1 } 
              style={{width: '100%'}}> 
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
 
    )
}

Stepper.propTypes = {

}

export default Stepper

