import {useState, useEffect} from 'react';
import {useApi} from '../context';
import {formatDate} from '../helpers';

export function useCustomerChurnRate(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [customerChurnRate, setCustomerChurnRate] = useState([]);
    const [hasError, setHasError] = useState(false);
    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getCustomerChurnRate(props.category);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setCustomerChurnRate(data)
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [customerChurnRate, isLoading, hasError ]
        }
    }, [props.category])

    return {customerChurnRate, isLoading, hasError}
}