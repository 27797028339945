import React, {useState} from 'react';
import {Form, Alert, Input, Button, Select} from 'antd';

import {FormGroup} from '../../../units/utils';
import {useRequalficationVariables, useUsers} from '../../../../hooks'
import {useApi, usePageValue, useModalValue} from '../../../../context';
import {Loader, Error} from '../../../units/atoms';


const { Option } = Select;

export default function ReassignmentRequest(props) {

    let {setIsModalLoading} = useModalValue()

    let api = useApi();
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState();

    let {users} = useUsers()
    
    const onFinish = values => {
        
        let data = {
            lead_id: props.leadId,
            comment: values.reason,
            assignee: values.assigned,
            stage_id: props.stageId
        }

        // console.log(values);

        const postForm = async() => {
            setIsLoading(true);
            setIsModalLoading(true);
            let results = await api.postReassignmentRequest(data);
            
            let {statusCode} = results;
            setIsLoading(false);
            setIsModalLoading(false);

            if(statusCode === 200) {
               
                setSuccess(true)
                setTimeout(() => {
                    props.closeModal(false)
                }, 3000);
                
            }
            else{
                setErrorMessage(results.error);
            }
        }

        postForm();
    }

    return (
        <div>
            {isLoading && 
            <Alert message="Loading" type="info" />
        }
        {errorMessage && <Error message={errorMessage} />}
        {success && 
        <Alert message="Request submitted" type="success" />
        }
            <Form
      id="reassignmentRequest"
      layout="vertical"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      size={'large'}
      // onFinishFailed={onFinishFailed}
    >

<FormGroup 
    name={'assigned'}
    label={'Reassign to'}
    required= {true}>
        
        <Select
          
          size={'400px'}
          placeholder="Select"
        showSearch
          style={{ width: '400px' }}

          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          filterSort={(optionA, optionB) =>
            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
          }
        >
          {users.map(requalificationVariable => (
              <Option value={requalificationVariable.id} key={requalificationVariable.id}>{requalificationVariable.name}</Option>
          ))}
        </Select>
       
            </FormGroup>

<FormGroup 
    name={'reason'}
    label={'Enter Reason'}
    required= {true}>
        
        <Input placeholder="My reason is" 
        style={{width: '400px'}}/>
       
            </FormGroup>
    </Form>
            
        </div>
    )
}
