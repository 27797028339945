import {useState, useEffect} from 'react';
import {useApi} from '../context';
import {formatDate} from '../helpers';

export function useRenewals(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [renewals, setRenewals] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [spreadSheetRenewals, setSpreadSheetRenewals] = useState([]);
    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getRenewalAccounts();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setRenewals(data)
                    setSpreadSheetRenewals(formatToSpreadSheet(data))
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [renewals, spreadSheetRenewals, isLoading, hasError ]
        }
    }, [])

    return {renewals, spreadSheetRenewals, isLoading, hasError}
}

export function useAllRenewals(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [renewals, setRenewals] = useState([]);
    const [hasError, setHasError] = useState(false);
    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAllRenewals();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setRenewals(data)
                    
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [renewals, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {renewals, isLoading, hasError}
}

export function useOneRenewal(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [renewalDetails, setRenewalDetails] = useState({});
    const [renewalDeals, setRenewalDeals] = useState({});
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneRenewal(props.accountId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                let {details, deals} = data
                    setRenewalDetails({
                        accountEmail: details.Account_email,
                        accountName: details.Account_name,
                        accountPhone: details.Account_phone,
                        accountOfficer: details.account_officer,
                        assignee: details.assignee,
                         companyName: details.company_name,
                         contactEmail: details.contact_email,
                         contactName: details.contact_name,
                         contactPhone: details.contact_phone,
                         customerId: details.customer_id,
                         dateSubscribed: details.date_subscribed,
                         invoice: details.invoice_no,
                         leadId: details.lead_id,
                         pricingGroupName: details.pricing_group_name,
                         pricingId: details.pricing_id,
                         productName: details.product_name,
                         quantity: details.quantity,
                         status: details.status
                    })
              setRenewalDeals(deals)
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [renewalDeals, renewalDetails, isLoading, hasError ]
        }
    }, [])

    return {renewalDeals, renewalDetails, isLoading, hasError}
}

const formatToSpreadSheet = (data) => {
    let tempArray = [['Date', 'Category', 'Product', 'Company/Client',
                'Account Officer', ]];
    if (data.length > 0) {
        data.forEach(dat => {
            // tempArray.push([moment(lead.date).format("MMM Do YYYY"), lead.product, lead.category, lead.company, lead.company_phone, lead.company_email, lead.customer, lead.contact_phone, lead.contact_email])
            tempArray.push([formatDate(dat.date_subscribed), dat.client_category, dat.product_name, dat.Account_name,
            dat.account_officer])
        })
    }

    return tempArray;
}

export function useRenewalFees() {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [renewalFees, setRenewalFees] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getRenewalFees();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setRenewalFees(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [renewalFees, isLoading, hasError ]
        }
    }, [])

    return {renewalFees, isLoading, hasError}
}

export function useTransactionHistory({accountId}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getTransactionHistory(accountId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setTransactions(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [transactions, isLoading, hasError ]
        }
    }, [])

    return {transactions, isLoading, hasError}
}

export function useOneRenewalFee  ({id}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [fee, setFee] = useState({});
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneRenewalFee(id);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                 data = data[0]
                    setFee({...data})
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [fee, isLoading, hasError ]
        }
    }, [])

    return {fee, isLoading, hasError}
}

export function useDealHistory  ({id, accountId, refreshPage}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [history, setHistory] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getDealHistory(id, accountId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                //  data = data[0]
                    setHistory(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [history, isLoading, hasError ]
        }
    }, [refreshPage])

    return {history, isLoading, hasError}
}

export function useDealReminders  ({id, refreshPage}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [reminders, setReminders] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getDealReminders(id);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                //  data = data[0]
                    setReminders(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [reminders, isLoading, hasError ]
        }
    }, [refreshPage])

    return {reminders, isLoading, hasError}
}


export function useAllDealReminders () {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [reminders, setReminders] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAllDealReminders();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                //  data = data[0]
                    setReminders(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [reminders, isLoading, hasError ]
        }
    }, [])

    return {reminders, isLoading, hasError}
}

export function useRenewalLatestInfo  ({id}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [info, setInfo] = useState({});
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getLatestInfo(id);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                //  data = data[0]
                if(!data){
                    setInfo({})
                }else{

                    setInfo(data)
                }
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [info, isLoading, hasError ]
        }
    }, [id])

    return {info, isLoading, hasError}
}


export function useOneReminder  ({id}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [reminder, setReminder] = useState({});
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneReminder(id);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                //  data = data[0]
                    setReminder(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [reminder, isLoading, hasError ]
        }
    }, [id])

    return {reminder, isLoading, hasError}
}


export function useReminderAction  ({id, refreshPage}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [actions, setAction] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getRenewalAction(id);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                //  data = data[0]
                    setAction(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [actions, isLoading, hasError ]
        }
    }, [refreshPage, id])

    return {actions, isLoading, hasError}
}


export function useNotRenewingComment  ({id, refreshPage}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [comments, setComments] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getRenewalComment(id);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                //  data = data[0]
                    setComments(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [comments, isLoading, hasError ]
        }
    }, [id, refreshPage])

    return {comments, isLoading, hasError}
}

export function useAccountReminders  ({id, refreshPage}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [reminders, setReminders] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneAccountReminders(id);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                //  data = data[0]
                    setReminders(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [reminders, isLoading, hasError ]
        }
    }, [id, refreshPage])

    return {reminders, isLoading, hasError}
}

export function useRenewalInvoice  ({dealPaymentHistoryId}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [invoice, setInvoice] = useState({});
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getRenewalInvoice(dealPaymentHistoryId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                //  data = data[0]
                    setInvoice(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [invoice, isLoading, hasError ]
        }
    }, [])

    return {invoice, isLoading, hasError}
}

export function usePendingDeals  () {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [pendingDeals, setPendingDeals] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getPendingDeals();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                //  data = data[0]
                    setPendingDeals(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [pendingDeals, isLoading, hasError ]
        }
    }, [])

    return {pendingDeals, isLoading, hasError}
}

export function useOnePendingDeal({accountDealId}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [pendingDeal, setPendingDeal] = useState({});
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOnePendingDeal(accountDealId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                //  data = data[0]
                    setPendingDeal(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [pendingDeal, isLoading, hasError ]
        }
    }, [])

    return {pendingDeal, isLoading, hasError}
}

export function useDevicesForDeal  ({accountDealId, refreshPage}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [devices, setDevices] = useState([]);
    const [deviceIds, setDeviceId] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getDevicesForDeal(accountDealId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                //  data = data[0]
                    setDevices(data)
                    setDeviceId(data.map(dat => dat.id));
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [devices, deviceIds, isLoading, hasError ]
        }
    }, [accountDealId, refreshPage])

    return {devices, deviceIds, isLoading, hasError}
}

export function useOneInstallationDevice  ({deviceId}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [device, setDevice] = useState({});
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getSingleDevice(deviceId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                //  data = data[0]
                setDevice(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [device, isLoading, hasError ]
        }
    }, [])

    return {device, isLoading, hasError}
}