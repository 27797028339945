import React, {useState} from 'react';
import {useApi, usePageValue} from '../../../../context';
import {Form, Input, Checkbox, Typography, Col, Row, Button, DatePicker,
    Alert} from 'antd';
import {FormGroup} from '../../../units/utils';
import {Loader, Error, Success} from '../../../units/atoms'


const {TextArea} = Input;
const defaultSelectWidth = 400;

export default function AddAction(props) {
    let api = useApi();
    let [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [showReminder, setShowReminder] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();

    const onFinish = value => {
        setIsLoading(true);
        setErrorMessage();
        console.log(props)
        let data = {
            account_deal_id: props.accountDealId,
            renewal_account_id: props.renewalAccountId,
            action: value.action
        }
        const postForm = async () => {
            let results = await api.addRenewalAction(data);
            let {statusCode} = results;
            setIsLoading(false)
            if(statusCode === 200) {
                 setSuccessMessage('Action added');
                 setTimeout(() => {
                     form.resetFields();
                     props.refreshPage(Math.random() * 100);
                        props.closeModal(false);
                 }, 2000);
            }
            else{
                setErrorMessage(results.message)
            }
        }
        postForm()

        let secondData = {
            account_deal_id: props.accountDealId,
            renewal_account_id: props.renewalAccountId,
            description: value.action,
            scheduled_time: value.scheduleTime.format(),
            title: value.title
        }
        const postReminderForm = async() => {
            let reminderResults = await api.createReminder(secondData);

        }

        if(showReminder){
            postReminderForm();
        }
    }

    const onChange = () => {
        setShowReminder(!showReminder)
    }

    return (
        <div>
            {isLoading && <Loader />}
            {errorMessage && <Error message={errorMessage} />}
            {successMessage && <Success message={successMessage} />}
           <Form
           form={form} 
           id="addAction"
           layout="vertical"
           initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          size={'large'}>

<FormGroup 
            name={'action'}
            label={'Action'}
            required= {true}>
              
         <TextArea placeholder="" allowClear
             
             style={{width: '400px', height: '100px'}} />
            </FormGroup>


            <Checkbox onChange={onChange}>Add Reminder</Checkbox>          
            
            {showReminder && (<>
                <FormGroup 
            name={'title'}
            label={'Title'}
            required= {true}>
              
         <Input style={{width: defaultSelectWidth}} />
            </FormGroup>


        
            <FormGroup 
            name={'scheduleTime'}
            label={'Schedule Date'}
            required= {true}>
              
              <DatePicker showTime 
              style={{width: defaultSelectWidth}} />
            </FormGroup>

            </>)}  
          </Form>
        </div>
    )
}
