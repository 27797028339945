import React from 'react'
import { Drawer, PageTitle } from "../../units/molecules";
import {Row, Col, Divider} from 'antd';
import {LabelAndNames} from '../leads/LeadDetail';
import {useRenewalInvoice} from '../../../hooks';
import { formatDate } from '../../../helpers';

export default function RenewalInvoiceDetail(props) {

    let dealPaymentHistoryId = props.match.params.id;
    const breadcrumbs = [
        {'label': 'Dashboard', path: "/dashboard"},
        {'label': 'Renewal Invoice'}
    ]

    let {invoice} = useRenewalInvoice({dealPaymentHistoryId: dealPaymentHistoryId})
    return (
        <Drawer>
            <PageTitle title="Invoice Detail" breadcrumbs={breadcrumbs} />

            {invoice? (
                <>
                <Row gutter="24">
                <Col span="12">
            <LabelAndNames label="Account Name" value={invoice.account_name}
                            backgroundColor="#EBEBEB" />

            <LabelAndNames label="Date Subscribed" value={formatDate(invoice.created_at)}
            backgroundColor="#EBEBEB" />
            
            </Col>


            <Col span="12">
   
           <LabelAndNames label="Product" value={invoice.product_name}
            backgroundColor="#FFF" />

<LabelAndNames label="Subscription Plan" value="Annually"
                            backgroundColor="#EBEBEB" />
            </Col>
            </Row>

            <Row style={{marginTop: "40px", border: "1px solid #aaa"}}>
                <Col span="24">
                    <Row gutter="24" style={{textAlign: "center"}}>
                <Col span="6" >
                    <strong>Product</strong>
                </Col>

                <Col span="2">
                    <strong>Quantity</strong>
                </Col>

                <Col span="4">
                    <strong>Unit Price</strong>
                </Col>
                <Col span="6">
                    <strong>Total</strong>
                </Col>

                </Row>
                </Col>

                <Divider />

                <Col span="24">
                <Row gutter="24" style={{textAlign: "center"}}>
                <Col span="6">
                    <strong>{invoice.product_name}</strong>
                </Col>

                <Col span="2">
                    <strong>{invoice.quantity}</strong>
                </Col>

                <Col span="4">
                    <strong>{invoice.unit_price}</strong>
                </Col>
                <Col span="6">
                    <strong>{Number(invoice.unit_price) * Number(invoice.quantity)}</strong>
                </Col>

                </Row>
                </Col>
            </Row>
            </>
            ) : (
                <h1>No invoice exists</h1>
            )}
            
        </Drawer>
    )
}
