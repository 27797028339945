import React from 'react'
import PropTypes from 'prop-types';
import {Form, Button, Row, Input} from 'antd';
import 'antd/dist/antd.css';
import {FullFormGroup} from '../../utils';

import {useApi, usePageValue, useModalValue} from '../../../../context';

function CreateMedium(props) {
  let {setIsModalLoading} = useModalValue()

    const {TextArea} = Input;
    let api = useApi();

    let {setPageLoading, setPageError, setPageSuccess, setMessage} = usePageValue();

    const onFinish = values => {
        console.log(values);

        let data = {
          name: values.name,
          description: values.description
        }

        const postForm = async() => {
          setPageLoading(true);
          setIsModalLoading(true);
          // console.log('post form again');
          let results = await api.createMedium(data);

          let {statusCode} = results;
          setPageLoading(false);
          setIsModalLoading(false);
          if(statusCode === 200){
            setMessage(results.message);
            setPageSuccess(true);

              setPageSuccess(false);
              props.refreshComp()
              props.closeModal(false)

          }

        }

        postForm();
      }


    return (
        <div>
                 <Form id="mediumForm"
      // {...layout}
      layout="vertical"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      size={'large'}
      // onFinishFailed={onFinishFailed}
    >
        <Row >
          <FullFormGroup 
            name={'name'}
            label={'Name'}
            required= {true}
            >
           <Input placeholder={"Facebook"} />
            </FullFormGroup>
        </Row> 

        <Row >
          <FullFormGroup 
            name={'description'}
            label={'Description'}
            required= {true}>
           
           <TextArea  allowClear
              //  onChange={onChange} 
              style={{width: '100%', height: '100px'}}
               />
               
            </FullFormGroup>
        </Row>
    </Form>
        </div>
    )
}

CreateMedium.propTypes = {

}

export default CreateMedium

