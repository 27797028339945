import React, {useState, useEffect} from 'react';
import {Form, Input, Alert, Radio, Typography, DatePicker} from 'antd';

import {FormGroup} from '../../utils';
import {useApi, usePageValue, useModalValue} from '../../../../context';
import {isStage} from '../../../../helpers'
import {InputWithMentions, Loader, Error} from '../../atoms'
import {useBant, useOneMeaningfulEngagement, useAllMeaningfulEngagement} from '../../../../hooks';
import {LoadingOutlined} from '@ant-design/icons';

export default function AdvanceLeadComment({stageId, substageId, leadId, ...props}) {

    let {setIsModalLoading} = useModalValue()

    let {setPageLoading, setPageError, setPageSuccess, setMessage} = usePageValue();
    let {bant} = useBant({leadId, substageId});
    let {engagements, questionNumber} = useOneMeaningfulEngagement({stageId, substageId})
    
    let api = useApi();
    let inputRef = React.createRef();
    const [isLoading, setIsLoading]  = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [showCommentBox, setShowCommentBox] = useState(((substageId < 8 && stageId == 3) || (substageId == 1 && stageId == 2))? false : true);
    const [totalChecklistValue, setTotalChecklistValue] = useState({});
    const [showCalendar, setShowCalendar] = useState(false);
    const [form] = Form.useForm();

    setPageLoading(false);
    const [quantifyState, setQuantifyState] = useState();

    let newAuthority =null;
    let newBudget = null;
    let newNeed = null;
    let newTime = null;

    const {Title} = Typography;
    
    useEffect(() => {
        setShowCalendar(false);
        setShowCommentBox(((substageId < 8 && stageId == 3) || (substageId == 1 && stageId == 2))? false : true);
        form.resetFields();
        console.log('showing the ref here', inputRef);
        return () => [quantifyState, showCommentBox]
    }, [substageId])

    // const quantifyState = null; 

    const stageAndSubstageIds = `${stageId}${substageId}`;
    const {TextArea} = Input;

    const handleShowCommentBox = (name, value) => {
        console.log('showing the value ', value);
        setTotalChecklistValue({...totalChecklistValue, [name]: value});
        let tempTotalCheckList = {...totalChecklistValue, [name]: value};
        
        let summedUpValue = Object.values(tempTotalCheckList).reduce((a,b) => a + b);

        console.log('showing the sumed up value ', summedUpValue);

        switch (substageId) {

            case 1:
                if(summedUpValue >= 7){
                    setShowCommentBox(true);
                    return;
                } 
                setShowCommentBox(false);
                break;

            case 5:
                if(summedUpValue >= 3){
                    setShowCommentBox(true);
                    return;
                } 
                setShowCommentBox(false);
                break;

            case 6:
                if(summedUpValue >= 7) {
                    setShowCommentBox(true);
                    return;
                }
                setShowCommentBox(false);
                break;
           
                case 7:
                    if(summedUpValue >= 5) {
                        setShowCommentBox(true);
                        return;
                    }
                    setShowCommentBox(false);
                    break;
        
            default:
               
                break;
        }
        return
    }
    const tips = {
        22: (<>
        <ul>
            <li>What business/product materials have been shared?</li>
            <li>When were they sent ?</li>
            <li>Who received them ? (Details of contact person)</li>
        </ul>

        </>),
        23: (<ul>
            <li>Date and time of meeting to be logged in the ERP.</li>
            <li>Venue of meeting to be logged in the ERP.</li>
            <li>Details of attendees to be logged in the ERP.</li>
        </ul>
        ),
        24: (
        <>
        <ul>
            <li>Who gets to manage product usage?</li>
            <li>Who will be signing off the product deal?</li>
            <li>What was the last time you bought a similar product? How did the decision making process go?</li>
            <li>Will anyone else be involved in this decision?</li>
            <li>Who should be copied in the mails been sent?</li>
            <li>Who should be of utmost consideration for every meeting schedule?</li>
        </ul>
        {/* <FormGroupWithRadio name="question1" callback={handleShowCommentBox}
            label="Is the product going to save the company cost?" />

<FormGroupWithRadio name="question2" callback={handleShowCommentBox}
            label="Is the product going to enhance the company's operational goals?" />

<FormGroupWithRadio name="question3" callback={handleShowCommentBox}
            label="Is the product of strategic advantage to the client?" />

<FormGroupWithRadio name="question4" callback={handleShowCommentBox}
            label="Is the product going to aid decision making in the company?" />

<FormGroupWithRadio name="question5" callback={handleShowCommentBox}
            label="Is the client the best customer for the set product(s)?" />

<FormGroupWithRadio name="question6" callback={handleShowCommentBox}
            label="Is the client going to adopt the product within a good time frame?" />


<FormGroupWithRadio name="question7" callback={handleShowCommentBox}
            label="Is the product targeted at the clients needs?" />
        
        <FormGroupWithRadio name="question8" callback={handleShowCommentBox}
            label="Is the product going to aid embedded analytics?" /> */}


        </>),
        35: (<>
        {/* <ul>
            <li>Do they have the purchasing powers? (Yes/No)</li>
            <li>Do they have the purchase of the solution in their current budget? (Yes/No)</li>
            <li>What do they currently spend on this problem or need</li>
            <li>How much would the organization set aside for wear and tear or loss of assets?</li>
            <li>How heavily will price factor into the decision?</li>
            <li>Have you identified a budget range for this purchase?</li>
            <li>What’s the ROI that they are hoping to see?</li>
            <li>Do they have the purchasing power?</li>
            <li>Do they have the purchase of the solution in their current budget?</li>
        </ul> */}


           {/* <FormGroupWithRadio name="question1" callback={handleShowCommentBox}
            label="Does the personnel manage the company’s budget?" />

            <FormGroupWithRadio name="question2" callback={handleShowCommentBox}
            label="Does the personal have the authority to make purchasing decisions on behalf of their company or department?" />


            <FormGroupWithRadio name="question3" callback={handleShowCommentBox}
            label="Are they in the position of implementing strategy in their company?" />

            <FormGroupWithRadio name="question4" callback={handleShowCommentBox}
            label="Are they the person in charge of looking out for solutions that can help their company overcome a challenge?" />

            <FormGroupWithRadio name="question5" callback={handleShowCommentBox}
            label="Do they have influence over the end users department?" /> */}


        </>
        ),
        36: (
        <>
        {/* <ul>
            <li>Who gets to manage product usage?</li>
            <li>Who will be signing off the product deal?</li>
            <li>What was the last time they bought a similar product? </li>
            <li>How did the decision making process go?</li>
            <li>Will anyone else be involved in this decision?</li>
            <li>Who should be copied in the mails been sent?</li>
            <li>Who should be of utmost consideration for every meeting schedule?</li>
        </ul> */}

             {/* <FormGroupWithRadio name="question1" callback={handleShowCommentBox}
            label="Asking the prospect if price is one of their main evaluation criteria?" />

            <FormGroupWithRadio name="question2" callback={handleShowCommentBox}
            label="Does the upside of investing in the product, quantify the future impact of investing in the product?" />

            <FormGroupWithRadio name="question3" callback={handleShowCommentBox}
            label="Do they have a budget range for the purchase?" />

            <FormGroupWithRadio name="question4" callback={handleShowCommentBox}
            label="Does the end users departmental budget figure for the product tie in the organization's budget?" />

            <FormGroupWithRadio name="question5" callback={handleShowCommentBox}
            label="Are you working within their budget with the price given?" />

            <FormGroupWithRadio name="question6" callback={handleShowCommentBox}
            label="Do they have a budget set aside (this month, quarter, and year) for the general product category?" />

            <FormGroupWithRadio name="question7" callback={handleShowCommentBox}
            label="How major a priority is the relevant business area to be optimized for the prospects team? Does that align with the product pricing?" /> */}


        </>
        ),
        37: (
        <>
        {/* <ul>
            <li>How does our solution address their specific needs?</li>
            <li>When did they identify [problem, opportunity]?</li>
            <li>What steps have you already taken to address it?</li>
            <li>How important is addressing this to the Company Objectives?</li>
            <li>What are the company's top priorities at the moment? Where does this fit on that list?</li>
            <li>What happens if you don’t address this?</li>
            <li>Do they need our solution?</li>
            <li>Do they belong to the industries that need our type of service? I.e. logistics, haulage, FMCG, oil and gas etc.</li>
        </ul> */}

         {/* <FormGroupWithRadio name="question1" callback={handleShowCommentBox}
            label="Are you clear about the  clients' need  for the service?" />

<FormGroupWithRadio name="question2" callback={handleShowCommentBox}
            label="Have you illustrated the product's efficiency by using customer testimonials and case studies to give specific examples of how the product has been for past buyers?" />

<FormGroupWithRadio name="question3" callback={handleShowCommentBox}
            label="Have you shown the client via demonstrations, presentations and  product value offerings ? " />

<FormGroupWithRadio name="question4" callback={handleShowCommentBox}
            label="Do you Understand the client's  sense of urgency as regards improving their financial position and optimizing their operations with product offerings (product value propositions)?" />

<FormGroupWithRadio name="question5" callback={handleShowCommentBox}
            label="Does the company’s goal align with the clients  on the joint venture?" /> */}

        </>
        ),
        38:  (<ul>
            <li>What date/period do they expect to have the solution been put to use? </li>
            <li>When are they willing to purchase and implement the products?</li>
            <li>When would the decision makers have their next meeting to deliberate purchase of our product?</li>
        </ul>
        )
    }

    const bantOptions = {
        35: (
            <>
            <Title level={4}>
            Is the decision maker involved?
            
           </Title>
          
           </>
     
        ),
        36: (
            <>
            <Title level={4}>
            Do they have the purchasing power?
            </Title>
            </>
        ),
        37: (
            <>
            <Title level={4}>
                Do our solution address their specific need?
            </Title>
            </>
        ),
        38: (
            <Title level={4}>
            Is there an urgent need for the solution?
            </Title>
        )
    }


    // This function will filter out the questions 
const filterQuestion = (questions) => {
    console.log(questions);
    let filteredQuestions = Object.keys(questions).filter(quest => quest.substring(0, 8) == 'question');
    let finalObject = {};
    filteredQuestions.forEach(filterQuestion => finalObject[`${filterQuestion}`] = questions[filterQuestion]? "Yes" : "No")
    // console.log('the filtered questions ', finalObject);
    return finalObject;

}

    const onFinish = values => {
       console.log('logging this');
       
        // process.exit(1);
       if(!values.comment) {
           setErrorMessage('Kindly check enough options from the checklist');
           return;
       }

           //    console.log(values);
    

        const postQuestions = async(questions) => {
            console.log(questions);
            // process.exitCode(3);
            let questionData = {
                lead_id: leadId,
                stage_id: stageId,
                stage_category_id: substageId,
                question_type_id: questionNumber,
                questions
            }
            let questionResults = await api.postOneMeaningfulEngagement(questionData)
        }
        if(Object.keys(engagements).length > 0){
            let questions = filterQuestion(values);
            postQuestions(questions);
            
        }
       // This function moves the lead to the next stage
       const updateStage = async() => {
        let thisQtyState;
        if (stageId ==3 &&  substageId == 9){

            let bantAmount = Number(bant.authority) + Number(bant.budget) + Number(bant.need) + Number(bant.time);
            console.log(`This is the bant amount ${bantAmount}`);
            
            
            if(bantAmount >9){
              //   props.finalStage('Hot');
            //   setQuantifyState('Hot')
            thisQtyState = 'Hot'
            }else if (bantAmount >6){
              //   props.finalStage('Warm');
            //   setQuantifyState('Warm')
            thisQtyState = 'Warm'
            }
            else if(bantAmount > 1){
              //   props.finalStage('Cold');
            //   setQuantifyState('Cold')
            thisQtyState = 'Cold';
            } else{
                // Set as dead lead
                // setQuantifyState('dead');
                thisQtyState = 'Dead'
            }
    
        }
       
        let data = {
            lead_id: leadId,
            comment: values.comment,
            stage_id: Number(stageId) + 1 ,
            qualifying_state: thisQtyState,
            assignee_id: props.assigneeId,
            stage_category_id: substageId,
        }
        
        setIsModalLoading(true);
        let results = await api.postAdvancementRequest(data);
        setIsModalLoading(false)
        let {statusCode} = results;
        setIsLoading(false);
        if(statusCode === 200) {
            form.resetFields();
            console.log(results)
            // updateSubstage(Number(stageId) + 1, substageId, leadId);
            setShowSuccess(true);
            setTimeout(() => {
                setShowSuccess(false);
            props.closeModal(false);
            props.refreshVariable(Math.random() * 100)
            }, 2000);
            
        }
       }
       
       // Move to the next substage
       const substageComment = async() => {
        let data = {
            lead_id :leadId,
              subcomment: values.comment,
              comment: 'This is the dummy comment',
              stage_id: stageId,
              stage_category_id: substageId,
            //   priority: priority,
            //   stage: props.stageName,
            stage: 'Dummy Stage Name'
        }
        setIsModalLoading(true);
        let results = await api.addLeadComment(data);
        let {statusCode} = results;
        setIsModalLoading(false);
        console.log(results);

        if(statusCode === 200) {
            form.resetFields();
            updateSubstage(stageId, Number(substageId) + 1, leadId);
            setIsLoading(false);
            setShowSuccess(true);
            setTimeout(() => {
                setShowSuccess(false);
                props.closeModal(false);
            props.refreshVariable(Math.random() * 100)
                
            }, 2000);
            
        } else{
            setErrorMessage(results.error);
        }
       }


       const updateSubstage = async(stageId, substageId, leadId) => {
        let data = {
            lead_id: leadId,
            stage_id: stageId,
            stage_category_id: substageId
        }
        setIsModalLoading(true)
        let results = await api.updateSubstage(data);
        let {statusCode} = results;
        setIsModalLoading(false);

        console.log(results);
        if(statusCode === 200) {
            setIsLoading(false);
            form.resetFields();
            setShowSuccess(true)
            setTimeout(() => {
                setShowSuccess(false);
                props.closeModal(false);
            props.refreshVariable(Math.random() * 100)
               
            }, 2000);  
        } else{
            setErrorMessage(results.error);
        }
        
       }


       setIsLoading(true);
        // Check if it is the end of a stage
        if (isStage(substageId, stageId)) {
            console.log('it is the end of a stage');
            updateStage();
            
        }else {
           
         substageComment()
        }

        
    }

    // For the bant manipulations
    const changeBant = async (val) =>{
        // console.log('the value selected ' ,val.target.value);
        let e = val.target.value;
        
        // props.setIsBantSelected(true);
          console.log(`This is e ${e}`);
          console.log(`This is the substage id ${substageId}`);
          switch (substageId) {
            case 5:
              if(e === 1){
                // setAuthority(2) ;
                console.log(`One is detected`);
                newAuthority = 3;
               
            }else{
              // setAuthority(0);
              console.log('0 is detected');
              newAuthority = 0;
                
            }
              break;
              case 6:
              // Number(budget)? setBudget(0) : setBudget(2);
              if(e === 1){
                console.log(`One is detected`);
                newAuthority = bant.authority;
                newBudget = 5;
            
            }else{
              console.log('0 is detected');
              newAuthority = bant.authority;
                newBudget = 0;
                
            }
              break;
              
              case 7:
              //  Number(need)? setNeed(0) : setNeed(3);
              if(e === 1){
                // setAuthority(2) ;
                console.log(`One is detected`);
                newAuthority = bant.authority;
                newBudget = bant.budget;
                newNeed = 1;
                
            }else{
              console.log('0 is detected');
              newAuthority = bant.authority;
                newBudget = bant.budget;
                newNeed = 0;
               
            }
              break;

              case 8:
                // Number(time)? setTime(0) : setTime(1);
                if(e === 1){
                  setShowCalendar(true);
                  console.log(`One is detected`);
                  newAuthority = bant.authority;
                  newBudget = bant.budget;
                  newNeed = bant.need;
                  newTime = 1;
              }else{
                  setShowCalendar(false);
                console.log('0 is detected');
                newAuthority = bant.authority;
                  newBudget = bant.budget;
                  newNeed = bant.need;
                  newTime = 0;
              }

             
              break;

              default: 
              
                  break;

          }
         
           let data = {
                lead_id: leadId,
                authority: newAuthority,
                budget: newBudget,
                need: newNeed,
                time: newTime
            };

        let results = await api.createBantMetric(data);
          let newbantData = results;
          
          console.log(`This is the authority ${newAuthority}, the budget ${newBudget}, the need ${newNeed}, the time ${newTime}`);
          
          console.log(`This is the bant Data ${newbantData.message}`);
        }
    
      
    return (
        <div>
         
        {/* {isLoading && <Loader />}
        {errorMessage && <Error message={errorMessage} />}
{showSuccess && 
        <Alert message="Comment submitted" type="success" />
        } */}
                   <Form
      form={form}
      id="advanceLeadForm"
      layout="vertical"
      name="basic"
      initialValues={{
        // remember: true,
      }}
      onFinish={onFinish}
      size={'large'}
      // onFinishFailed={onFinishFailed}
    >
        {tips[stageAndSubstageIds] && (
            <Title level={4}>
            Use the questions below to fill up the comment box
        </Title>
        )}
        
        
        {tips[stageAndSubstageIds]}

        {Object.keys(engagements).map(engagement => (
            <FormGroupWithRadio name={engagement} callback={handleShowCommentBox}
            label={engagements[engagement]} />

        ))}

        {bantOptions[stageAndSubstageIds] && (
<>
{bantOptions[stageAndSubstageIds]}
<FormGroup 
    name={'bantSelected'}
    required={true}>

<Radio.Group
         onChange={changeBant} 
        size="large"
        name="bantSelected"
         >
        <Radio value={1}>Yes</Radio>
        <Radio value={0}>No</Radio>
       
      </Radio.Group>

    </FormGroup>
      </>
        ) 
}

{
    showCalendar && 
    <FormGroup
    label={"Select timeline for adoption"}
    name="weekOfImplementation">
        <DatePicker  />

    </FormGroup>
}

{
    showCommentBox &&  
    <FormGroup 
    name={'comment'}
    label={'Enter Comments'}
    required= {true}
    ref={inputRef}>
     

        <InputWithMentions width={450}/>
            </FormGroup>
}

    </Form>

    {isLoading && <Loader />}
        {errorMessage && <Error message={errorMessage} />}
{showSuccess && 
        <Alert message="Comment submitted" type="success" />
        }
        </div>
    )
}



const FormGroupWithRadio = ({label, name, callback}) => {
    return (
        <FormGroup
        label={label}
        name={name}
        required={true}>
        <Radio.Group
        size="large"
        name={name}
        onChange={(e) => callback(name, e.target.value)}
      
        >
            <Radio value={1}>Yes</Radio>
            <Radio value={0}>No</Radio>
        </Radio.Group>
        </FormGroup>
    )
}
