import {useState} from 'react'

export default function useIndustries() {
    const [categories, setCategories] = useState([
        { value: 'Male', label : 'Male'},
        {label: 'Female', value: 'Female'}
        
         ])

         return categories;
}
