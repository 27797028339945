import {useState, useEffect} from 'react';
import {addLabelToArray} from '../helpers';
import {useApi} from '../context';

export  function useProducts(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getProducts();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setProducts(addLabelToArray(data))
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [products, isLoading, hasError ]
        }
    }, [])

    return {products, isLoading, hasError}
}

export  function useAllProducts(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAllProducts();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setProducts(addLabelToArray(data))
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [products, isLoading, hasError ]
        }
    }, [])

    return {products, isLoading, hasError}
}

export  function useReasonForEnquiry(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [reasons, setReason] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getReasonForEnquiry(props.productGroupId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setReason(addLabelToArray(data))
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [reasons, isLoading, hasError ]
        }
    }, [props.productGroupId])

    return {reasons, isLoading, hasError}
}

export  function useOneProduct(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState({});
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneProduct(props.productId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setProduct(data);
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [product, isLoading, hasError ]
        }
    }, [])
    return {product, isLoading, hasError}
}

export  function useOneProductModule(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [productModule, setProductModules] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneProductModules(props.productId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setProductModules(data);
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [productModule, isLoading, hasError ]
        }
    }, [props.refreshPage])
    return {productModule, isLoading, hasError}
}


export  function usePricingUnits(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [units, setUnit] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getPricingUnits(props.moduleId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setUnit(data);
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [units, isLoading, hasError ]
        }
    }, [props.refreshPage])
    return {units, isLoading, hasError}
}