import {useState, useEffect} from 'react';
import {addLabelToArray} from '../helpers';
import {useApi} from '../context';

export  function useMake() {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [makes, setMakes] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

            // setMakes(sampleData);
                results = await api.getMakes();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    let labelledData = (addLabelToArray(data));
                    setMakes(labelledData)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [makes, isLoading, hasError ]
        }
    }, [])

    return {makes, isLoading, hasError}
}

export  function useModel({makeId}) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [models, setModels] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

            
                results = await api.getModels(makeId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setModels(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [models, isLoading, hasError ]
        }
    }, [makeId])

    return {models, isLoading, hasError}
}


export  function useAllControlAccounts() {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAllAccounts();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setAccounts(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [accounts, isLoading, hasError ]
        }
    }, [])

    return {accounts, isLoading, hasError}
}

export  function usePendingDevices(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [devices, setDevices] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getPendingDevices(props.renewalAccountId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setDevices(data)
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [devices, isLoading, hasError ]
        }
    }, [])

    return {devices, isLoading, hasError}
}

export  function useControlDetailsForOneAccount(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [control, setControl] = useState([]);
    const [account, setAccount] = useState({});
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneControlAccountDevices(props.renewalAccountId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setControl(data.control);
                    setAccount(data.account);
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [control, account, isLoading, hasError ]
        }
    }, [])

    return {control, account, isLoading, hasError}
}

export  function useAllExpiredAccounts(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAccountsThatExpireInThreeMonths();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setAccounts(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [accounts, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {accounts, isLoading, hasError}
}

export function useOneControlAccountDetail (props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [accountDetail, setAccountDetail] = useState({});
    const [vehicleMake, setVehicleMake] = useState(0);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

            results = await api.getOneControlAccountDetail(props.controlId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setAccountDetail(data)
                    setVehicleMake(data.make_id)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [accountDetail, vehicleMake, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {accountDetail, vehicleMake, isLoading, hasError}
}

export function useOneControlHistory (props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [history, setHistory] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneControlAccountHistory(props.controlId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setHistory(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [history, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {history, isLoading, hasError}
}

export function useOneControlSubscriptionHistory (props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [history, setHistory] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneControlAccountSubscriptionHistory(props.controlId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setHistory(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [history, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {history, isLoading, hasError}
}

export function usePlatforms (props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [platforms, setPlatforms] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getPlatforms();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setPlatforms(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [platforms, isLoading, hasError ]
        }
    }, [])

    return {platforms, isLoading, hasError}
}

export function useCustomerGroups (props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [groups, setGroups] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getGroups();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setGroups(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [groups, isLoading, hasError ]
        }
    }, [])

    return {groups, isLoading, hasError}
}

export function useInstallationTypes (props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [installationTypes, setInstallationTypes] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getInstallationType();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setInstallationTypes(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [installationTypes, isLoading, hasError ]
        }
    }, [])

    return {installationTypes, isLoading, hasError}
}

export function useOneCustomer (props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [customer, setCustomer] = useState({});
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneCustomer(props.customerId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                setCustomer(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [customer, isLoading, hasError ]
        }
    }, [])

    return {customer, isLoading, hasError}
} 