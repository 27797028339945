import {useState, useEffect} from 'react'
import {addLabelToArray} from '../helpers';
import {useApi} from '../context';

export default function useMedium() {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [mediums, setMedium] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getMediums();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
                    setMedium(addLabelToArray(data))
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => {
            return [mediums, isLoading, hasError ]
        }
    }, [])

    return {mediums, isLoading, hasError}
}
