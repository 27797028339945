import React, {useState} from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash';
import {Drawer, PageTitle, QuantityAmount, AddLeadComment} from '../';
import {Row, Col, Typography, Skeleton, Card, Menu, Dropdown, Button, Avatar, Tabs,
  Collapse, Divider, Form } from 'antd';
import {Stepper} from '../../atoms'
import {MailOutlined, PhoneOutlined, PlusSquareOutlined, DownOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {NumberFormat} from '../../atoms';
import {FormGroup} from '../../../units/utils';
import {Select, Loader, Error, Success} from '../../../units/atoms';
import {Modal} from '../../../units/molecules/modals';
import {useContacts} from '../../../../hooks';
import {useApi} from '../../../../context';
import { AddMoreForm } from '../../../pages/leads/CreateLead';


const { Title, Text } = Typography;

const { TabPane } = Tabs;

const { Panel } = Collapse;

export default function LeadDetails({lead, refreshVariable, leadId}) {
    const [showContactModal, setShowContactModal] = useState(false);
    return (
        <Col xs={24} sm={24} md={6} xl={6}>

        <Card style={{width: 'inherit'}}>
        <Row >
            <Col span={24}  align="center" justify="center">
    <Title level={4}> {lead.categoryId && <span>{(lead.categoryId === 1 || lead.categoryId === 4)? `${lead.companyName}` : `${lead.customerName}`}</span>}</Title>
            </Col>
            <Col span={24}  align="center" justify="center">
            <MailOutlined />: &nbsp;
                <Text style={{fontStyle: 'italic'}}> 
    {lead.categoryId && <span>{(lead.categoryId === 1 || lead.categoryId === 4)? `${lead.companyEmail}` : `${lead.customerEmail}`}</span>}</Text>
            </Col>

            <Col span={24}  align="center" justify="center">
            <PhoneOutlined />: &nbsp;
                <Text> {lead.categoryId && <span>{(lead.categoryId === 1 || lead.categoryId === 4)? `${lead.companyPhone}` : `${lead.customerPhone}`}</span>} </Text>
            </Col>
        </Row>


        <Row style={{marginTop: '20px'}}>
        <Col span={24}>
            <Text>Deal Size</Text>: <Text strong style={{fontSize: '20px'}}>
            <NumberFormat
        value={Number(lead.dealSize).toFixed(2)}
    />
              </Text>
        </Col>
        <Col span={24}>
        <Text>Qualification</Text>: <Text>{lead.qualification? `${lead.qualification}`: 'N/A'}</Text>
        </Col>
        </Row>
        </Card>
        
        <Card style={ {width: 'inherit', marginTop: '20px'}}>
            <Row gutter={12} >
                <Col span={12}>
                <Text>Product </Text> <br />
                <Text style={{fontSize: '20px'}}>
                    {lead.productName}
                </Text>
                </Col>

                <Col span={12}>
                <Text>Category </Text> <br />
                <Text style={{fontSize: '20px'}}>
                    {lead.categoryName}
                </Text>
                </Col>

                </Row>

                <Row  gutter={8} style={{marginTop: '20px'}}>
                <Col span={12}>
                <Text>Medium </Text> <br />
                <Text style={{fontSize: '20px'}}>
                    {lead.mediumName}
                </Text>
                </Col>

                <Col span={12}>
                <Text>Channel </Text> <br />
                <Text style={{fontSize: '20px'}}>
                    {lead.channelName}
                </Text>
                </Col>
         
                </Row>
        </Card>
        
        
        <Card size="small" title="Lead Background Information"  style={{ width: "inherit", marginTop: 20 }}>
      <p>{lead.backgroundInfo}</p>
      
    </Card>

    <Card size="small" title="Reason for Installation"  style={{ width: "inherit", marginTop: 20 }}>
      <p>{lead.installationReason}</p>
      
    </Card>

    <Card size="small" title="Contacts" 
    // extra={<a href="#">More</a>} 
    extra={<PlusSquareOutlined style={{fontSize: '20px', color: "#1890ff"}}  
    onClick={() => setShowContactModal(!showContactModal)}/>}
    style={{ width: 'inherit', marginTop: 20 }}>
      {
        lead.contacts && lead.contacts.map(cont => (<TeamMembersContact name={cont.name} email={cont.email} 
            phoneNumber={cont.phoneNumber} contactType={cont.contactType}/>))
      }
      
    </Card>

         {/* ============ Start Add Contact Modal ==========*/}
         <Modal 
 visible={showContactModal}
 title="Add Contact"
//  onOk={setNextStageModal}
 onCancel={setShowContactModal}
 formId="addContact"
 >
<AddContacts leadId={leadId} closeModal={setShowContactModal}
refreshVariable={refreshVariable}/>
</Modal>
{/* ============ End Add Contact Modal ==========*/}

        </Col>
    )
}


const TeamMembersContact = ({name, email, phoneNumber, contactType}) => 
    (
        <>
        <p>Name: <Text strong={true}>{name}</Text> <br/>
      Email: <Text strong={true}>{email}</Text> <br />
      Phone Number:  <Text strong={true}>{phoneNumber}</Text> <br />
      Contact Type: <Text strong={true}>{contactType}</Text> <br />
      </p>
      <Divider />
      </>
    )


    
// THIS ADD THE CONTACT

const AddContacts = ({leadId, closeModal, refreshVariable}) => {
    let {contacts} = useContacts({refreshVariable: 0});
    const [errorMessage, setErrorMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState();
    let api = useApi();

    const defaultSelectWidth = 300;

    const onFinish = values => {
        let tagId = values.contact;
        const postForm = async() => {
            setIsLoading(true)
            let results = await api.addContactsToALead(leadId, tagId);

            let {statusCode} = results;
            
            console.log(statusCode);
            setIsLoading(false)
            if(statusCode === 200) {

                setSuccessMessage("Contact Added");
                setTimeout(() => {
                closeModal(false);
                refreshVariable(Math.random() * 100);
                }, 2000);
               
               
            }
            else{
                setErrorMessage(results.error);
            }
        }
        postForm();
    }

    return(
        <div>
               {isLoading && <Loader />}
            {errorMessage && <Error message={errorMessage}/>}
            {successMessage && <Success message={successMessage}/>}
                        <Form
      // {...layout}
      id="addContact"
      layout="vertical"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      size={'large'}
      // onFinishFailed={onFinishFailed}
    >
{/* 
<FormGroup 
    name={'contact'}
    label={'Select Contact'}
    required= {true}>
        
             
        <Select  style={{ width: defaultSelectWidth }}>
                {contacts}
    </Select> 
    </FormGroup> */}


<AddMoreForm 
defaultSelectWidth={defaultSelectWidth}
name="contact"
label={'Contacts'}
formId="contactForm"
title="New Contact"
modal="contact"
selectMultiple={true}
callbackFunction={api.getContacts}   
/>
    </Form>
        </div>
    )
}