import {useState, useEffect} from 'react';
import {addLabelToArray} from '../helpers';
import {useApi} from '../context';

export  function useCompanies() {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [companies, setcompanies] = useState([]);
    const [hasError, setHasError] = useState(false);
  
    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;
  
                results = await api.getCompanies();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
  
                    // let labelledData = (addLabelToArray(data));
                    setcompanies(addLabelToArray(data));
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }
  
        fetchData();
        return () => { 
            return [companies, isLoading, hasError ]
        }
    }, [])
  
    return {companies, isLoading, hasError}
  }

  export  function useAllCompanies() {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [companies, setcompanies] = useState([]);
    const [hasError, setHasError] = useState(false);
  
    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;
  
                results = await api.getAllCompanies();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
  
                    // let labelledData = (addLabelToArray(data));
                    setcompanies(addLabelToArray(data));
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }
  
        fetchData();
        return () => { 
            return [companies, isLoading, hasError ]
        }
    }, [])
  
    return {companies, isLoading, hasError}
  }

