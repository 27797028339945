import {useState, useEffect} from 'react';
import {addLabelToArray} from '../helpers';
import {useApi} from '../context';

export function useSalesExecutives(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [executives, setExecutives] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getSalesExecutives();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setExecutives(addLabelToArray(data))
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [executives, isLoading, hasError ]
        }
    }, [])

    return {executives, isLoading, hasError}
}



export function useContacts(props) {
  let api = useApi();

  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
      
      const fetchData = async() => {
          setIsLoading(true)
          let results = null;

              results = await api.getContacts();
        
          if(results.statusCode === 200) {
              let {data, message} = results;

                  // let labelledData = (addLabelToArray(data));
                  setContacts(addLabelToArray(data));
            
          } 
          else {
              let {error} = results;
              setHasError(error);
          }
          // setIsLoading(false);
      }

      fetchData();
      return () => { 
          return [contacts, isLoading, hasError ]
      }
  }, [props.refreshPage])

  return {contacts, isLoading, hasError}
}

export function useAllContacts(props) {
    let api = useApi();
  
    const [isLoading, setIsLoading] = useState(true);
    const [contacts, setContacts] = useState([]);
    const [hasError, setHasError] = useState(false);
  
    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;
  
                results = await api.getAllContacts();
          
            if(results.statusCode === 200) {
                let {data, message} = results;
  
                    // let labelledData = (addLabelToArray(data));
                    setContacts(addLabelToArray(data));
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }
  
        fetchData();
        return () => { 
            return [contacts, isLoading, hasError ]
        }
    }, [])
  
    return {contacts, isLoading, hasError}
  }