import React from 'react';
import {LoadingOutlined, CloseCircleOutlined, CheckCircleOutlined} from '@ant-design/icons';
import {Alert} from 'antd';

export  function Loader() {
    return (
        <div>
           <Alert message={<><LoadingOutlined /> <span>Loading...</span> </>} type="info" /> 
        </div>
    )
}

export  function Error({message}) {
    return (
        <div>
           <Alert message={<><CloseCircleOutlined /> <span>{message}</span> </>} type="error" /> 
        </div>
    )
}


export  function Success({message}) {
    return (
        <div>
           <Alert message={<><CheckCircleOutlined /> <span>{message}</span> </>} type="success" /> 
        </div>
    )
}