import {useState, useEffect} from 'react';
import {useApi} from '../context';

export function useDashboardReports(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [deptExecutives, setDeptExecutive] = useState([]);
    const [deptRevenue, setDeptRevenue] = useState({})
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getDepartmentExecutives();
               let deptRevenueResults = await api.getDepartmentRevenue();

            if(results.statusCode === 200) {
                let {data, message} = results;
                
                   setDeptExecutive(data);
                   setDeptRevenue(deptRevenueResults.data);
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [deptExecutives, deptRevenue, isLoading, hasError ]
        }
    }, [])

    return {deptExecutives, deptRevenue, isLoading, hasError}
}

export function useExtensionReport(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [extensionReport, setExtensionReport] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getExtensionReport();

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setExtensionReport(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [extensionReport, isLoading, hasError ]
        }
    }, [])

    return {extensionReport, isLoading, hasError}
}

export function useTeamExtensionReport(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [extensionReport, setExtensionReport] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getTeamExtensionReport(props.teamId);

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setExtensionReport(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [extensionReport, isLoading, hasError ]
        }
    }, [])

    return {extensionReport, isLoading, hasError}
}

export function useLeadExtensionReport(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [extensionReport, setExtensionReport] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getLeadExtensionReport(props.leadId);

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setExtensionReport(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [extensionReport, isLoading, hasError ]
        }
    }, [props.leadId])

    return {extensionReport, isLoading, hasError}
}

export function useIndividualExtensionReport(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [extensionReport, setExtensionReport] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getIndividualExtensionReport(props.userId);

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setExtensionReport(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [extensionReport, isLoading, hasError ]
        }
    }, [])

    return {extensionReport, isLoading, hasError}
}

export function useAllExecutiveExtensionRequest(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [extensionReport, setExtensionReport] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAllExecutiveExtensionRequest();

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setExtensionReport(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [extensionReport, isLoading, hasError ]
        }
    }, [])

    return {extensionReport, isLoading, hasError}
}