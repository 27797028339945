import React, {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';

export const ModalContext = createContext();

export const ModalProvider = ({children}) => {
    const [isModalLoading, setIsModalLoading] = useState(false);
   
    return (
        <ModalContext.Provider value={{isModalLoading, setIsModalLoading}}
        >
            {children}
        </ModalContext.Provider>
    )
};

export const useModalValue = () => useContext(ModalContext);

ModalProvider.propTypes = {
    children: PropTypes.node.isRequired
}


