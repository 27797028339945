import {useState, useEffect} from 'react';
import {useApi} from '../context';

export function useInactiveGraphReport(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [inactiveGraph, setInactiveGraph] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getInactiveLeadGraph(props.year, props.quarter);

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setInactiveGraph(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [inactiveGraph, isLoading, hasError ]
        }
    }, [props.quarter])

    return {inactiveGraph, isLoading, hasError}
}

export function useInactiveConvertedGraphReport(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [inactiveGraph, setInactiveGraph] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getNumberOfConvertedInactiveLeadGraph({year: props.year, quarter: props.quarter, pastYears: props.pastYear});

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setInactiveGraph(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [inactiveGraph, isLoading, hasError ]
        }
    }, [props.quarter, props.pastYear])

    return {inactiveGraph, isLoading, hasError}
}


export function useDeptWithTeams(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [depts, setDepts] = useState([]);
    const [teams, setTeams] = useState([[]]);
    const [salesExecutives, setSalesExecutive] = useState([[[]]]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getDeptWithTeams();

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setDepts(data);
                   setTeams(getTeams(data));
                   setSalesExecutive(getSalesExecutives(data));
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [depts, teams, salesExecutives, isLoading, hasError ]
        }
    }, [])

    const getTeams = (data) => {
        return data.map(dat => dat.teams);
    }

    const getSalesExecutives = (data) => {
        return data.map(dat=> {
            return dat.teams.map(tea => tea.members)
        });
    }

    return {depts, teams, salesExecutives, isLoading, hasError}
}

export function useChurnRateForGraph(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [inactiveGraph, setInactiveGraph] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getInactiveByTeam(props.queryString);

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setInactiveGraph(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [inactiveGraph, isLoading, hasError ]
        }
    }, [props.queryString])

    return {inactiveGraph, isLoading, hasError}
}

export function useSalesmenTarget(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [inactiveGraph, setInactiveGraph] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAllSalesExecutiveByTarget();

            if(results.statusCode === 200) {
                let {data, message} = results;

                   setInactiveGraph(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [inactiveGraph, isLoading, hasError ]
        }
    }, [])

    return {inactiveGraph, isLoading, hasError}
}