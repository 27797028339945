import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import 'antd/dist/antd.css';
import { Steps, Button, message } from 'antd';
import _default from 'antd/lib/time-picker';

const { Step } = Steps;

const steps = [
  {
    title: 'Lead Response Time',
    content: 'First-content',
  },
    {
      title: 'Introduction',
      content: 'Second-content',
    },
    {
      title: 'Qualification',
      content: 'Third-content',
    },
    {
      title: 'Follow Up',
      content: 'Fourth-content',
    },
    {
      title: 'Sale',
      content: 'Last-content',
    },
    
  ];

function Stepper({stageId}) {

    // const [current, setCurrent] = useState(0)
    return (
        
              <Steps current={Number(stageId) - 1 } 
              style={{width: '100%'}}> 
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        
        
    )
}

Stepper.propTypes = {

}

export default Stepper

