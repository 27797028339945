import axios from 'axios';
import * as localStore from '../helpers/localStorageHelper';
import _ from 'lodash';

const VERSION_ONE = 'v1';
const VERSION_TWO = 'v2';

// const apiUrl = 'https://erpapi.concept-nova.com/api/v1/';
// const apiUrl = 'http://157.245.37.253/serviceAPI/public/api/v1/';
// const apiUrl = 'http://localhost:8000/api/v1/';
const apiUrl = process.env.REACT_APP_API_V1_URL;
const rootURL = process.env.REACT_APP_API_URL;


function init() {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      // "Content-Type": "multipart/form-data",
      'Authorization': `Bearer ${localStore.getToken()}`
    }

    const redirectToLogin = () => {
      let originalUrl = window.location.href;
      console.log(originalUrl);
      localStore.storeHistoryLocation(originalUrl);
      let publicUrl = process.env.PUBLIC_URL;
      let loginPageUrl = publicUrl + '/login';
      let origin = window.location.origin;
      if (window.location.origin === origin + loginPageUrl) return;
      window.location.href = loginPageUrl;
  }

    const instance = axios.create({
        baseURL: apiUrl,
        timeout: 50000,
        headers
      });

      instance.interceptors.response.use(function (response) {
        response = { ...response, status: 200, statusCode: 200 };
        return response;
    }, function (error) {
        if (error.response) {
            
            let status = error.response.status;
            if (status === 401) {
                redirectToLogin();
            }

            if (status === 422) {
              // console.log('the status code is 422 ', error.response.data.error);
              return {error: error.response.data.error? error.response.data.error : error.response.data , statusCode: 422}

            }
        }
        // let customError = Promise.reject(error)
        return {error, statusCode: 500};
        // return { error: 'Unable to connect to the internet', statusCode: 500 }

    })

      return instance;
}

    // To process results 
   function processResult(response, version) {
      // console.log('I am logging the status code', response);
      let {statusCode} = response;
      
    //   console.log(response);
      if(statusCode === 200) {
          let data = null;
          let message = null;

          if(version === VERSION_ONE){
          data = response.data.success
      } else {
          data = response.data.data;
          message = response.data.message;

      }
          return {statusCode, data, message};
      }
       else if(statusCode === 201) {
          return {statusCode: 200, data: response.data.data, message: response.data.message}
      } else if(statusCode === 500) {
        // console.log(response);
    let error = "Internal Server Error";
    // let error = response.error;
          return {statusCode, error}
      }
      else if (statusCode === 422){
        console.log(response);
        return {statusCode, error: response.error}
      } 
      else {
          return {statusCode, error: response.message};
      }

  }

  
function postLogin(data) {


   return axios.post(rootURL + '/login', data)
  // return axios.post('http://157.245.37.253/serviceAPI/public/api/login', data)
  // return axios.post('http://localhost:8000/api/login', data)
    .then(res=> {
        let {data} = res.data;
        localStore.setToken(data.token);
        localStore.setUserId(data.info.id);
        localStore.setExpiresAt(data.expiresin);
        localStore.setUserName(data.info.name);
        localStore.setRole(data.info.roles[0].id);
        localStore.setTeam(data.info.team? data.info.team.team_id: null)
        localStore.setTeamName(data.info.team? data.info.team.team_details.name: null);
        localStore.setTeamLead(data.info.team? data.info.team.team_lead_status: null)
        return {statusCode: 200, data: 'Success'}
    }).catch(error => {
        
        if(error.response) {
           if( error.response.status == 401) {
               return {statusCode: 401, data: "Email and password does not match any record"}
           }
           else {
            return {statusCode: 500, data: "Error in internet connection"} 
           }
        }else {
            console.log('an error ', error)
            return {statusCode: 500, data: "Error in internet connection"}
    }

  });
  }

  // ================ DASHBOARD ====================
  async function  getLeadFlowRate(id = null) {
    let results = null;
    if (id) {
      results = await init().get(`dashboard/leadflowRate?sales_id=${id}`);
  } else{
      results = await init().get(`dashboard/leadflowRate`);
  }
    return processResult(results);
  }

  async function  getAnnualBudget(year) {
     let results = await init().get(`dashboard/getAnnualBudget/${year}`);
    return processResult(results);
  }

  async function  getSalesmanTarget(year, id) {
    let results = await init().get(`dashboard/getSalemantarget/${year}?sales_id=${id}`);
   return processResult(results);
 }

  async function  getSalesAnalysis(year) {
    let results = await init().get(`dashboard/salesAnalysis/${year}`);
   return processResult(results);
 }


  async function  getUrgentLeads(id) {
    let results = null;
    if(id){
        results = await init().get(`getUrgentLeads?sales_id=${id}`);  
}
else {
    results = await init().get(`getUrgentLeads`);
}
   return processResult(results);
 }


 async function  getExpiredLeads(id) {
  let results = null;
  if(id){
      results = await init().get(`getAllExpiredLeads?sales_id=${id}`);  
}
else {
  results = await init().get(`getAllExpiredLeads`);
}
 return processResult(results);
}

 async function  getLeadsGenerated(id) {
  let results = null;
  if(id){
      results = await init().get(`dashboard/leadsGenerated?sales_id=${id}`);  
}
else {
  results = await init().get(`dashboard/leadsGenerated`);
}
 return processResult(results);
}

async function getMgtAchievedTarget(id) {
  let results = null;
  if(id) {
      results = await init().get(`dashboard/getMAchievedTarget?sales_id=${id}`);
  }else{
   results = await init().get(`dashboard/getMAchievedTarget`);
  }
  return processResult(results);  
}

async function getProductStatistics(id) {
  let results = null;
  if(id) {
      results = await init().get(`dashboard/getProductStatistics?sales_id=${id}`);
  }else{
   results = await init().get(`dashboard/getProductStatistics`);
  }
  return processResult(results);  
}

// async function getSalesAnalysis(year) {
//   let results = await init().get(`dashboard/getSalesAnalysis?sales_id=${year}`);

//   return processResult(results);  
// }

async function getProductAnalysis(selectedYear, salesmanId) {
  let results = null;
        if(salesmanId){
            results = await init().get(`dashboard/productAnalysis/${selectedYear}?sales_id=${salesmanId}`);
        } else{
            results = await init().get(`dashboard/productAnalysis/${selectedYear}`);
        }
  return processResult(results);  
}

async function getChannelStatistics(salesmanId) {
  let results = null;
        if(salesmanId){
            results = await init().get(`dashboard/getChannelStatistics?sales_id=${salesmanId}`);
        } else{
            results = await init().get(`dashboard/getChannelStatistics`);
        }
  return processResult(results);  
}

async function  getInboundAndOutBoundLeads(days) {
  let results = await init().get(`dashboard/getLeadCategorizationBySource/${days}`);
 return processResult(results);
}

async function  getLeadCategorizationBySource(days) {
  let results = await init().get(`dashboard/getLeadCategorizationBySourceStat/${days}`);
 return processResult(results);
}


  // ================ LEAD MODULE ===================
  async function getCategories() {

    let results = await init().get(`categories/select`);
    return processResult(results);
  }

  async function  getCompanies() {
    let results = await init().get(`companies/select`);
    return processResult(results);
  }

  async function  getAllCompanies() {
    let results = await init().get(`companies`);
    return processResult(results);
  }

  async function  getChannels() {
    let results = await init().get(`select/channels`);
    return processResult(results);
  }

  async function getMediums () {
    let results = await init().get(`medium/select`);
    return processResult(results);
  }


// ==========  PRODUCTS ===========
  async function getProducts () {
    let results = await init().get(`products/select`);
    return processResult(results);
  }

  async function updateProduct (data, productId) {
    let results = await init().post(`products/${productId}`, data);
    return processResult(results);
  }

  async function getAllProducts () {
    let results = await init().get(`products`);
    return processResult(results);
  }


  async function createProduct (data) {
    let results = await init().post(`products`, data);
    return processResult(results);
  }

  async function createProductModule (data) {
    let results = await init().post(`createPricingsGroup`, data);
    return processResult(results);
  }

  async function createPricingUnit (data) {
    let results = await init().post(`createPricings`, data);
    return processResult(results);
  }

  async function updatePricingUnit (data, pricingUnitId) {
    let results = await init().patch(`updatePricingUnit/${pricingUnitId}`, data);
    return processResult(results);
  }

  async function getOneProduct (productId) {
    let results = await init().get(`products/${productId}/edit`);
    return processResult(results);
  }

  async function getOneProductModules (productId) {
    let results = await init().get(`getPricingGroup/${productId}`);
    return processResult(results);
  }

  async function getPricingUnits (productModuleId) {
    let results = await init().get(`getGroupItems/${productModuleId}`);
    return processResult(results);
  }

  async function editPricingUnit (productModuleId, data) {
    let results = await init().put(`updatePricingGroup/${productModuleId}`, data );
    return processResult(results);
  }

  async function deactivatePricingUnit(id, status){
    let results = await init().get(`updateOnePricingGroup/${id}/${status}`);
    return processResult(results);
  }

  async function getContacts () {
    let results = await init().get(`contacts/select`);
    return processResult(results);
  }

  async function getAllContacts () {
    let results = await init().get(`contacts`);
    return processResult(results);
  }

  async function createContact (data) {
    let results = await init().post(`createContact`, data);
    return processResult(results);
  }

  async function getTaglines () {
    let results = await init().get(`taglines/select`);
    return processResult(results);
  }

  async function postCompanies (data) {
    let results = await init().post(`companies`, data);
    return processResult(results);
  }

  async function getCountries () {
    let results = await init().get(`countries/select`);
    return processResult(results);
  }

  async function getStates (id) {
    let results = await init().get(`getStates/${id}/select`);
    return processResult(results);
  }

  async function createLead(data) {
    let results = await init().post(`createLead`, data);
    return processResult(results);
  }

  async function createMedium(data){
    let results = await init().post(`createMedium`, data);
    return processResult(results);
  }

  async function createChannel(data){
    let results = await init().post(`createChannel`, data);
    return processResult(results);
  }

  async function createCategory(data){
    let results = await init().post(`createCategory`, data);
    return processResult(results);
  }

  async function getLeads(data){
    let results = await init().get(`allLeads`);
    return processResult(results);
  }

async function getExecutiveExpiredLeads(data) {
  let results = await init().get(`leads/expired`);
  return processResult(results);
}

async function getExecutiveInactiveLeads(data) {
  let results = await init().get(`getInactiveLeads`);
  return processResult(results);
}

  async function assignLead(data, requestParams = false) {
    if(!requestParams){
      let results = await init().post(`assignLead`, data);
      return processResult(results);
    }
    let results = await init().post(`assignLead?${requestParams}`, data);
    return processResult(results);
  }

  async function getSalesExecutives(){
    let results = await init().get(`getSalesExecutives`);
    return processResult(results);
  }

  async function getReasonForEnquiry(productGroupId){
    let results = await init().get(`getReasonForEnquiry/${productGroupId}`);
    return processResult(results);
  }

  // ============== SALES MODULE ==================
  async function getSaleLeads(){
    let results = await init().get(`getMySales`);
    return processResult(results);
  }

  async function getOneLead(id){
    let results = await init().get(`oneLead/${id}`);
    return processResult(results);
  }

  async function getAssignmentHistory(id){
    let results = await init().get(`assigneeHistory/${id}`);
    return processResult(results);
  }

  async function addContactsToALead (leadId, tagId) {
    let results = await init().get(`addLeadsTags/${leadId}/${tagId}`);
    return processResult(results);
  }
  async function getGeneralComments(id){

    let results = await init().get(`getLeadComments/${id}`);
    return processResult(results);
  }

  async function getStageLogs(id){
    let results = await init().get(`stageLog/${id}`);
    return processResult(results);
  }
// This endpoint moves it to the next substage
  async function updateSubstage(data){
    let results = await init().post(`updateSubStage`, data);
    return processResult(results);
  }

// This only adds a lead comment to the current stage
async function addLeadComment(data){
  let results = await init().post(`addLeadsComment`, data);
  return processResult(results);
}

// This endpoint moves it to the next stage
async function updateStage(data){
  let results = await init().post(`updateStage`, data);
  return processResult(results);
}

// INVOICE ENDPOINTS
async function createInvoice(leadId, quantity){
  let results = await init().get(`updateLeadQuantity/${leadId}/${quantity}`);
  return processResult(results);
}

async function getInvoicePricing(productId){
  let results = await init().get(`getSalesPrice_new/${productId}`);
  return processResult(results);
}

async function postCreateNewInvoice(data){
  let results = await init().post(`createNewInvoice`, data);
  return processResult(results);
}

async function getSingleInvoice(invoiceId){
  let results = await init().get(`getOneInvoice_new/${invoiceId}`);
  return processResult(results);
}

async function markInvoiceAsPaid(invoiceId, leadId) {
  let results = await init().get(`updateOneInvoice/${invoiceId}/Paid/${leadId}`);
  return processResult(results);
}

// Marketing Materials
async function getMarketingMaterials(){
  let results = await init().get(`getMarketingMaterials`);
  return processResult(results);
}

async function postGeneralComment(data){
  let results = await init().post(`generalComment`, data);
  return processResult(results);
}

// Bi Questionnaire
async function postBIQuestionnaire(data){
  let results = await init().post(`answerQuestion`, data);
  return processResult(results);
}

async function getBIQuestionnaire(leadId){
  let results = await init().get(`getBIQuestionaire?lead_id=${leadId}`);
  return processResult(results);
}

// =========== EXTENSION REQUESTS =============
async function getExtensionRequest(teamId){
  let results = null;
  if(teamId != 'null'){
     results = await init().get(`getExtensionRequest?team_id=${teamId}`);
    return processResult(results);
  }
   results = await init().get(`getExtensionRequest`);
  return processResult(results);
}

async function postExtensionRequest(data){
  let results = await init().post(`sendExtensionRequest`, data);
  return processResult(results);
}

async function approveExtensionRequest(id){
  let results = await init().get(`approveExtension/${id}`);
  return processResult(results);
}

async function rejectExtensionRequest(id){
  let results = await init().get(`rejectExtension/${id}`);
  return processResult(results);
}

// =========== INACTIVE LEADS ===============
async function postReasonForExpiration(data){
  let results = await init().post(`addReasonForInactiveLead`, data);
  return processResult(results);
}

async function postTelesalesMeaningfulEngagement(data){
  let results = await init().post(`postTelesalesMeaningfulEngagement`, data);
  return processResult(results);
}

async function getInactiveLeads(status){
  let results = await init().get(`getInactiveLeads?status=${status}`);
  return processResult(results);
}

async function addInactiveLeadComment(data){
  let results = await init().post(`addCommentToInactiveLead`, data);
  return processResult(results);
}

async function getInactiveLeadComment(leadId){
  let results = await init().get(`getInactiveLeadReport/${leadId}`);
  return processResult(results);
}

async function approveReasonForInactive(leadId){
  let results = await init().get(`approveInactiveLead/${leadId}`);
  return processResult(results);
}

async function sendInactiveReassignmentRequest(data){
  let results = await init().post(`sendInactiveAdvancementRequest`, data);
  return processResult(results);
}

async function getInactiveRequests(){
  let results = await init().get(`getInactiveAdvancementRequests`);
  return processResult(results);
}

async function acceptReassignmentRequest(leadId){
  let results = await init().get(`acceptInactiveAdvancementRequest/${leadId}`);
  return processResult(results);
}

async function sendInactiveLeadReassignment(data){
  let results = await init().post(`sendInactiveLeadReassignment`, data);
  return processResult(results);
}

async function getInactiveReassignmentRequests(){
  let results = await init().get(`getInactiveReassignmentRequests`);
  return processResult(results);
}

async function approveInactiveReassignmentRequest(leadId){
  let results = await init().get(`approveInactiveReassignmentRequest/${leadId}`);
  return processResult(results);
}

// =========== REQUALIFICATION REQUESTS =============
async function getRequalificationRequest(teamId){
  let results = null;

  if(teamId != 'null'){
  results = await init().get(`getRequalificationRequest?team_id=${teamId}`);
  return processResult(results);
  }
  results = await init().get(`getRequalificationRequest`);
     
  return processResult(results);
  
}

async function approveRequalificationRequest(id){
  let results = await init().get(`approveRequalification/${id}`);
  return processResult(results);
}

async function rejectRequalificationRequest(id){
  let results = await init().get(`rejectRequalification/${id}`);
  return processResult(results);
}

async function getControlVariables(){
  let results = await init().get(`getControllableVariable`);
  return processResult(results);
}

async function postRequalificationRequest(data){
  let results = await init().post(`sendRequalificationRequest`, data);
  return processResult(results);
}

// =========== ADVANCEMENT REQUESTS =============
async function getAdvancementRequest(teamId){
  let results = null;
  if(teamId != 'null'){
    results = await init().get(`getCommentsForApproval?team_id=${teamId}`);
    return processResult(results);
  }
  results = await init().get(`getCommentsForApproval`);
  return processResult(results);
}

async function getOneAdvancementRequest(leadId, stageId){
  let results = await init().get(`getCommentApprovalStatus/${leadId}`);
  return processResult(results);
}

async function postAdvancementRequest(data){
  let results = await init().post(`sendCommentForApproval`, data);
  return processResult(results);
}

async function approveAdvancementRequest(leadId, stageId, data){
  let results = await init().post(`approveComment/${leadId}/${stageId}`, data);
  return processResult(results);
}

async function rejectAdvancementRequest(leadId, stageId, data){
  let results = await init().post(`disapproveComment/${leadId}/${stageId}`, data);
  return processResult(results);
}

async function getAdvancementComments(leadId){
  let results = await init().get(`getApprovalComment/${leadId}`);
  return processResult(results);
}

// =========== REASSIGNMENT REQUESTS =============
async function postReassignmentRequest(data){
  let results = await init().post(`leadRequest`, data);
  return processResult(results);
}

async function getReassignmentRequest (teamId) {
  let results = null;
  if(teamId != 'null'){
    results = await init().get(`leadRequests?team_id=${teamId}`);
    return processResult(results);
  }
   results = await init().get(`leadRequests`);
  return processResult(results);

}

async function approveReassignmentRequest (leadId) {
  let results = await init().get(`reAssignAccept/${leadId}`);
  return processResult(results);
}

async function rejectReassignmentRequest (leadId) {
  let results = await init().get(`rejectLeadRequest/${leadId}`);
  return processResult(results);
}
// =========== PRODUCT CHANGE REQUESTS =============

async function postProductChange (data) {
  let results = await init().post(`changeLeadProduct`, data);
  return processResult(results);
}

async function getProductChangeRequest () {
  let results = await init().get(`getChangeLeadProductRequests`);
  return processResult(results);
}

async function updateProductChangeRequest (id, status) {
  let results = await init().get(`updateLeadProductRequest/${id}/${status}`);
  return processResult(results);
}


// ============ TAT MGT =============
async function getAllStages(){
  let results = await init().get(`getLeadStages`);
  return processResult(results);
}

async function getAllTat(){
  let results = await init().get(`getTATs`);
  return processResult(results);
}

async function createTAT(data, stageId){
  let results = await init().put(`updateTAT/${stageId}`, data);
  return processResult(results);
}

async function getRequalificationTat(){
  let results = await init().get(`getRequalificationTATs`);
  return processResult(results);
}

async function updateRequalificationTat(data, id){
  let results = await init().put(`updateRequalificationTAT/${id}`, data);
  return processResult(results);
}

// ============== BANT ===============
async function getBantMetric(id){
  let results = await init().get(`getBantMetric/${id}`);
  return processResult(results);
}

async function createBantMetric(data){
  let results = await init().post(`createBantMetrics`, data);
  return processResult(results);
}

// ================ INVOICES ==============
async function getInvoices(id){
  let results = await init().get(`getLeadInvoices/${id}`);
  return processResult(results);
}


// ================ USERS ==============
async function getUsers(id){
  let results = await init().get(`getUsers`);
  return processResult(results);
}

async function getOneUser(id){
  let results = await init().get(`getUser/${id}`);
  return processResult(results);
}
async function postUsers(data){
  let results = await init().post(`createUser`, data);
  return processResult(results);
}

async function editUser(data){
  let results = await init().post(`updateUserDetails`, data);
  return processResult(results);
}

// ================= ROLES ===============
async function getRoles(){
  let results = await init().get(`getRoles`);
  return processResult(results);
}

async function createRole(data){
  let results = await init().post(`createRole`, data);
  return processResult(results);
}

// ============== RENEWALS ================
async function getRenewalAccounts(){
  let results = await init().get(`getRenewalAccount`);
  return processResult(results);
}

async function addRenewalAction(data){
  let results = await init().post(`addRenewalAction`, data);
  return processResult(results);
}

async function getRenewalAction(renewalAccountId){
  let results = await init().get(`getRenewalAction/${renewalAccountId}`);
  return processResult(results);
}

async function getRenewalComment(renewalAccountId){
  let results = await init().get(`getRenewalComment/${renewalAccountId}`);
  return processResult(results);
}
async function addReasonForNotRenewing(data){
  let results = await init().post(`addRenewalComment`, data);
  return processResult(results);
}

async function markARenewalAsPaid(data){
  let results = await init().post(`markAsPaid`, data);
  return processResult(results);
}

async function getAllRenewals(){
  let results = await init().get(`getAllRenewals`);
  return processResult(results);
}

async function getOneRenewal(leadId){
  let results = await init().get(`getRenewalAccountDetail/${leadId}`);
  return processResult(results);
}

async function getRenewalFees(){
  let results = await init().get(`getRenewalFees`);
  return processResult(results);
}

async function getOneRenewalFee(id){
  let results = await init().get(`editRenewalFee/${id}`);
  return processResult(results);
}

async function getTransactionHistory(id){
  let results = await init().get(`getAccountDeals/${id}`);
  return processResult(results);
}

async function updateRenewalFee(data){
  let results = await init().put(`updateRenewalFee`, data);
  return processResult(results);
}

async function getDealHistory(renewalId, accountId){
  let results = await init().get(`getAccountDealHistory/${renewalId}/${accountId}`);
  return processResult(results);
}

async function createReminder(data){
  let results = await init().post(`createReminder`, data);
  return processResult(results);
}

async function updateReminder(data, id){
  let results = await init().put(`updateReminder/${id}`, data);
  return processResult(results);
}

async function getDealReminders(accountDealId){
  let results = await init().get(`getDealReminder/${accountDealId}`);
  return processResult(results);
}

async function getOneReminder(reminderId){
  let results = await init().get(`editReminder/${reminderId}`);
  return processResult(results);
}

async function getAllDealReminders(){
  let results = await init().get(`getAllDealsReminder`);
  return processResult(results);
}

async function getLatestInfo(accountId){
  let results = await init().get(`getLatestRenewalInfo/${accountId}`);
  return processResult(results);
}

async function deleteReminder(id){
  let results = await init().delete(`deleteReminderAction/${id}`);
  return processResult(results);
}

async function getOneAccountReminders(accountId){
  let results = await init().get(`getAccountReminder/${accountId}`);
  return processResult(results);
}

async function getCustomerChurnRate(category){
  if(category){
    let results = await init().get(`getCustomerChurnRate?category=${category}`);
    return processResult(results);
  }
  let results = await init().get(`getCustomerChurnRate`);
  return processResult(results);
 
}

async function getRenewalInvoice(renewalHistoryId){
  let results = await init().get(`renewal-invoice/${renewalHistoryId}`);
  return processResult(results);
}

async function getPendingDeals(){
  let results = await init().get(`get-account-deals-for-approval`);
  return processResult(results);
}

async function getOnePendingDeal(accountDealId){
  let results = await init().get(`get-account-deals-for-approval/${accountDealId}`);
  return processResult(results);
}

async function getPricingPlans(){
  let results = await init().get(`get-pricing-plans`);
  return processResult(results);
}

async function updateDealAccount(data){
  let results = await init().post(`update-deal-account`, data);
  return processResult(results);
}

async function getDevicesForDeal(accountDealId){
  let results = await init().get(`get-devices/${accountDealId}`);
  return processResult(results);
}


async function addDevicesToADeal(data){
  let results = await init().post(`control/installation-device`, data);
  return processResult(results);
}

async function getSingleDevice(deviceId){
  let results = await init().get(`control/installation-device/${deviceId}`);
  return processResult(results);
}

  // ============== TEAM MANAGEMENT ================
async function getTeams(){
  let results = await init().get(`getActiveTeams`);
  return processResult(results);
}

async function getAllTeams(){
  let results = await init().get(`getTeams`);
  return processResult(results);
}

async function getTeamDetail(id){
  let results = await init().get(`getTeam/${id}`);
  return processResult(results);
}
async function createTeam(data){
  let results = await init().post(`createTeam`, data);
  return processResult(results);
}

async function addUsersToTeam(data){
  let results = await init().post(`addTeamUser`, data);
  return processResult(results);
}

async function makeTeamLead(data){
  let results = await init().post(`promoteToTeamLead`, data);
  return processResult(results);
}

async function removeFromTeam(userId){
  let results = await init().get(`removeFromTeam/${userId}`);
  return processResult(results);
}

async function updateTeam(data){
  let results = await init().put(`updateTeam`, data);
  return processResult(results);
}

async function getTeamCategories(){
  let results = await init().get(`getActiveTeamCategory`);
  return processResult(results);
}

async function getAllTeamCategories(){
  let results = await init().get(`getTeamCategory`);
  return processResult(results);
}

async function getOneTeamCategory(deptId){
  let results = await init().get(`editTeamCategory/${deptId}`);
  return processResult(results);
}
async function createTeamCategory(data){
  let results = await init().post(`createTeamCategory`, data);
  return processResult(results);
}

async function updateDept(deptId, data){
  let results = await init().put(`updateTeamCategory/${deptId}`, data);
  return processResult(results);
}

async function getTeamExpiredLead(teamId) {
  let userId = localStore.getUserId();
  let results = await init().get(`getAllExpiredLeads?team_id=${teamId}&sender_id=${userId}`);  
  return processResult(results);
}

async function postTeamExpiredLeadRequest(data) {
  let results = await init().post(`requestExpiredLead`, data);  
  return processResult(results);
}

async function getExpiredLeadRequests() {
  let results = await init().get(`getExpiredLeadRequest`);  
  return processResult(results);
}

async function approvedExpiredLeadRequest(id) {
  let results = await init().get(`approveExpiredLeadRequest/${id}`);  
  return processResult(results);
}

async function rejectExpiredLeadRequest(id) {
  let results = await init().get(`rejectExpiredLeadRequest/${id}`);  
  return processResult(results);
}

async function deactivateTeam(teamId) {
  let results = await init().get(`changeTeamStatus/${teamId}?status=deactivate`);  
  return processResult(results);
}

async function activateTeam(teamId) {
  let results = await init().get(`changeTeamStatus/${teamId}?status=activate`);  
  return processResult(results);
}

async function activateDept(departmentId) {
  let results = await init().get(`changeTeamCategoryStatus/${departmentId}?status=activate`);  
  return processResult(results);
}

async function deactivateDept(departmentId) {
  let results = await init().get(`changeTeamCategoryStatus/${departmentId}?status=deactivate`);  
  return processResult(results);
}
// ============== MEANINGFUL ENGAGEMENT ================
async function getOneMeaningfulEngagement(stageId, substageId) {
  let results = await init().get(`getMeaningfulEngagementQuestion?stage_id=${stageId}&substage_id=${substageId}`);  
  return processResult(results);
}

async function postOneMeaningfulEngagement(data) {
  let results = await init().post(`answerMeaningfulEngagementQuestion`, data);  
  return processResult(results);
}

async function getAllMeaningfulEngagement(leadId) {
  let results = await init().get(`getEngagmentReponse/${leadId}`);  
  return processResult(results);
}

async function getListOfMeaningfulEngagement() {
  let results = await init().get(`getMeaningFulEngagementId`);  
  return processResult(results);
}

async function postSalesmanTarget(data) {
  let results = await init().post(`dashboard/createSalemantarget`, data);  
  return processResult(results);
}

async function getAllSalesExecutiveByTarget() {
  let results = await init().get(`/dashboard/getAllSalesTarget/2020`);
    return processResult(results);
}

async function getTeamPipelineVelocity(team) {
  let results = await init().get(`getTeamPipelineVelocity?team=${team}`);
    return processResult(results);
}

async function getAveragePipelineVelocityGraph(team) {
  let results = await init().get(`getAveragePipelineVelocityGraph?team=${team}`);
    return processResult(results);
}


async function getPipelineVelocityAgainstSalesTargetGraph(team) {
  let results = await init().get(`getPipelineVelocityAgainstSalesTargetGraph?team=${team}`);
    return processResult(results);
}

async function getExecutivePipelineVelocity(executiveId, teamName) {
  let results = await init().get(`getExecutivePipelineVelocity?team=${teamName}&executive_id=${executiveId}`);
    return processResult(results);
}

// ============== VARIABLE MANAGEMENT ================
async function getVariables(data){
  let results = await init().get(`getVariable`);
  return processResult(results);
}
async function createVariable(data){
  let results = await init().post(`createReqVariable`, data);
  return processResult(results);
}

// ============== REPORTS ===============
async function getDepartmentRevenue(){
  let results = await init().get(`dashboard/getDepartmentRevenue`);
  return processResult(results);
}
async function getDepartmentExecutives(){
  let results = await init().get(`dashboard/getDepartmentExecutives`);
  return processResult(results);
}

async function getExtensionReport(){
  let results = await init().get(`getTeamExtensionReportOverview`);
  return processResult(results);
}

async function getTeamExtensionReport(teamId){
  let results = await init().get(`getExtensionReportOverview?team_id=${teamId}`);
  return processResult(results);
}

async function getIndividualExtensionReport(individualId){
  let results = await init().get(`getExtensionReportOverview?sales_id=${individualId}`);
  return processResult(results);
}

async function getAllExecutiveExtensionRequest(){
  let results = await init().get(`getExecutiveLeadCount`);
  return processResult(results);
}

async function getLeadExtensionReport(leadId){
  let results = await init().get(`getExtensionReportDetails/${leadId}`);
  return processResult(results);
}

// ============== GRAPHICAL REPORTS ===============

async function getDeptWithTeams(){
  let results = await init().get(`getDepartmentWithTeams`);
  return processResult(results);
}

async function getInactiveByTeam(queryString){
  let results = await init().get(`inactiveLeadByTeam?${queryString}`);
  return processResult(results);
}

async function getInactiveLeadGraph(year, quarter){
  let results = await init().get(`inactiveLead?year=${year}&quarter=${quarter}`);
  return processResult(results);
}

async function getNumberOfConvertedInactiveLeadGraph({year, quarter, pastYears}){
  if(quarter){
    let results = await init().get(`inactiveAndConvertedLead?year=${year}&quarter=${quarter}`);
  return processResult(results);
  }
  if(pastYears){
    let results = await init().get(`inactiveAndConvertedLead?pastyears=${true}`);
    return processResult(results);
  }
  let results = await init().get(`inactiveAndConvertedLead?year=${year}&quarter=${quarter}`);
  return processResult(results);
}

async function getWeeklyEngagementReport(team){
  let results = await init().get(`getTeamWeeklyEngagement?team=${team}`);
  return processResult(results);
}

async function getYearlyEngagementReport(team){
  let results = await init().get(`getTeamYearlyEngagement?year=2021&team=${team}`);
  return processResult(results);
}

// ============== PERFORMANCE REPORTS ===============
async function getWeeklySalesQuota(){
  let results = await init().get(`getWeeklySalesQuotaTeams`);
  return processResult(results);
}

async function getWeeklySalesQuotaByTeamPercentage(team){
  let results = await init().get(`getWeeklySalesQuotaIndividualPercentage?team=${team}`);
  return processResult(results);
}

async function getWeeklySalesQuotaByTeam(team){
  let results = await init().get(`getWeeklySalesQuotaIndividual?team=${team}`);
  return processResult(results);
}

async function getWeeklySalesandTarget(team){
  let results = await init().get(`getWeeklySalesandTarget?team=${team}`);
  return processResult(results);
}

async function getCummulativeSalesandTarget(team, year){
  let results = await init().get(`getCummulativeSalesandTarget?team=${team}&year=${year}`);
  return processResult(results);
}

async function getExtensionRequestCount(team){
  let results = await init().get(`getExtensionRequestCount?team=${team}`);
  return processResult(results);
}

async function getAverageSalesCycleByTeam(team){
  let results = await init().get(`getAverageSalesCycleByTeam?year=2021&team=${team}`);
  return processResult(results);
}

async function getExtensionReportByStages(){
  let results = await init().get(`getExtensionRequestByStages`);
  return processResult(results);
}


async function getAverageSalesCycle(year){
  let results = await init().get(`getAverageSalesCycle?year=${year}`);
  return processResult(results);
}

async function getWeeklySalesAndTargetTeam(){
  let results = await init().get(`getWeeklySalesandTargetTeams`);
  return processResult(results);
}

// ============== INDUSTRY ==================
async function createIndustry(data){
  let results = await init().post(`createNewTagline`, data);
  return processResult(results);
}

async function getOneIndustry(id){
  let results = await init().get(`getTagline/${id}`);
  return processResult(results);
}

async function updateIndustry(data, id){
  let results = await init().put(`updateTagline/${id}`, data);
  return processResult(results);
}

// ============== EMAIL ==================
async function createEmailCategory(data){
  let results = await init().post(`createEmailCategory`, data);
  return processResult(results);
}

async function getEmailCategories(){
  let results = await init().get(`getEmailCategory`);
  return processResult(results);
}

async function getOneEmailCategory(id){
  let results = await init().get(`editEmailCategory/${id}`);
  return processResult(results);
}

async function updateEmailCategory(data, id){
  let results = await init().put(`updateEmailCategory/${id}`, data);
  return processResult(results);
}

async function deleteEmailCategory(emailCategoryId){
  let results = await init().delete(`deleteEmailCategory/${emailCategoryId}`);
  return processResult(results);
}

async function postCustomRenewalEmail(data){
  let results = await init().post(`customRenewalEmail`, data);
  return processResult(results);
}

async function getReminderEmailDetails(accountId){
  let results = await init().get(`getReminderEmailDetails/${accountId}`);
  return processResult(results);
}

// ============== TELESALES ==================
async function getDeadLeads(){
  let results = await init().get(`getAllDeadLeads`);
  return processResult(results);
}

async function getOneDeadLead(id){
  let results = await init().get(`getDeadLead/${id}`);
  return processResult(results);
}

async function postDeadLeadComment(data){
  let results = await init().post(`addCommentToDeadLead`, data);
  return processResult(results);
}

async function getTelesalesMonthlyReport(month, year){
  let results = null;
  if(month){
    results = await init().get(`getTelesalesReport?year=${year}&month=${month}`);
  } else{
    results = await init().get(`getTelesalesReport`);
  }
  return processResult(results);
}

async function getOldTelesalesMonthlyReport(month, year){
  let results = null;
  if(month){
    results = await init().get(`getUnupdatedTelesalesReport?year=${year}&month=${month}`);
  } else{
    results = await init().get(`getUnupdatedTelesalesReport`);
  }
  return processResult(results);
}

//========= CONTROL ========
async function getMakes(){
  let results = await init().get(`control/vehicle-make`);
  return processResult(results);
}

async function createMakes(data){
  let results = await init().post(`control/vehicle-make`, data);
  return processResult(results);
}

async function createModel(data){
  let results = await init().post(`control/vehicle-models`, data);
  return processResult(results);
}

async function getModels(makeId){
  let results = await init().get(`control/vehicle-make/${makeId}/models`);
  return processResult(results);
}

async function createDirectory(data){
  let results = await init().post(`control/directory`, data);
  return processResult(results);
}

async function createCustomer(data){
  let results = await init().post(`control/customers`, data);
  return processResult(results);
}

async function getOneCustomer(customerId){
  let results = await init().get(`control/customers/${customerId}`);
  return processResult(results);
}

async function createGroup(data){
  let results = await init().post(`control/groups`, data);
  return processResult(results);
}

async function getGroups(){
  let results = await init().get(`control/groups`);
  return processResult(results);
}

async function createPlatform(data){
  let results = await init().post(`control/platforms`, data);
  return processResult(results);
}

async function getPlatforms(){
  let results = await init().get(`control/platforms`);
  return processResult(results);
}

async function createInstallationType(data){
  let results = await init().post(`control/installation-type`, data);
  return processResult(results);
}

async function getInstallationType(){
  let results = await init().get(`control/installation-type`);
  return processResult(results);
}

async function createInstaller(data){
  let results = await init().post(`control/installers`, data);
  return processResult(results);
}

async function getInstaller(){
  let results = await init().get(`control/installers`);
  return processResult(results);
}

async function updateDirectory(data, id){
  let results = await init().patch(`control/directory/${id}`, data);
  return processResult(results);
}

async function getAllAccounts(){
  let results = await init().get(`control/directory`);
  return processResult(results);
}

async function getAccountsThatExpireInThreeMonths(){
  let results = await init().get(`control/directory-three-months`);
  return processResult(results);
}

async function getOneControlAccountDevices(renewalAccountId){
  let results = await init().get(`control/devices/${renewalAccountId}`);
  return processResult(results);
}

async function getPendingDevices(renewalAccountId){
  let results = await init().get(`control/pending-devices/${renewalAccountId}`);
  return processResult(results);
}


async function getOneControlAccountDetail(controlDirectoryId){
  let results = await init().get(`control/directory/${controlDirectoryId}`);
  return processResult(results);
}

async function getOneControlAccountHistory(controlDirectoryId){
  let results = await init().get(`control/directory-history/${controlDirectoryId}`);
  return processResult(results);
}

async function getOneControlAccountSubscriptionHistory(controlDirectoryId){
  let results = await init().get(`control/subscription-history/${controlDirectoryId}`);
  return processResult(results);
}

async function changeControlAccountStatus(controlDirectoryId, status){
  let results = await init().get(`control/edit-assessment-information/${controlDirectoryId}/${status}`);
  return processResult(results);
}

async function updateProductAndAsset(controlDirectoryId, data){
  let results = await init().patch(`control/edit-asset-information/${controlDirectoryId}`, data);
  return processResult(results);
}

async function updateInstallationDetail(controlDirectoryId, data){
  let results = await init().patch(`control/edit-installation-information/${controlDirectoryId}`, data);
  return processResult(results);
}

async function updateCustomerDetail(customerId, data){
  let results = await init().patch(`control/customers/${customerId}`, data);
  return processResult(results);
}

async function uploadControlData( data){
  let results = await init().post(`control/second-control-data-upload`, data);
  return processResult(results);
}

// ============= THE EXPORTS ==============
export default {
  postLogin,
  getCategories,
  getCompanies,
  getChannels,
  getMediums,
  getCountries,
  getStates,
  getProducts,
  getAllProducts,
  createLead,
  getContacts,
  createMedium,
  createChannel,
  createCategory,
  getLeads,
  getExecutiveExpiredLeads,
  getExecutiveInactiveLeads,
  getSaleLeads,
  getSaleLeads,
  getOneLead,
  getGeneralComments,
  getStageLogs,
  updateSubstage,
  addLeadComment,
  updateStage,
  createInvoice,
  getInvoicePricing,
  postCreateNewInvoice,
  getExtensionRequest,
  getRequalificationRequest,
  approveExtensionRequest,
  rejectExtensionRequest,
  approveRequalificationRequest,
  rejectRequalificationRequest,
  getAllStages,
  getAllTat,
  getBantMetric,
  createBantMetric,
  createTAT,
  getInvoices,
  assignLead,
  getSalesExecutives,
  getUsers,
  getRoles,
  getMarketingMaterials,
  getSingleInvoice,
  markInvoiceAsPaid,
  postExtensionRequest,
  getControlVariables,
  postRequalificationRequest,
  getAdvancementRequest,
  postAdvancementRequest,
  approveAdvancementRequest,
  rejectAdvancementRequest,
  getOneAdvancementRequest,
  getLeadFlowRate,
  getAnnualBudget,
  getSalesAnalysis,
  postUsers,
  editUser,
  getOneUser,
  getTaglines,
  postCompanies,
  createRole,
  getUrgentLeads,
  getLeadsGenerated,
  getMgtAchievedTarget,
  getProductStatistics,
  getProductAnalysis,
  createContact,
  getAssignmentHistory,
  postProductChange,
  getProductChangeRequest,
  updateProductChangeRequest,
  postReassignmentRequest,
  getChannelStatistics,
  getExpiredLeads,
  getReassignmentRequest,
  approveReassignmentRequest,
  rejectReassignmentRequest,
  getRenewalAccounts,
  getOneRenewal,
  getRenewalFees,
  getTransactionHistory,
  getOneRenewalFee,
  updateRenewalFee,
  getSalesmanTarget,
  createTeam,
  getDealHistory,
  createReminder,
  getDealReminders,
  getAllDealReminders,
  getVariables,
  createVariable,
  getTeams,
  getTeamDetail,
  makeTeamLead,
  addUsersToTeam,
  removeFromTeam,
  getDepartmentRevenue,
  getDepartmentExecutives,
  updateTeam,
  getInboundAndOutBoundLeads,
  getExtensionReport,
  createIndustry,
  getOneIndustry,
  updateIndustry,
  getLatestInfo,
  deleteReminder,
  getOneReminder,
  updateReminder,
  createEmailCategory,
  getEmailCategories,
  getOneEmailCategory,
  updateEmailCategory,
  getTeamExtensionReport,
  getLeadExtensionReport,
  getLeadCategorizationBySource,
  postGeneralComment,
  deleteEmailCategory,
  getTeamCategories,
  createTeamCategory,
  getOneTeamCategory,
  updateDept,
  getTeamExpiredLead,
  postTeamExpiredLeadRequest,
  getExpiredLeadRequests,
  getRequalificationTat,
  approvedExpiredLeadRequest,
  rejectExpiredLeadRequest,
  updateRequalificationTat,
  getDeadLeads,
  getOneDeadLead,
  postDeadLeadComment,
  getIndividualExtensionReport,
  getAllExecutiveExtensionRequest,
  getAllRenewals,
  addRenewalAction,
  markARenewalAsPaid,
  getRenewalAction,
  addReasonForNotRenewing,
  getRenewalComment,
  getAdvancementComments,
  postBIQuestionnaire,
  postCustomRenewalEmail,
  getOneAccountReminders,
  getReminderEmailDetails,
  getTelesalesMonthlyReport,
  getOldTelesalesMonthlyReport,
  addContactsToALead,
  getCustomerChurnRate,
  deactivateTeam,
  activateTeam,
  deactivateDept,
  activateDept,
  getOneMeaningfulEngagement,
  postOneMeaningfulEngagement,
  getAllMeaningfulEngagement,
  getAllTeamCategories,
  getAllTeams,
  getBIQuestionnaire,
  getAllCompanies,
  getAllContacts,
  postReasonForExpiration,
  getInactiveLeads,
  addInactiveLeadComment,
  getInactiveLeadComment,
  getListOfMeaningfulEngagement,
  postTelesalesMeaningfulEngagement,
  approveReasonForInactive,
  getInactiveLeadGraph,
  getNumberOfConvertedInactiveLeadGraph,
  getDeptWithTeams,
  getInactiveByTeam,
  getAllSalesExecutiveByTarget,
  postSalesmanTarget,
  getWeeklySalesQuota,
  getWeeklySalesQuotaByTeam,
  getWeeklySalesQuotaByTeamPercentage,
  sendInactiveReassignmentRequest,
  getInactiveRequests,
  acceptReassignmentRequest,
  getWeeklyEngagementReport,
  getYearlyEngagementReport,
  getExtensionRequestCount,
  getAverageSalesCycleByTeam,
  getExtensionReportByStages,
  getAverageSalesCycle,
  getWeeklySalesandTarget,
  getCummulativeSalesandTarget,
  getReasonForEnquiry,
  sendInactiveLeadReassignment,
  getInactiveReassignmentRequests,
  approveInactiveReassignmentRequest,
  getWeeklySalesAndTargetTeam,
  getTeamPipelineVelocity,
  getExecutivePipelineVelocity,
  getAveragePipelineVelocityGraph,
  getPipelineVelocityAgainstSalesTargetGraph,
  createProduct,
  createProductModule,
  createPricingUnit,
  getOneProduct,
  getOneProductModules,
  getPricingUnits,
  editPricingUnit,
  deactivatePricingUnit,
  getMakes,
  getModels,
  getGroups,
  createMakes,
  createModel,
  createDirectory,
  createCustomer,
  createGroup,
  createPlatform,
  getPlatforms,
  createInstallationType,
  getInstallationType,
  updateDirectory,
  createInstaller,
  getInstaller,
  getAccountsThatExpireInThreeMonths,
  getAllAccounts,
  getOneControlAccountDetail,
  getOneControlAccountHistory,
  changeControlAccountStatus,
  getOneControlAccountSubscriptionHistory,
  getRenewalInvoice,
  updateProductAndAsset,
  updateInstallationDetail,
  getOneCustomer,
  updateCustomerDetail,
  getPendingDeals,
  getOnePendingDeal,
  getPricingPlans,
  updateDealAccount,
  getDevicesForDeal,
  addDevicesToADeal,
  getSingleDevice,
  updatePricingUnit,
  getOneControlAccountDevices,
  getPendingDevices,
  updateProduct,
  uploadControlData,
};
