import React, { useState, useMemo } from "react";
import { usePageValue, useApi } from "../../../context";
import { Drawer, PageTitle } from "../../units/molecules";
import { useAllRenewals, useDevicesForDeal, useNotRenewingComment, usePricingPlans } from "../../../hooks";
import { ReactTable, Link, NumberFormat } from "../../units/atoms";
import { formatDate } from "../../../helpers";
import {
  Typography,
  Menu,
  Dropdown,
  Button,
  Modal as ConfirmModal,
  Row, Col, Modal, Divider, Form, Input, Checkbox, Select
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Modal as CustomModal } from "../../units/molecules/modals";
import AddAction from "./unit/AddAction";
import AddActionForNotRenewing from "./unit/AddReasonForNotRenewing";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {FormGroup, FullFormGroup} from '../../units/utils';

const { Text } = Typography;

const { confirm } = ConfirmModal;

const {Option} = Select;

export default function RenewalAccounts() {
  const breadcrumbs = [
    { label: "Dashboard", path: "/dashboard" },
    { label: " Renewals" },
  ];

  const [showAddAction, setShowAddAction] = useState(false);
  const [showNotRenewing, setShowNotRenewing] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [refreshPage, setRefreshPage] = useState(0);

  const [showEnterQuantity, setShowEnterQuantity] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState({});


  let api = useApi();
  let {
    setPageLoading,
    setPageError,
    setPageSuccess,
    setMessage,
  } = usePageValue();
  let { renewals, isLoading } = useAllRenewals({ refreshPage });

  setPageLoading(isLoading);

  const handleMenuClick = (accountDealId, renewalAccountId) => {
    setShowAddAction(!showAddAction);
    setSelectedDetails({ accountDealId, renewalAccountId });
  };

  const handleAddReasonMenuClick = (accountDealId, renewalAccountId) => {
    setShowNotRenewing(!showNotRenewing);
    setSelectedDetails({ accountDealId, renewalAccountId });
  };

  const handleViewReason = (accountDealId, renewalAccountId) => {
    setShowReason(!showReason);
    setSelectedDetails({ accountDealId, renewalAccountId });
  };

  const menu = (accountDealId, renewalAccountId) => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => handleMenuClick(accountDealId, renewalAccountId)}
      >
        Add Action
      </Menu.Item>
      <Menu.Item key="2">
        <span
          className="badge badge-info"
          onClick={() =>
            handleAddReasonMenuClick(accountDealId, renewalAccountId)
          }
        >
          Add Reason
        </span>
      </Menu.Item>

      <Menu.Item key="2">
        <span
          className="badge badge-info"
          onClick={() =>
            handleViewReason(accountDealId, renewalAccountId)
          }
        >
          View Reasons
        </span>
      </Menu.Item>
    </Menu>
  );

  const handleChangePaymentStatus = (d) => {

    setSelectedAccount(d);
    setShowEnterQuantity(true)
    // function showConfirm() {
    //   confirm({
    //     title: "Do you mark this as paid?",
    //     icon: <ExclamationCircleOutlined />,
    //     content: "From ",
    //     onOk() {
    //       postMarkAsPaid();
    //     },
    //     onCancel() {
    //       console.log("Cancel");
    //     },
    //   });
    // }

    // const postMarkAsPaid = async () => {
    //   let results = await api.markARenewalAsPaid(
    //     renewalAccountId,
    //     accountDealId
    //   );
    //   let { statusCode } = results;
    //   if (statusCode === 200) {
    //     setMessage("Status changed to Paid");
    //     setPageSuccess(true);
    //     setTimeout(() => {
    //       setRefreshPage(Math.random() * 100);
    //     }, 2000);
    //   }
    // };
    // showConfirm();
  };

  const columns = useMemo(() =>[
      {
        id: "date",
        Header: "Expiring Date",
        accessor: (d) => formatDate(d.next_renewal_date),
        Cell: (d) => <span>{d.value}</span>,
      },
      {
        id: "company",
        Header: "Company/Client",
        accessor: (d) => (d.account_name),
        Cell: (d) => <span>{d.value}</span>,
      },
      {
        Header: "Product",
        accessor: "product_name",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        id: "renewal_fee",
        Header: "Renewal Fee",
        accessor:d => d,
        Cell: (d) => <NumberFormat value={d.value.unit_price * d.value.quantity} />,
      },
      // {
      //   id: "status",
      //   Header: "Status",
      //   accessor: (d) => d,
      //   Cell: (d) => (
      //     <>
      //       {d.value.payment_status.toLowerCase() === "paid" ? (
      //         <Text style={{ color: "#057017fa" }}>
      //           <strong>Paid</strong>
      //         </Text>
      //       ) : (
      //         <>
      //           <Text type="danger">
      //             {" "}
      //             <strong>Unpaid</strong>
      //           </Text>
      //           &nbsp;
      //           <Button
      //             type="primary"
      //             size="small"
      //             onClick={() =>
      //               handleChangePaymentStatus(
      //                 // d.value.account_deal_id,
      //                 // d.value.renewal_account_id
      //                 d.value
      //               )
      //             }
      //           >
      //             change
      //           </Button>
      //         </>
      //       )}
      //     </>
      //   ),
      // },
      {
        id: "status",
        Header: "Renew",
        accessor: (d) => d,
        Cell: d => <Button type="primary" size="small"
        onClick={() =>
                        handleChangePaymentStatus(
                          d.value
                        )}>
          Renew
        </Button>
      },
      {
        id: "action",
        Header: "Action",
        accessor: (d) => d,
        Cell: (d) => (
          <>
            <Dropdown
              overlay={menu(
                d.value.account_deal_id,
                d.value.renewal_account_id
              )}
            >
              <Button>
                Action <DownOutlined />
              </Button>
            </Dropdown>
          </>
        ),
      },
    ],
    renewals
  );

  return (
    <Drawer>
      {/*=========== ADD ACTIONS MODAL ========*/}
      <CustomModal
        visible={showAddAction}
        title="Add Action"
        onCancel={setShowAddAction}
        formId="addAction"
      >
        <AddAction
          accountDealId={selectedDetails.accountDealId}
          renewalAccountId={selectedDetails.renewalAccountId}
          closeModal={setShowAddAction}
        />
      </CustomModal>

      {/*=========== ADD NOT RENEWING MODAL ========*/}
      <CustomModal
        visible={showNotRenewing}
        title="Add Reason Delayed Renewal"
        onCancel={setShowNotRenewing}
        formId="notRenewing"
      >
        <AddActionForNotRenewing
          accountDealId={selectedDetails.accountDealId}
          renewalAccountId={selectedDetails.renewalAccountId}
          closeModal={setShowNotRenewing}
          refreshPage={setRefreshPage}
        />
      </CustomModal>

                   {/* =========== View Reason for Not Renewing  ============*/}
 <Modal 
 width= {620}
 visible={showReason}
 title={`Reason for not Renewing`}
//  onOk={setNextStageModal}
 onCancel={() => setShowReason(false)}
 footer={[
 
]}
 >
<ViewReason 
accountDealId={selectedDetails.accountDealId}
renewalAccountId={selectedDetails.renewalAccountId} 
refreshPage={refreshPage}
/>
</Modal>

<Modal 
 visible={showEnterQuantity}
 title={`Renew subscription for ${selectedAccount.company_name ? selectedAccount.company_name : selectedAccount.contact_name}`}
 onCancel={() => setShowEnterQuantity(false)}
 footer={[]}
 >
   <RenewItems accountDetail={selectedAccount} closeModal={setShowEnterQuantity}/>
   </Modal>
      <PageTitle title="Renewal Accounts (UnPaid)" breadcrumbs={breadcrumbs} />

      <ReactTable columns={columns} filterable={true} 
      data={renewals}
      // data={[]}
       />
    </Drawer>
  );
}

const ViewReason = ({renewalAccountId, refreshPage}) => {
    
    let {comments} = useNotRenewingComment({id: renewalAccountId, refreshPage});
  

  return (
    <>
    <Row gutter={24}>
        {comments.map(reason => (
            <>
            <Col span={24}>
    
    <Text>
      <p>
        <strong>Description: </strong>
        {reason.comment}{" "}
      </p>
    </Text>
  </Col>

  <Col span={24}>
    <Text>
      <strong style={{float: "right"}}>{formatDate(reason.created_at)}</strong>
    </Text>
  </Col>
  <Divider />
  </>
        ))}
      
      </Row>
    </>
  );
};


const RenewItems = ({accountDetail, closeModal}) => {
  const [form] = Form.useForm();
  let api = useApi();

  let {devices, deviceIds} = useDevicesForDeal({accountDealId: accountDetail.account_deal_id, refreshPage: accountDetail.account_deal_id});

  let {pricingPlans} = usePricingPlans();

  let {
    setPageLoading,
    setPageError,
    setPageSuccess,
    setMessage,
  } = usePageValue();

  const [quantityToRenew, setQuantityToRenew] = useState(0);
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const [checkAll, setCheckAll] = React.useState(false);
  const [checkedList, setCheckedList] = React.useState([]);

  const handleChangeQuantity = (input) => {
    // console.log(input)
    setQuantityToRenew(input);
  }

  const onFinish = formInputs => {
    console.log(formInputs);
    
    if(checkAll){
      console.log('all is checked');
      formInputs.devicesCheckbox = deviceIds;
    }
      let formData = {
        account_deal_id: accountDetail.account_deal_id,
        renewal_account_id: accountDetail.renewal_account_id,
        quantity: quantityToRenew,
        pricing_plan: formInputs.pricingPlan,
        devices : formInputs.devicesCheckbox
      }

      console.log(formData);
      confirm({
            title: `Do you want to mark this as paid?`,
            icon: <ExclamationCircleOutlined />,
            content: `The quantity is ${quantityToRenew} and the total cost is ₦${quantityToRenew * accountDetail.unit_price} `,
            onOk() {
              postForm();
            },
            onCancel() {
              console.log("Cancel");
            },
          });
        

      const postForm = async() => {
        setIsLoading(true);
        let results = await api.markARenewalAsPaid(formData);
        let {statusCode, data} =  results;
        setIsLoading(false);
        if(statusCode === 200){
          setMessage('Subscription Renewed')
          setPageSuccess(true);
          closeModal(false);
        }
      }

      // postForm();
  }

  const onChange = value => {
      setDisable(value.target.checked)
      setQuantityToRenew(accountDetail.quantity)
  }

  const onCheckboxChange = event => {

    if(event.target.checked){
      setQuantityToRenew(quantityToRenew + 1);
    } else {
      setQuantityToRenew(quantityToRenew - 1);
    }
    // setCheckedList(list);
    // setIndeterminate(!!list.length && list.length < plainOptions.length);
    // setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = e => {
    console.log(e.target.checked);
    setCheckedList(e.target.checked ? deviceIds : []);
    setCheckAll(e.target.checked);
    setQuantityToRenew(devices.length);
  };

    return (
      <div>
        <Form 
         form={form}
         id="renewItem"
         layout="vertical"
       name="basic"
       initialValues={{
        remember: true,
      }}
      onFinish={onFinish}>

<FormGroup name="checkAll">
<Checkbox  onChange={onCheckAllChange} >
Renew all assets
      </Checkbox>
      </FormGroup>
      <Divider />

{
  !checkAll && (
              <FullFormGroup
                label={false}
                name={`devicesCheckbox`}
                required={true}
                >
                <Checkbox.Group style={{ width: '100%' }} >
                    {devices.map ((device, index) => (
                        <Row style={{backgroundColor: (index % 2 == 0)? '#e1e1e1': 'transparent', paddingTop: "5px", paddingBottom: "5px", paddingLeft: "10px"}}
                        >
                            <Col span="22"> {device.name}</Col>
                    
                            {/* {console.log(existingWorkFlow.actions.find((ele) => ele.id == work.id)? true : false)} */}
                            <Col span="2">
                            <Checkbox value={device.id} 
                            onChange={(e) => onCheckboxChange(e)}
                        
                            />
                            </Col>
                        </Row>
                        
                    ))}
                    </Checkbox.Group>
                    </FullFormGroup>

     
  )
}
      {/* <FormGroup>
        <Checkbox onChange={onChange}>Renew all asset</Checkbox>
      </FormGroup> */}
    
        <FormGroup 
        name={'pricingPlan'}
        label={'Pricing Plan'}
        required={true}
        >
         <Select style={{width: "300px"}}>
           {pricingPlans.map(pricing => (
             <Option value={pricing.id}>{pricing.name}</Option>
           ))}
         </Select>
        </FormGroup>

        <p>Amount to pay <strong><NumberFormat value={quantityToRenew * Number(accountDetail.unit_price)} /></strong>
        </p>

        <Form.Item >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
        </Form>
      </div>
    )
}