import React, { useState } from "react";
import "antd/dist/antd.css";
import "./drawer.css";
import { Layout, Menu, Typography, message, Avatar, Alert } from "antd";
import { NavLink } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined
} from "@ant-design/icons";

import { usePageValue } from "../../../../context";
import {getRole, getUserName, setToken, getTeam, getTeamName, getTeamLead, 
clearLocalStorage} from '../../../../helpers';
import Logo from './concept-nova-logo.png';

const { Header, Sider, Content } = Layout;

const { SubMenu } = Menu;
const {Title, Text} = Typography;

function Drawer(props) {
  const [collapse, setCollapse] = useState(false);
  let roleId = getRole();
  let {
    isPageLoading,
    isPageError,
    isPageSuccess,
    messageContent,
    setMessage,
    setPageSuccess,
    setPageError
  } = usePageValue();

  // setMessage('Loading');
  setPageSuccess(false);
  setPageError(false);
  const toggle = () => {
    setCollapse(!collapse);
  };

  const key = "updatable";

  const logoutAction = () => {
    clearLocalStorage();
    let publicUrl = process.env.PUBLIC_URL;
              let loginPageUrl = publicUrl + '/login';
              let origin = window.location.origin;
              if (window.location.origin === origin + loginPageUrl) return;
              window.location.href = loginPageUrl;
  }

  const menuItems = {
    1: ( <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
        <Menu.Item key="2" 
    // icon={<UnorderedListOutlined />}
    >
      <NavLink to="/salesdash">Dashboard</NavLink>
    </Menu.Item>

    <SubMenu
              key="sub1"
              //  icon={<MailOutlined />}
              title="Lead Management">
              <Menu.Item key="5">
                <NavLink to="/lead/create">Create Lead</NavLink>
              </Menu.Item>
              <Menu.Item key="6">
                <NavLink to="/leads">View Lead</NavLink>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="7" >
              <NavLink to="/sales">Sales Management</NavLink>
            </Menu.Item>

    </Menu>

    ),
    2: (
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
      <Menu.Item key="2" 
  // icon={<UnorderedListOutlined />}
  >
    <NavLink to="/salesdash">Dashboard</NavLink>
  </Menu.Item>

  <SubMenu
            key="sub1"
            //  icon={<MailOutlined />}
            title="Lead Management">
            <Menu.Item key="5">
              <NavLink to="/lead/create">Create Lead</NavLink>
            </Menu.Item>
            <Menu.Item key="6">
              <NavLink to="/leads">View Lead</NavLink>
            </Menu.Item>
          </SubMenu>

          <Menu.Item key="7" >
            <NavLink to="/sales">Sales Management</NavLink>
          </Menu.Item>

          <Menu.Item key="17">
                <NavLink to="/companies">Companies</NavLink>
              </Menu.Item>


  </Menu>
    ),
    3: (
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
      <Menu.Item key="2" 
  // icon={<UnorderedListOutlined />}
  >
    <NavLink to="/salesdash">Dashboard</NavLink>
  </Menu.Item>

  <SubMenu
            key="sub1"
            //  icon={<MailOutlined />}
            title="Lead Management">
            <Menu.Item key="5">
              <NavLink to="/lead/create">Create Lead</NavLink>
            </Menu.Item>
            <Menu.Item key="6">
              <NavLink to="/leads">View Lead</NavLink>
            </Menu.Item>
          </SubMenu>

          <Menu.Item key="7" >
            <NavLink to="/sales">Sales Management</NavLink>
          </Menu.Item>

          <Menu.Item key="8" >
            <NavLink to={`/expiredTeams/${getTeam()}/${getTeamName()}`}>Team</NavLink>
          </Menu.Item>

          { (getTeamLead() == 1)?(
           <SubMenu
           key="request"
           //  icon={<MailOutlined />}
           title="Request"
         >
           <Menu.Item key="81">
             <NavLink to="/expiredLeads">Expired Leads</NavLink>
           </Menu.Item>
           <Menu.Item key="8">
             <NavLink to="/request/extension">Extension</NavLink>
           </Menu.Item>
           <Menu.Item key="9">
               <NavLink to="/request/reassignment">Reassignment</NavLink>
             </Menu.Item>
           <Menu.Item key="10">
             <NavLink to="/request/requalification">Requalification</NavLink>
           </Menu.Item>

           <Menu.Item key="11">
             <NavLink to="/request/advancement">Lead Advancement</NavLink>
           </Menu.Item>

           <Menu.Item key="113">
             <NavLink to="/request/product">Product Change</NavLink>
           </Menu.Item>

           <Menu.Item key="114">
             <NavLink to="/request/inactive-lead">Inactive Leads</NavLink>
           </Menu.Item>

           <Menu.Item key="115">
             <NavLink to="/request/telerequests">Inactive Advancement</NavLink>
           </Menu.Item>

           <Menu.Item key="115">
             <NavLink to="/request/inactive-lead-reassignment">Inactive Reassignment</NavLink>
           </Menu.Item>

         </SubMenu>

        ): ("")} 
         <Menu.Item key="17">
                <NavLink to="/companies">Companies</NavLink>
              </Menu.Item>

  </Menu>
    ),
    4: (
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
      <Menu.Item key="2" 
  // icon={<UnorderedListOutlined />}
  >
    <NavLink to="/salesdash">Dashboard</NavLink>
  </Menu.Item>

  <SubMenu
            key="sub1"
            //  icon={<MailOutlined />}
            title="Lead Management">
            <Menu.Item key="5">
              <NavLink to="/lead/create">Create Lead</NavLink>
            </Menu.Item>
            <Menu.Item key="6">
              <NavLink to="/leads">View Lead</NavLink>
            </Menu.Item>
          </SubMenu>

          <Menu.Item key="7" >
            <NavLink to="/sales">Sales Management</NavLink>
          </Menu.Item>

          <Menu.Item key="17">
                <NavLink to="/companies">Companies</NavLink>
              </Menu.Item>

  </Menu>
    ),
    5: (
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
      <Menu.Item key="2" 
  // icon={<UnorderedListOutlined />}
  >
    <NavLink to="/salesdash">Dashboard</NavLink>
  </Menu.Item>

  <SubMenu
            key="sub1"
            //  icon={<MailOutlined />}
            title="Lead Management">
            <Menu.Item key="5">
              <NavLink to="/lead/create">Create Lead</NavLink>
            </Menu.Item>
            <Menu.Item key="6">
              <NavLink to="/leads">View Lead</NavLink>
            </Menu.Item>
          </SubMenu>

          <Menu.Item key="7" >
            <NavLink to="/sales">Sales Management</NavLink>
          </Menu.Item>

        
          <SubMenu
              key="telesales"
              //  icon={<MailOutlined />}
              title="Telesales"
            >
              <Menu.Item key="810">
                <NavLink to="/telesales">Dead Leads</NavLink>
              </Menu.Item>

              <Menu.Item key="811">
                <NavLink to="/telesales/inactive-leads">Inactive Leads</NavLink>
              </Menu.Item>

              <Menu.Item key="811">
                <NavLink to="/telesales/report">Reports</NavLink>
              </Menu.Item>

              <Menu.Item key="812">
                <NavLink to="/telesales/oldReport">Reports (Not worked)</NavLink>
              </Menu.Item>
             
            </SubMenu>
            <Menu.Item key="17">
                <NavLink to="/companies">Companies</NavLink>
              </Menu.Item>

  </Menu>
    ),
    6: (
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
    <Menu.Item key="2" 
    // icon={<UnorderedListOutlined />}
    >
      <NavLink to="/admin/dashboard">Dashboard</NavLink>
    </Menu.Item>
    <SubMenu
              key="sub1"
              //  icon={<MailOutlined />}
              title="Lead Management">
              <Menu.Item key="5">
                <NavLink to="/lead/create">Create Lead</NavLink>
              </Menu.Item>
              <Menu.Item key="6">
                <NavLink to="/leads">View Lead</NavLink>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="7" >
              <NavLink to="/sales">Sales Management</NavLink>
            </Menu.Item>

            <SubMenu
              key="request"
              //  icon={<MailOutlined />}
              title="Request"
            >
              <Menu.Item key="8">
                <NavLink to="/request/extension">Extension</NavLink>
              </Menu.Item>
              <Menu.Item key="9">
                <NavLink to="/request/requalification">Requalification</NavLink>
              </Menu.Item>

              <Menu.Item key="9">
                <NavLink to="/request/advancement">Lead Advancement</NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="settings"
              //  icon={<MailOutlined />}
              title="Settings"
            >
              <Menu.Item key="10">
                <NavLink to="/tat/create">TAT Mgt</NavLink>
              </Menu.Item>
              
              <Menu.Item key="11">
                <NavLink to="/users">User Mgt</NavLink>
              </Menu.Item>

              <Menu.Item key="12">
                <NavLink to="/roles">Role Mgt</NavLink>
              </Menu.Item>

              <Menu.Item key="17">
                <NavLink to="/companies">Companies</NavLink>
              </Menu.Item>

            </SubMenu>
    </Menu>
    ),
    7: (<Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
    <Menu.Item key="2" 
    // icon={<UnorderedListOutlined />}
    >
      <NavLink to="/admin/dashboard">Dashboard</NavLink>
    </Menu.Item>
    <SubMenu
              key="sub1"
              //  icon={<MailOutlined />}
              title="Lead Management">
              <Menu.Item key="5">
                <NavLink to="/lead/create">Create Lead</NavLink>
              </Menu.Item>
              <Menu.Item key="6">
                <NavLink to="/leads">View Lead</NavLink>
              </Menu.Item>
            </SubMenu>

            <Menu.Item key="7" >
              <NavLink to="/sales">Sales Management</NavLink>
            </Menu.Item>

            {/* <Menu.Item key="70" >
              <NavLink to="/telesales">Telesales</NavLink>
            </Menu.Item> */}

            <SubMenu
              key="telesales"
              //  icon={<MailOutlined />}
              title="Telesales"
            >
              <Menu.Item key="810">
                <NavLink to="/telesales">Dead Leads</NavLink>
              </Menu.Item>

              <Menu.Item key="811">
                <NavLink to="/telesales/inactive-leads">Inactive Leads</NavLink>
              </Menu.Item>
              <Menu.Item key="812">
                <NavLink to="/telesales/report">Reports</NavLink>
              </Menu.Item>

              <Menu.Item key="813">
                <NavLink to="/telesales/oldReport">Reports (Not worked)</NavLink>
              </Menu.Item>

              <Menu.Item key="814">
                <NavLink to="/telesales/expired-control-accounts">Control Accounts</NavLink>
              </Menu.Item>
             
            </SubMenu>

            <SubMenu
              key="team"
              //  icon={<MailOutlined />}
              title="Team"
            >
              <Menu.Item key="81">
                <NavLink to="/expiredTeams">Expired Leads</NavLink>
              </Menu.Item>

              <Menu.Item key="83">
                <NavLink to="/expiredTeams/requests">Expired Leads Request</NavLink>
              </Menu.Item>
             
            </SubMenu>

            <SubMenu
              key="request"
              //  icon={<MailOutlined />}
              title="Request"
            >
              <Menu.Item key="81">
                <NavLink to="/expiredLeads">Expired Leads</NavLink>
              </Menu.Item>
              <Menu.Item key="8">
                <NavLink to="/request/extension">Extension</NavLink>
              </Menu.Item>
              <Menu.Item key="9">
                  <NavLink to="/request/reassignment">Reassignment</NavLink>
                </Menu.Item>
              <Menu.Item key="10">
                <NavLink to="/request/requalification">Requalification</NavLink>
              </Menu.Item>

              <Menu.Item key="11">
                <NavLink to="/request/advancement">Lead Advancement</NavLink>
              </Menu.Item>

              <Menu.Item key="113">
                <NavLink to="/request/product">Product Change</NavLink>
              </Menu.Item>

              <Menu.Item key="114">
                <NavLink to="/request/inactive-lead">Inactive Leads</NavLink>
              </Menu.Item>

              <Menu.Item key="115">
                <NavLink to="/request/telerequests">Inactive Advancement</NavLink>
              </Menu.Item>

              <Menu.Item key="115">
                <NavLink to="/request/inactive-lead-reassignment">Inactive Reassignment</NavLink>
              </Menu.Item>

            </SubMenu>

            <SubMenu
              key="renewal"
              //  icon={<MailOutlined />}
              title="Renewal"
            >
                <Menu.Item key="13">
                <NavLink to="/pending-renewal-deals">Pending Deals</NavLink>
              </Menu.Item>
              <Menu.Item key="100">
                <NavLink to="/renewalAccounts">Renewals</NavLink>
              </Menu.Item>
              <Menu.Item key="10">
                <NavLink to="/accounts">Accounts</NavLink>
              </Menu.Item>
              <Menu.Item key="11">
                <NavLink to="/reminders">Reminder</NavLink>
              </Menu.Item>
              <Menu.Item key="12">
                <NavLink to="/renewals/fees">Renewal Fees</NavLink>
              </Menu.Item>

            </SubMenu>

            <SubMenu
              key="control"
              //  icon={<MailOutlined />}
              title="Control"
            >
            <Menu.Item key="113">
                <NavLink to="/control/create-dictionary">Create Directory</NavLink>
              </Menu.Item>

              <Menu.Item key="114">
                <NavLink to="/control/view-dictionary">View Directory</NavLink>
              </Menu.Item>

              <Menu.Item key="115">
                <NavLink to="/control/upload">Upload Data</NavLink>
              </Menu.Item>

</SubMenu>
<SubMenu
              key="reports"
              //  icon={<MailOutlined />}
              title="Report"
            >
              <Menu.Item key="12">
            <NavLink to="/extensionReports">Team Extension</NavLink>
          </Menu.Item>

          <Menu.Item key="120">
            <NavLink to="/allExtensionReport">Individual Extension</NavLink>
          </Menu.Item>

          <Menu.Item key="120">
            <NavLink to="/reports">Individual Reports</NavLink>
          </Menu.Item>

          <Menu.Item key="134">
                <NavLink to="/churnRate/all">All Churn Rate</NavLink>
              </Menu.Item>

          <Menu.Item key="130">
                <NavLink to="/churnRate/b2b">B2B Churn Rate</NavLink>
              </Menu.Item>
              <Menu.Item key="131">
                <NavLink to="/churnRate/b2c">B2C Churn Rate</NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="graphicalReports"
              //  icon={<MailOutlined />}
              title="Report Graphs"
            >
              <Menu.Item key="12">
            <NavLink to="/graph/team">Inactive Lead Report</NavLink>
          </Menu.Item>

          <Menu.Item key="121">
            <NavLink to="/graph/performance">Team Performance</NavLink>
          </Menu.Item>

          <Menu.Item key="122">
            <NavLink to="/graph/engagement">Meaningful Engagments</NavLink>
          </Menu.Item>

          </SubMenu>

            <SubMenu
              key="settings"
              //  icon={<MailOutlined />}
              title="Settings"
            >
               <Menu.Item key="935">
            <NavLink to="/products">Product Mgt</NavLink>
          </Menu.Item>
              <Menu.Item key="135">
            <NavLink to="/departments">Dept Mgt</NavLink>
          </Menu.Item>
              <Menu.Item key="13">
            <NavLink to="/teams">Team Mgt</NavLink>
          </Menu.Item>

          <Menu.Item key="19">
                <NavLink to="/teams/create-budget">Annual Target</NavLink>
              </Menu.Item>

          <Menu.Item key="14">
                <NavLink to="/emails/view">Emails</NavLink>
              </Menu.Item>

              <SubMenu
              key="tat"
              //  icon={<MailOutlined />}
              title="TAT Mgt"
            >
              <Menu.Item key="14">
                <NavLink to="/tat/create">Stage TAT</NavLink>
              </Menu.Item>

              <Menu.Item key="140">
                <NavLink to="/requalificationTat/create">Requalification </NavLink>
              </Menu.Item>
              </SubMenu>
              
              <Menu.Item key="15">
                <NavLink to="/users">User Mgt</NavLink>
              </Menu.Item>

              <Menu.Item key="16">
                <NavLink to="/roles">Role Mgt</NavLink>
              </Menu.Item>

              <Menu.Item key="16">
                <NavLink to="/industries">Industry Mgt</NavLink>
              </Menu.Item>

              <Menu.Item key="17">
                <NavLink to="/variables">Variables Mgt</NavLink>
              </Menu.Item>

              <Menu.Item key="18">
                <NavLink to="/companies">Companies</NavLink>
              </Menu.Item>

              <Menu.Item key="19">
                <NavLink to="/Contacts">Contacts</NavLink>
              </Menu.Item>

              <Menu.Item key="20">
                <NavLink to="/medium">Medium Mgt</NavLink>
              </Menu.Item>

              <Menu.Item key="21">
                <NavLink to="/channels">Channel Mgt</NavLink>
              </Menu.Item>

            </SubMenu>
    </Menu>),
    9: (<Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
      <Menu.Item key="2" 
      // icon={<UnorderedListOutlined />}
      >
        <NavLink to="/admin/dashboard">Dashboard</NavLink>
      </Menu.Item>
      <SubMenu
                key="sub1"
                //  icon={<MailOutlined />}
                title="Lead Management">
                <Menu.Item key="5">
                  <NavLink to="/lead/create">Create Lead</NavLink>
                </Menu.Item>
                <Menu.Item key="6">
                  <NavLink to="/leads">View Lead</NavLink>
                </Menu.Item>
              </SubMenu>
  
              <Menu.Item key="7" >
                <NavLink to="/sales">Sales Management</NavLink>
              </Menu.Item>
  
              <SubMenu
                key="request"
                //  icon={<MailOutlined />}
                title="Request"
              >
                <Menu.Item key="8">
                  <NavLink to="/request/extension">Extension</NavLink>
                </Menu.Item>
                <Menu.Item key="9">
                  <NavLink to="/request/requalification">Requalification</NavLink>
                </Menu.Item>
  
                <Menu.Item key="9">
                  <NavLink to="/request/advancement">Lead Advancement</NavLink>
                </Menu.Item>
              </SubMenu>
  
              <SubMenu
                key="settings"
                //  icon={<MailOutlined />}
                title="Settings"
              >
                <Menu.Item key="10">
                  <NavLink to="/tat/create">TAT Mgt</NavLink>
                </Menu.Item>
                
                <Menu.Item key="11">
                  <NavLink to="/users">User Mgt</NavLink>
                </Menu.Item>
  
                <Menu.Item key="12">
                  <NavLink to="/roles">Role Mgt</NavLink>
                </Menu.Item>
  
                <Menu.Item key="17">
                <NavLink to="/companies">Companies</NavLink>
              </Menu.Item>

              </SubMenu>
      </Menu>),
      11: (<Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
          <Menu.Item key="2" 
          // icon={<UnorderedListOutlined />}
          >
            <NavLink to="/salesdash">Dashboard</NavLink>
          </Menu.Item>
          <SubMenu
                    key="sub1"
                    //  icon={<MailOutlined />}
                    title="Lead Management">
                    <Menu.Item key="5">
                      <NavLink to="/lead/create">Create Lead</NavLink>
                    </Menu.Item>
                    <Menu.Item key="6">
                      <NavLink to="/leads">View Lead</NavLink>
                    </Menu.Item>
                  </SubMenu>
      
                  <Menu.Item key="7" >
                    <NavLink to="/sales">Sales Management</NavLink>
                  </Menu.Item>
      
                  <SubMenu
              key="team"
              //  icon={<MailOutlined />}
              title="Team"
            >
              <Menu.Item key="81">
              <NavLink to={`/expiredTeams/${getTeam()}/${getTeamName()}`}>Expired Leads</NavLink>
              </Menu.Item>

              <Menu.Item key="83">
                <NavLink to="/expiredTeams/requests">Expired Leads Request</NavLink>
              </Menu.Item>
             
            </SubMenu>

            <SubMenu
              key="request"
              //  icon={<MailOutlined />}
              title="Request"
            >
              <Menu.Item key="81">
                <NavLink to="/expiredLeads">Expired Leads</NavLink>
              </Menu.Item>
              <Menu.Item key="8">
                <NavLink to="/request/extension">Extension</NavLink>
              </Menu.Item>
              <Menu.Item key="9">
                  <NavLink to="/request/reassignment">Reassignment</NavLink>
                </Menu.Item>
              <Menu.Item key="10">
                <NavLink to="/request/requalification">Requalification</NavLink>
              </Menu.Item>

              <Menu.Item key="11">
                <NavLink to="/request/advancement">Lead Advancement</NavLink>
              </Menu.Item>

              <Menu.Item key="113">
                <NavLink to="/request/product">Product Change</NavLink>
              </Menu.Item>

              <Menu.Item key="114">
                <NavLink to="/request/inactive-lead">Inactive Leads</NavLink>
              </Menu.Item>

              <Menu.Item key="115">
                <NavLink to="/request/telerequests">Inactive Advancement</NavLink>
              </Menu.Item>

              <Menu.Item key="115">
                <NavLink to="/request/inactive-lead-reassignment">Inactive Reassignment</NavLink>
              </Menu.Item>

            </SubMenu>
            </Menu>
          ),
          12: (<Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
            <Menu.Item key="2" 
            // icon={<UnorderedListOutlined />}
            >
              <NavLink to="/salesdash">Dashboard</NavLink>
            </Menu.Item>
            <SubMenu
                      key="sub1"
                      //  icon={<MailOutlined />}
                      title="Lead Management">
                      <Menu.Item key="5">
                        <NavLink to="/lead/create">Create Lead</NavLink>
                      </Menu.Item>
                      <Menu.Item key="6">
                        <NavLink to="/leads">View Lead</NavLink>
                      </Menu.Item>
                    </SubMenu>
        
                    <Menu.Item key="7" >
                      <NavLink to="/sales">Sales Management</NavLink>
                    </Menu.Item>
        
                    <SubMenu
              key="request"
              //  icon={<MailOutlined />}
              title="Request"
            >
              <Menu.Item key="81">
                <NavLink to="/expiredLeads">Expired Leads</NavLink>
              </Menu.Item>
              <Menu.Item key="8">
                <NavLink to="/request/extension">Extension</NavLink>
              </Menu.Item>
              <Menu.Item key="9">
                  <NavLink to="/request/reassignment">Reassignment</NavLink>
                </Menu.Item>
              <Menu.Item key="10">
                <NavLink to="/request/requalification">Requalification</NavLink>
              </Menu.Item>

              <Menu.Item key="11">
                <NavLink to="/request/advancement">Lead Advancement</NavLink>
              </Menu.Item>

              <Menu.Item key="113">
                <NavLink to="/request/product">Product Change</NavLink>
              </Menu.Item>

              <Menu.Item key="114">
                <NavLink to="/request/inactive-lead">Inactive Leads</NavLink>
              </Menu.Item>

              <Menu.Item key="115">
                <NavLink to="/request/telerequests">Inactive Advancement</NavLink>
              </Menu.Item>

              <Menu.Item key="115">
                <NavLink to="/request/inactive-lead-reassignment">Inactive Reassignment</NavLink>
              </Menu.Item>

            </SubMenu>

                              <Menu.Item key="17">
                <NavLink to="/companies">Companies</NavLink>
              </Menu.Item>

            </Menu>),

13: (
  <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
  <Menu.Item key="2" 
// icon={<UnorderedListOutlined />}
>
<NavLink to="/salesdash">Dashboard</NavLink>
</Menu.Item>

<SubMenu
        key="sub1"
        //  icon={<MailOutlined />}
        title="Lead Management">
        <Menu.Item key="5">
          <NavLink to="/lead/create">Create Lead</NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/leads">View Lead</NavLink>
        </Menu.Item>
      </SubMenu>

      <Menu.Item key="7" >
        <NavLink to="/sales">Sales Management</NavLink>
      </Menu.Item>

      <Menu.Item key="8" >
        <NavLink to={`/expiredTeams/${getTeam()}/${getTeamName()}`}>Team</NavLink>
      </Menu.Item>

     { (getTeamLead() == 1)?(
       <SubMenu
       key="request"
       //  icon={<MailOutlined />}
       title="Request"
     >
       <Menu.Item key="81">
         <NavLink to="/expiredLeads">Expired Leads</NavLink>
       </Menu.Item>
       <Menu.Item key="8">
         <NavLink to="/request/extension">Extension</NavLink>
       </Menu.Item>
       <Menu.Item key="9">
           <NavLink to="/request/reassignment">Reassignment</NavLink>
         </Menu.Item>
       <Menu.Item key="10">
         <NavLink to="/request/requalification">Requalification</NavLink>
       </Menu.Item>

       <Menu.Item key="11">
         <NavLink to="/request/advancement">Lead Advancement</NavLink>
       </Menu.Item>

       <Menu.Item key="113">
         <NavLink to="/request/product">Product Change</NavLink>
       </Menu.Item>

       <Menu.Item key="114">
         <NavLink to="/request/inactive-lead">Inactive Leads</NavLink>
       </Menu.Item>

       <Menu.Item key="115">
         <NavLink to="/request/telerequests">Inactive Advancement</NavLink>
       </Menu.Item>

       <Menu.Item key="115">
         <NavLink to="/request/inactive-lead-reassignment">Inactive Reassignment</NavLink>
       </Menu.Item>

     </SubMenu>

    ): ("")} 
     <Menu.Item key="17">
            <NavLink to="/companies">Companies</NavLink>
          </Menu.Item>

</Menu>
),

14: (<Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
<Menu.Item key="2" 
// icon={<UnorderedListOutlined />}
>
  <NavLink to="/salesdash">Dashboard</NavLink>
</Menu.Item>
<SubMenu
          key="sub1"
          //  icon={<MailOutlined />}
          title="Lead Management">
          <Menu.Item key="5">
            <NavLink to="/lead/create">Create Lead</NavLink>
          </Menu.Item>
          <Menu.Item key="6">
            <NavLink to="/leads">View Lead</NavLink>
          </Menu.Item>
          <Menu.Item key="8">
            <NavLink to="/leads/expired">View Expired Leads</NavLink>
          </Menu.Item>
          <Menu.Item key="9">
            <NavLink to="/leads/inactive">View Inactive Leads</NavLink>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="7" >
          <NavLink to="/sales">Sales Management</NavLink>
        </Menu.Item>


        <SubMenu
              key="team"
              //  icon={<MailOutlined />}
              title="Team"
            >
              <Menu.Item key="81">
                <NavLink to="/expiredTeams">Expired Leads</NavLink>
              </Menu.Item>

              <Menu.Item key="83">
                <NavLink to="/expiredTeams/requests">Expired Leads Request</NavLink>
              </Menu.Item>
             
            </SubMenu>

        <SubMenu
  key="request"
  //  icon={<MailOutlined />}
  title="Request"
>
  <Menu.Item key="81">
    <NavLink to="/expiredLeads">Expired Leads</NavLink>
  </Menu.Item>
  <Menu.Item key="8">
    <NavLink to="/request/extension">Extension</NavLink>
  </Menu.Item>
  <Menu.Item key="9">
      <NavLink to="/request/reassignment">Reassignment</NavLink>
    </Menu.Item>
  <Menu.Item key="10">
    <NavLink to="/request/requalification">Requalification</NavLink>
  </Menu.Item>

  <Menu.Item key="11">
    <NavLink to="/request/advancement">Lead Advancement</NavLink>
  </Menu.Item>

  <Menu.Item key="113">
    <NavLink to="/request/product">Product Change</NavLink>
  </Menu.Item>

  <Menu.Item key="114">
    <NavLink to="/request/inactive-lead">Inactive Leads</NavLink>
  </Menu.Item>

  <Menu.Item key="115">
    <NavLink to="/request/telerequests">Inactive Advancement</NavLink>
  </Menu.Item>

  <Menu.Item key="115">
    <NavLink to="/request/inactive-lead-reassignment">Inactive Reassignment</NavLink>
  </Menu.Item>

</SubMenu>

<SubMenu
              key="reports"
              //  icon={<MailOutlined />}
              title="Report"
            >
              <Menu.Item key="12">
            <NavLink to="/extensionReports">Team Extension</NavLink>
          </Menu.Item>

          <Menu.Item key="120">
            <NavLink to="/allExtensionReport">Individual Extension</NavLink>
          </Menu.Item>

          <Menu.Item key="120">
            <NavLink to="/reports">Individual Reports</NavLink>
          </Menu.Item>

          <Menu.Item key="134">
                <NavLink to="/churnRate/all">All Churn Rate</NavLink>
              </Menu.Item>

          <Menu.Item key="130">
                <NavLink to="/churnRate/b2b">B2B Churn Rate</NavLink>
              </Menu.Item>
              <Menu.Item key="131">
                <NavLink to="/churnRate/b2c">B2C Churn Rate</NavLink>
              </Menu.Item>
            </SubMenu>

            <SubMenu
              key="graphicalReports"
              //  icon={<MailOutlined />}
              title="Report Graphs"
            >
              <Menu.Item key="12">
            <NavLink to="/graph/team">Inactive Lead Report</NavLink>
          </Menu.Item>

          <Menu.Item key="121">
            <NavLink to="/graph/performance">Team Performance</NavLink>
          </Menu.Item>

          <Menu.Item key="122">
            <NavLink to="/graph/engagement">Meaningful Engagments</NavLink>
          </Menu.Item>

          </SubMenu>

                  <Menu.Item key="17">
    <NavLink to="/companies">Companies</NavLink>
  </Menu.Item>

</Menu>),

15: (<Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
<Menu.Item key="2" 
// icon={<UnorderedListOutlined />}
>
  <NavLink to="/salesdash">Dashboard</NavLink>
</Menu.Item>
<SubMenu
          key="sub1"
          //  icon={<MailOutlined />}
          title="Lead Management">
          <Menu.Item key="5">
            <NavLink to="/lead/create">Create Lead</NavLink>
          </Menu.Item>
          <Menu.Item key="6">
            <NavLink to="/leads">View Lead</NavLink>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="7" >
          <NavLink to="/sales">Sales Management</NavLink>
        </Menu.Item>

        {/* <Menu.Item key="70" >
          <NavLink to="/telesales">Telesales</NavLink>
        </Menu.Item> */}

       <SubMenu
          key="renewal"
          //  icon={<MailOutlined />}
          title="Renewal"
        >
            <Menu.Item key="13">
            <NavLink to="/pending-renewal-deals">Pending Deals</NavLink>
          </Menu.Item>
          <Menu.Item key="100">
            <NavLink to="/renewalAccounts">Renewals</NavLink>
          </Menu.Item>
          <Menu.Item key="10">
            <NavLink to="/accounts">Accounts</NavLink>
          </Menu.Item>
          <Menu.Item key="11">
            <NavLink to="/reminders">Reminder</NavLink>
          </Menu.Item>
          <Menu.Item key="12">
            <NavLink to="/renewals/fees">Renewal Fees</NavLink>
          </Menu.Item>

        </SubMenu>

        <SubMenu
          key="control"
          //  icon={<MailOutlined />}
          title="Control"
        >
        <Menu.Item key="113">
            <NavLink to="/control/create-dictionary">Create Directory</NavLink>
          </Menu.Item>

          <Menu.Item key="114">
            <NavLink to="/control/view-dictionary">View Directory</NavLink>
          </Menu.Item>

          <Menu.Item key="115">
            <NavLink to="/control/upload">Upload Data</NavLink>
          </Menu.Item>

</SubMenu>
</Menu>),


  }
  
  return (
    <>
    
       <div style={{zIndex: "99", width: "200px", position: "fixed", marginLeft: '40%', marginTop: "20px"}}>
              {isPageLoading &&
         <Alert message="Loading" type="info" />
         
         }
         </div>
         {/* {isPageLoading && message.loading({content: 'Loading...', key: 'loading'})}; */}

      {isPageSuccess && message.success({ content: messageContent, key })}

      {isPageError && message.error({ content: messageContent, key: 'error', duration: 20})}

      {/* {isPageError && <Alert key="error" message={messageContent} type="error" />} */}
      <Layout style={{ height: "inherit" }}>
        <Sider trigger={null} collapsible collapsed={collapse}>
          <div className="logo" style={{padding: "5px", marginBottom: '50px'}} > <img src={Logo} style={{width: "40px", height: "40px"}}/></div>
         
          {menuItems[roleId]}
          
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            {React.createElement(
              collapse ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: toggle,
                style: {color: "blue"}
              }
            )}
            {/* <Title level={4}>Concept Nova ERP</Title> */}
            <Text style={{color: "blue"}}><strong>Concept Nova ERP</strong></Text>
            {/* Concept Nova ERP */}
            <div style={{float: "right", marginRight: "20px"}}>
            <Avatar size={30} style={{backgroundColor: "blue"}}> {getUserName()? getUserName().substring(0,1) : ''}</Avatar>
            {/* <LogoutOutlined /> */}
            &nbsp; &nbsp;
            <span style={{cursor: "pointer"}} title="Logout" onClick={()=> logoutAction()}>
            <Avatar size={30} style={{backgroundColor: "#f56a00"}} icon={<LogoutOutlined />}/>
            </span>
            </div>
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              // height: 'max-content',
              // minHeight: 'inherit'
            }}
          >
         
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

Drawer.propTypes = {};

export default Drawer;
