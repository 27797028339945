import React from 'react';
import {Form, Input, Radio, Typography} from 'antd';

import {FormGroup} from '../../utils';
import {useApi, usePageValue, useModalValue} from '../../../../context';
import {isStage} from '../../../../helpers'
import {InputWithMentions} from '../../atoms'

export default function AdvanceLeadCommentBC({stageId, substageId, leadId, ...props}) {

    let {setIsModalLoading} = useModalValue();

    let {setPageLoading, setPageError, setPageSuccess, setMessage} = usePageValue();

    const [form] = Form.useForm();

    let api = useApi();
    const {Title} = Typography;

    const quantifyState = null; 

    const stageAndSubstageIds = `${stageId}`;
    const {TextArea} = Input;

    const bantOptions = {
        3: (
            <Title level={4}>
            Qualify lead as
           </Title>
        )
    }

    const onFinish = values => {
       console.log('logging this');
       console.log(values);
        
       // This function moves the lead to the next stage
       const updateStage = async() => {

        let data = {
            lead_id: leadId,
            comment: values.comment,
            stage: Number(stageId) + 1,
            qualifying_state: values.bantSelected,
            assignee_id: props.assigneeId
        }
        setIsModalLoading(true);
        let results = await api.updateStage(data);
        let {statusCode} = results;
        setIsModalLoading(false);
        
        if(statusCode === 200) {   
            form.resetFields()
            setPageLoading(false);
            props.closeModal(false);
            props.refreshVariable(Math.random() * 100)
        }
       }

       setPageLoading(true);
        // Check if it is the end of a stage  
            updateStage();      
    }
    
    return (
        <div>
                   <Form
      // {...layout}
      form={form}
      id="advanceLeadForm"
      layout="vertical"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      size={'large'}
      // onFinishFailed={onFinishFailed}
    >
  
        {bantOptions[stageAndSubstageIds] && (
<>
{bantOptions[stageAndSubstageIds]}
<FormGroup 
    name={'bantSelected'}
    required={true}>

<Radio.Group
        //  onChange={this.onChange} value={this.state.value}
        size="large"
        name="bantSelected"
         >
        <Radio value={'Cold'}>Cold</Radio>
        <Radio value={'Warm'}>Warm</Radio>
        <Radio value={'Hot'}>Hot</Radio>
       
      </Radio.Group>

    </FormGroup>
      </>
        ) 
}

 <FormGroup 
    name={'comment'}
    label={'Enter Comments'}
    required= {true}>
        {/* <TextArea placeholder="Enter more details..." allowClear
        //  onChange={onChange} 
        style={{width: '450px', height: '100px'}}
        /> */}

        <InputWithMentions width={450}/>
            </FormGroup>
    </Form>
        </div>
    )
}
