import {useState, useEffect} from 'react';
import {useApi, usePageValue} from '../context';

export function useExtensionRequests(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [extensionRequests, setExtensionRequests] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

            if(props.teamId){
                results = await api.getExtensionRequest(props.teamId);
            } else{
                results = await api.getExtensionRequest();
            }
                
          setIsLoading(false)
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // setData(addLabelToArray(data));
                    
                    setExtensionRequests(combineStage(data));
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [extensionRequests, isLoading, hasError ]
        }
    }, [props.refreshPage])


    return {extensionRequests, isLoading, hasError}
}

const addStageLabel = (data, stageName) => {
    let dataWithStageName = data.map(dat => ({...dat, stageName}));
    return dataWithStageName
}


export const combineStage = (data) => {
    let followUp = addStageLabel(data.follow, 'Follow Up')
    let introduction = addStageLabel(data.introduction, 'Introduction');
    let qualification = addStageLabel(data.qualification, 'Qualification');
    let combinedArray = followUp.concat(introduction.concat(qualification));
    return combinedArray
}

export function useRequalificationRequest(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [requalificationRequests, setRequalificationRequests] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;
            
                results = await api.getRequalificationRequest(props.teamId);
          setIsLoading(false)
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // setData(addLabelToArray(data));
                    setRequalificationRequests(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [requalificationRequests, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {requalificationRequests, isLoading, hasError}
}


export function useAdvancementRequest(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [advancementRequests, setAdvancementRequest] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

            let finalTeamId = 'null';
            if(props.teamId != 1){
                finalTeamId = props.teamId;
            }
                results = await api.getAdvancementRequest(finalTeamId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // setData(addLabelToArray(data));
                    setAdvancementRequest(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [advancementRequests, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {advancementRequests, isLoading, hasError}
}

export function useReassignmentRequest(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [reassignmentRequest, setReassignmentRequest] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getReassignmentRequest(props.teamId);
          setIsLoading(false);
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // setData(addLabelToArray(data));
                    setReassignmentRequest(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [reassignmentRequest, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {reassignmentRequest, isLoading, hasError}
}

export function useProductChangeRequest(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [productRequest, setProductRequest] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getProductChangeRequest();
          setIsLoading(false);
            if(results.statusCode === 200) {
                let {data, message} = results;

                setProductRequest(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [productRequest, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {productRequest, isLoading, hasError}
}

export function useInactiveRequests(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [inactiveRequests, setInactiveRequests] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getInactiveRequests();
          setIsLoading(false);
            if(results.statusCode === 200) {
                let {data, message} = results;

                setInactiveRequests(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [inactiveRequests, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {inactiveRequests, isLoading, hasError}
}