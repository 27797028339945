import React, {createContext, useContext, useState} from 'react';
import {getToken} from '../helpers';
import {Redirect} from 'react-router-dom';

const LoginContext = createContext();

const LoginProvider = ({children}) => {

    const token = getToken();

    return(
        <>
        {token? 
        (<LoginContext.Provider value={token}>
            {children}
            </LoginContext. Provider>) :
             
            (<Redirect to="/login" />) }

            </>
    )
}

const useLogin = () => {
    const context = useContext(LoginContext);

    if(context === undefined) {
        throw new Error('useLogin must be used within an LoginProvider')
    }

    return context
}

export {LoginProvider, useLogin}