import React, {useEffect} from 'react';
import {useOneDepartment} from '../../../hooks';
import { Drawer, PageTitle } from "../../units/molecules";
import {Form, Input, Row, Button} from 'antd';
import {FormGroup} from '../../units/utils';
import {useApi, usePageValue} from '../../../context';
import {DEFAULT_INPUT_SIZE} from '../../../constants/constantValues'

const {TextArea} = Input;
export default function EditDept(props) {
    let {id} = props.match.params;
    let {department} = useOneDepartment({id});
    const [form] = Form.useForm();
  
    let initialValues = {}
    const breadcrumbs = [
        {'label': 'Dashboard'},
        {'label': 'Departments', path: "/departments"},
        {'label' : 'Edit Department'}
    ]

    let {setPageLoading, setPageError, setPageSuccess, setMessage} = usePageValue();
    let api = useApi();

    useEffect(() => {
      form.resetFields();
      return () => {}
    }, [department])

    

    const onFinish = values => {
        console.log('logging this');
        console.log(values);
         
        let data = {
        //   team_id: id,
          name: values.name,
          description: values.description,
          status: "active"
        }
  
        const postForm = async () => {
          setPageLoading(true)
          let results = await api.updateDept(id, data);
           
          let {statusCode } = results;
          setPageLoading(false);
          if(statusCode === 200){
            // setRefreshPage(Math.random * 1000);
            setMessage('Department updated updated successfully')
            setPageSuccess(true);
            // form.resetFields();
            props.history.push("/departments")
          }
        }
  
        postForm();
     }

    return (
        <Drawer>
            <PageTitle title="Edit Department" breadcrumbs={breadcrumbs} />

            <Row gutter={12}>

            <Form
      // {...layout}
      form={form}
      id="createTat"
      layout="vertical"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      size={'large'}
      // onFinishFailed={onFinishFailed}
      initialValues = {initialValues}
    >

<FormGroup 
            name={'name'}
            label={'Name'}
            required= {true}
            initialValue = {department.name}
            >
              
              <Input style={{width: DEFAULT_INPUT_SIZE}} />
          
            </FormGroup>

            <FormGroup 
            name={'description'}
            label={'Description'}
            required= {true}
            initialValue = {department.description}
            
            >
              
              <TextArea placeholder="" allowClear
             
             style={{width: '300px', height: '100px'}}
              />
          
            </FormGroup>

            <Form.Item >
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>

      </Form>
              </Row>
        </Drawer>
    )
}
