import React, {useState, useEffect} from 'react';
import {MentionsInput, Mention} from 'react-mentions';
import defaultMentionStyle from './defaultMentionStyle';
import defaultStyle from './defaultStyle';
import {useMentions} from './../../../../hooks';

export default function InputWithMention({refreshVariable,...props}) {


    let {contacts} = useMentions();
    const [inputValue, setinputValue] = useState('');
    useEffect(() => {
      
        return () => inputValue
    }, [props, refreshVariable])

    const handleInputChange = (val) =>{
        let value = val.target.value 
        // console.log(value);

        setinputValue(value);
        props.handleCommentChange(value);

      }

    return (
       <MentionsInput 
        // onChange={(val)=>handleInputChange(val)} 
        {...props}
        style={defaultStyle({width: props.width})} placeholder="Enter your comments">
       {/* <MentionsInput value={inputValue} onChange={(val)=>console.log(val.target.value)}> */}
           <Mention trigger="@" data={contacts}  style={defaultMentionStyle}
        //    onAdd={(id, display) => addTags(id)}
           />
       </MentionsInput>

    )
}
