import React, {useState, useEffect} from "react";
import {
  Drawer,
  PageTitle,
  QuantityAmount,
  AddLeadComment,
  LeadDetails,
} from "../../units/molecules";
import { useOneLead, useStageLogs, useAssignmentHistory } from "../../../hooks";
import { usePageValue } from "../../../context";
import {
  Row,
  Col,
  Typography,
  Skeleton,
  Card,
  Menu,
  Dropdown,
  Button,
  Avatar,
  Tabs,
  Collapse,
  Divider,
} from "antd";
import { DownOutlined } from '@ant-design/icons';
import { Link, NumberFormat } from "../../units/atoms";
import { ReactTable } from "../../units/atoms";
import AssignLead from './modal/AssignLead';

import {formatDate, getAvatarText} from '../../../helpers'

import {Modal} from '../../units/molecules/modals';
import ReassignmentRequest from '../sales/modal/ReassignmentRequest';
import ProductChange from './modal/ProductChange';
import { getNameForAvatar, setHtmlFormatting } from "../../../helpers/stageHelper";

const { Title, Text } = Typography;
const { TabPane } = Tabs;

export default function LeadDetail(props) {
  let leadId = props.match.params.leadId;

  const [refreshVariable, setRefreshVariable] = useState(0);

  let { lead, isLoading, hasError } = useOneLead({ id: leadId, refreshVariable });
  let {stageLogs, message} = useStageLogs({id: leadId, refreshVariable: refreshVariable});
  
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [showProductChange, setShowProductChange] = useState(false);
  const [showReassignmentRequest, setShowReassignmentRequest] = useState(false);

  let {
    setPageLoading,
    setPageError,
    setPageSuccess,
    setMessage,
  } = usePageValue();
  // setMessage("Loading");
  setPageLoading(isLoading);


  const breadcrumbs = [
    { label: "Dashboard", path: "/"},
    { label: "Leads", path: "/leads" },
    { label: "Leads Details" },
  ];


  const requestDropdownMenu = ({showReassign, showProduct}) =>(
    <Menu>
    
      <Menu.Item>
        <span onClick={() => showProduct(true)}>
          Product Change
          </span>
      </Menu.Item>

      <Menu.Item>
        <span onClick={()=> showReassign(true)}>
          Reassign Lead
          </span>
      </Menu.Item>
    </Menu>
  );

  return (
    <Drawer>
      <PageTitle title="Lead Detail" breadcrumbs={breadcrumbs} />
     
     

     {/* ============ Start Assign Lead Modal ==========*/}
     <Modal 
 visible={showAssignModal}
 title="Assign Lead"
//  onOk={setNextStageModal}
 onCancel={setShowAssignModal}
 formId="assignLeadForm"
 >
<AssignLead leadId={leadId} closeModal={setShowAssignModal}
refreshVariable={setRefreshVariable}/>
</Modal>
{/* ============ End Assign Lead Modal ==========*/}


{/* =========== REASSIGNMENT EXTENSION  ============*/}
<Modal 
 visible={showReassignmentRequest}
 title="Reassignment Request"
//  onOk={setNextStageModal}
 onCancel={setShowReassignmentRequest}
 formId="reassignmentRequest"
 >
<ReassignmentRequest leadId={leadId} stageId={lead.stageId}
closeModal={setShowReassignmentRequest}/>
</Modal>

{/* =========== PRODUCT CHANGE  ============*/}
<Modal 
 visible={showProductChange}
 title="Change Product Request"
//  onOk={setNextStageModal}
 onCancel={setShowProductChange}
 formId="reassignmentRequest"
 >
<ProductChange leadId={leadId} stageId={lead.stageId}
closeModal={setShowProductChange} oldProductId={lead.product? lead.product.id : ''}/>
</Modal>


    <Row gutter={24}>
    <Col span="10">
      <Row>
        <Col span={24} align="center" justify="center">
          <Avatar size="large">{getNameForAvatar(lead.companyName? lead.companyName : lead.customerName)}</Avatar>
        </Col>
        <Col span={24} align="center" justify="center" style={{marginTop: '10px'}}>
          <Text strong={true}> {lead.companyName? lead.companyName : lead.customerName}</Text>
        </Col>


        <Col span={24} align="center" justify="center" style={{margin: '20px'}}>
<Dropdown overlay={requestDropdownMenu({showReassign: setShowReassignmentRequest, showProduct: setShowProductChange})} placement="bottomLeft">
      <Button>Request 
      <DownOutlined />
      </Button>
    </Dropdown>
    &nbsp; &nbsp; 
    {!lead.assignee? (
 <Button primary onClick={() => setShowAssignModal(!showAssignModal)}> Assign</Button> 
    ): (<Link to={"/sales/"+leadId}>
    <Button type="primary"> View Lead</Button> 
    </Link>)}
   
    
    </Col>

      </Row>

      <Col span="24">
        <Row >
          <LabelAndNames label={"Phone"} value={lead.companyPhone? lead.companyPhone : lead.customerPhone} 
          backgroundColor = "#EBEBEB"/>

          <LabelAndNames
            label="Email"
            value={lead.companyEmail? lead.companyEmail: lead.customerEmail}
            backgroundColor="#FFF"
          />

          <LabelAndNames
            label="Address"
            value={lead.companyAddress? lead.companyAddress: lead.customerAddress}
            backgroundColor = "#EBEBEB"
          />
          <Divider />
          <LabelAndNames
            label="Category"
            value={lead.categoryName}
            backgroundColor="#FFF"
          />

          <LabelAndNames
            label="Deal Size"
            value={<NumberFormat value={Number(lead.dealSize).toFixed(2)} />}
            backgroundColor = "#EBEBEB"
          />

          <LabelAndNames label="Product" value={lead.productName}
          backgroundColor="#FFF"
          />

          <LabelAndNames
            label="Qualification"
            value={lead.qualification}
            backgroundColor = "#EBEBEB"
          />

          <Divider />
          <LabelAndNames
            label="Channel"
            value={lead.channelName}
            backgroundColor="#FFF"
          />

          <LabelAndNames label="Medium" value={lead.mediumName}
          backgroundColor = "#EBEBEB" />

        <LabelAndNames
            label="Assigned To"
            value={lead.assignee}
            backgroundColor="#FFF"
          />

        </Row>
      </Col>
    </Col>

    <Col span="14">
      <Tabs defaultActiveKey="1" centered>
        <TabPane tab="Lead Info" key="1">
          <LeadInfo title="Reason for Enquiry" description={lead.installationReason}/>

          <LeadInfo title="Background Information" description={lead.backgroundInfo}/>
        </TabPane>
        <TabPane tab="Stage Logs" key="2">
          {
            stageLogs && stageLogs.map((dat, index) => (
            <StageLogs
            stageName={dat.stageName}
            comment={dat.comment}
            createdAt={formatDate(dat.updated_at)}
          />
            ))
          }
          
        </TabPane>
        <TabPane tab="Tags" key="3">
          {
           lead.contacts && lead.contacts.map((cont) => (<Tags phoneNumber={cont.phoneNumber} email={cont.email}
            name={cont.name} contactType={cont.contactType}/>))
          }
          
        </TabPane>

        <TabPane tab="Assignment History" key="4">
          <AssignmentHistory leadId={leadId}/>
        </TabPane>
      </Tabs>
    </Col>
  </Row>

     {/* )} */}
    </Drawer>
  );
}

export const LabelAndNames = ({ label, value, backgroundColor }) => {
  return (
    <Col span="24" style={{ backgroundColor }}>
      <Text>{label}: </Text>
      <span style={{float: "right"}}>
      <Text strong> {value}</Text>
      </span>
    </Col>
    
  );
};

const LeadInfo = ({ title, description }) => {
  return (
    <Card title={title} style={{ width: "100%", marginTop: "20px" }}>
      <Text>{description}</Text>
    </Card>
  );
};

const StageLogs = ({ stageName, comment, createdAt }) => {
  return (
    <Card title={stageName} style={{ width: "100%", marginTop: "20px" }}>
      <Text><span dangerouslySetInnerHTML={setHtmlFormatting (comment)} /></Text>
      <span style={{ float: "right" }}>{createdAt}</span>
    </Card>
  );
};

const Tags = ({name, phoneNumber, email, contactType}) => {
  return (
    <Row>
      <Col span="4">
        <Avatar>{getAvatarText(name)}</Avatar>
      </Col>
      <Col>
        <Text>Name:</Text> <Text level={4}>{name}</Text>
        <br />
        <Text>Email:</Text> <Text level={4}>{email}</Text>
        <br />
        <Text>Phone Number:</Text> <Text level={4}>{phoneNumber}</Text>
        <br />
        <Text>Contact Type:</Text> <Text level={4}>{contactType}</Text>
      </Col>
    </Row>
  );
};

function AssignmentHistory ({leadId}){
  let {assignmentHistory} = useAssignmentHistory({leadId})

  const columns = [
    {
      id: 'assingmer',
      Header: 'Assigner',
      accessor: 'assigner',
      Cell: d => <span>{d.value.name}</span>
    },
    {
      id: 'assignee',
      Header: 'Assigned To',
      accessor: 'assignee',
      Cell: d => <span>{d.value.name}</span>
    },
    {
      id: 'dateAssigned',
      Header: 'Date Assigned',
      accessor: d=> (formatDate(d.created_at)),
      Cell: d =><span>{d.value}</span>,
    }
  ]
  return (
    <ReactTable
      columns={columns}
      filterable={false}
      data={assignmentHistory}
      // defaultPageSize={2}
      showPagination={false}
    />
    
  );
};
