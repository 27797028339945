import React, {useState, useEffect, useRef} from 'react'
import {Drawer, PageTitle} from '../../units/molecules';
import {ModalButton as CustomButton} from '../../units/atoms'
import { Form, Row, Input, Button, Select, Divider } from 'antd';
import 'antd/dist/antd.css';
import { useFetch, useMedium, useChannels, useReasonForEnquiry, useProducts} from '../../../hooks'
import {Redirect} from 'react-router-dom';

import {CreateCompany, CreateContact, CreateMedium, CreateChannel, Modal} from '../../units/molecules/modals'
import {FormGroup, FullFormGroup} from '../../units/utils';

import {useApi, usePageValue} from '../../../context';

const { Option } = Select;


export default function CreateLead(props) {

  let api = useApi()
  let {setPageLoading, isPageLoading, setPageError, setPageSuccess, setMessage} = usePageValue();

  let categories = useFetch({callbackFunction: api.getCategories, addLabel: true});
 
  // let  products = useFetch({callbackFunction: api.getProducts, addLabel: true});
  const {products} = useProducts();
  let contacts  = useFetch({callbackFunction: api.getContacts, addLabel: true});
  const {mediums}  = useMedium();
  const {channels} = useChannels();
  const [success, setSuccess ] = useState(false);
  let assigns = useFetch({callbackFunction: api.getSalesExecutives, addLabel: true});

  const [companyModal, setCompanyModal] = useState(false);

  const [channelModal, setChannnelModal] = useState(false);

  const [mediumModal, setMediumModal] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(2);

  const [contactModal, setContactModal] = useState(false);

  const [isB2B, setIsB2B] = useState(true);

  const [selectedReason, setSelectedReason] = useState();

  const {reasons} = useReasonForEnquiry({productGroupId: selectedProduct});

  const selectReasonRef = useRef(null);

  const { TextArea } = Input;

  const defaultSelectWidth = 500;

  useEffect(() => {
    
    return () => {
      return [products, contacts, TextArea]
     }
  }, [])
    const breadcrumbs = [
        {'label': 'Dashboard', 'path': '/'},
        {'label': 'Lead', 'path': '/leads'},
        {'label' : 'Create Lead'}
    ]

    const onFinish = values => {
      // console.log(values);

      let data = {
        category_id : values.category,
        company_id : values.company,
        channel_id : values.channel,
        medium_id : values.medium,
        industry_id: values.industry,
        product_id: values.product,
        background_info: values.backgroundInfo,
        // installation_reason: values.reason,
        installation_reason: selectedReason,
        contacts: (isB2B? values.contact : [values.contact]),
        // customer_id: [values.customer]
      
      }    
      
      console.log(data);
      const postForm = async() => {
        setPageLoading(true);
        let results = await api.createLead(data);

        let {statusCode} = results;
        setPageLoading(false);
        if (statusCode === 200) {
          setMessage('Lead Created')
          setPageSuccess(true);

          setTimeout(() => {
            setSuccess(true)
          }, 2000);
        } else{
          setMessage(results.error)
            setPageError(true);
        }
      }

      postForm();
    }

  const toggleCategory = (value) => {
    console.log('category value ', value)
    if(String(value) === '2'){
      setIsB2B(false);
  } else{
      setIsB2B(true);
  }
  }

  const handleProductChange = (selectedValue) => {
    console.log(selectedValue);
    let matchingProduct = products.find(prod => prod.value == selectedValue);

    console.log(selectReasonRef.current.props)
     
    // if(selectReasonRef.current.props.value){
    //   console.log('there is a value ');
    //   let oldProps = selectReasonRef.current.props;
    //   selectReasonRef.current.props = {...oldProps, value: undefined};
    // }
    

    console.log(matchingProduct.product_group_id);
    setSelectedProduct(matchingProduct.product_group_id);
    setSelectedReason();
    
  }
  

    return (
        <Drawer>
          {success && <Redirect to="/leads" />}
            <PageTitle title="Create Lead" breadcrumbs={breadcrumbs} />

 {/* =========== COMPANY MODAL  ============*/}
<Modal 
 visible={companyModal}
 title="New Company"
//  onOk={this.handleOk}
 onCancel={setCompanyModal}
 >
<CreateCompany />
</Modal>

{/*============== CONTACT MODAL ============*/}
<Modal 
 visible={contactModal}
 title="New Contact"
//  onOk={this.handleOk}
 onCancel={setContactModal}
 >
<CreateContact />
</Modal>


{/*============== MEDIUM MODAL ============*/}
<Modal 
 visible={channelModal}
 title="New Channel"
//  onOk={this.handleOk}
 onCancel={setChannnelModal}
 >
<CreateChannel />
</Modal>


{/* ============== CREATE LEAD FORM ================ */}
            <Form
      // {...layout}
      layout="vertical"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      size={'large'}
      // onFinishFailed={onFinishFailed}
    >

            <Row gutter={24}>
          <FormGroup 
            name={'category'}
            label={'Category'}
            required= {true}>
              
              <Select  style={{ width: defaultSelectWidth }} 
              onChange={toggleCategory}
              >
                { categories.data &&
                  categories.data.map(cat => (<Option value={cat.value}>{cat.label}</Option>))
                }
    </Select> 
            </FormGroup>


{ isB2B? (
  <AddMoreForm 
      defaultSelectWidth={defaultSelectWidth}
      name="company"
      label={'Company'}
      key="company"
      formId="companyForm"
      title="New Company"
      modal="company"
      callbackFunction={api.getCompanies}      
    />
): (<AddMoreForm 
  defaultSelectWidth={defaultSelectWidth}
  name="contact"
  label={'Customer'}
  key="customer"
  formId="contactForm"
  title="New Customer"
  modal="contact"
  callbackFunction={api.getContacts}      
/>)}    
    
                </Row>


                <Row gutter={24}>


{/* <AddMoreForm 
      defaultSelectWidth={defaultSelectWidth}
      name="channel"
      label={'Channel'}
      formId="channelForm"
      title="New Channel"
      modal="channel"
     callbackFunction={api.getChannels}      
    /> */}
   
   <FormGroup
    name="channel"
    label="Channel"
    required={true}
    >
       <Select
  showSearch
    mode={'single'}
  style={{ width: defaultSelectWidth }}
  // placeholder="Select a Medium"
  optionFilterProp="children"
  filterOption={(input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
>
        { 
          channels.map(cat => (<Option value={cat.value} title={`${cat.description}`}>{cat.label}</Option>))
        }
</Select> 
    </FormGroup>
       

    {/* <AddMoreForm 
      defaultSelectWidth={defaultSelectWidth}
      name="medium"
      label={'Medium'}
      formId="mediumForm"
      title="New Medium"
      modal="medium"
      button={<CustomButton label="New Medium" onClick={()=> setMediumModal(!mediumModal)} />}
      callbackFunction={api.getMediums}      
    /> */}

    <FormGroup
    name="medium"
    label="Medium"
    required={true}
    >
       <Select
  showSearch
    mode={'single'}
  style={{ width: defaultSelectWidth }}
  // placeholder="Select a Medium"
  optionFilterProp="children"
  filterOption={(input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
>
        { 
          mediums.map(cat => (<Option value={cat.value} title={`${cat.description}`}>{cat.label}</Option>))
        }
</Select> 
    </FormGroup>

    </Row>

    <Row gutter={24}>
            <FormGroup 
            name={'product'}
            label={'Product'}
            required= {true}>
              
          <Select
          showSearch
          style={{ width: defaultSelectWidth }}
          // placeholder="Select a Product"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={(e) => handleProductChange(e)}
        >
              
                { products.map(cat => (<Option value={cat.value}>{cat.label}</Option>))
                }
    </Select>
                
            </FormGroup>
    {isB2B? (
       <AddMoreForm 
       defaultSelectWidth={defaultSelectWidth}
       name="contact"
       label={'Contacts'}
       formId="contactForm"
       title="New Contact"
       modal="contact"
       selectMultiple={true}
       callbackFunction={api.getContacts}      
     />
    ): ('')}

    </Row>

<Row gutter={24}>

            <Divider orientation="left">More Information</Divider>
            <FullFormGroup 
            name={'reason'}
            label={'Reason For Enquiry'}
            required= {true}
            value={selectedReason}>
              {/* <TextArea placeholder="Their reason for needing this product is..." allowClear
             
              style={{width: '500px', height: '100px'}} maxLength={500}
               /> */}
               <Select 
               placeholder="Select reason for enquiry"
               style={{width: '650px'}} maxLength={500}
               value={selectedReason}
               onChange={(value) => setSelectedReason(value) }
               ref={selectReasonRef}
               >
                 {reasons.map(reason => (<Option value={reason.reason_for_enquiry} title={reason.reason_for_enquiry}
                 key={reason.reason_for_enquiry}>{reason.reason_for_enquiry}</Option>))}
               </Select>
            </FullFormGroup>

            <FormGroup 
            name={'backgroundInfo'}
            label={'Background Information'}
            required= {true}>
              <TextArea placeholder="Brief info about the company..." allowClear
              //  onChange={onChange} 
              style={{width: '650px', height: '100px'}} maxLength={500}
               />
            </FormGroup>
    
          
            </Row>

            <Form.Item >
        <Button type="primary" htmlType="submit" loading={isPageLoading}>
          Create lead
        </Button>
      </Form.Item>

        </Form>
      
        </Drawer>
    )
}


export const AddMoreForm = ({defaultSelectWidth, name, label, button, modal,
    formId, callbackFunction, title, selectMultiple}) => {
  
  const [modalContent, setModalContent] = useState(false);

  const [refresh, setRefresh] = useState(0);
  
  const [isLoading, setIsLoading] = useState(false);

  
  let medium = useFetch({callbackFunction, addLabel: true, refreshHook:refresh});

  function refreshComponent() {
    console.log('refreshing the component ' + refresh);
  
    setRefresh(refresh + 1);
  }

  useEffect(() => {
    return () => medium
  }, [name])

  const modals = {
    "medium" : <CreateMedium closeModal={setModalContent} refreshComp={refreshComponent} setLoading={setIsLoading}/>,
    "channel" : <CreateChannel closeModal={setModalContent} refreshComp={refreshComponent} setLoading={setIsLoading}/>,
    "company" : <CreateCompany closeModal={setModalContent} refreshComp={refreshComponent} setLoading={setIsLoading}/>,
    "contact": <CreateContact closeModal={setModalContent} refreshComp={refreshComponent}  setLoading={setIsLoading}/>
  }

  return(
<>
    <Modal 
formId={formId}
 visible={modalContent}
 title={title}
//  onOk={this.handleOk}
 onCancel={setModalContent}
 isLoading={isLoading}
 >
{/* <CreateMedium closeModal={setModalContent} refreshComp={refreshComponent}/> */}
{modals[modal]}
</Modal>

    <FormGroup 
    name={name}
    label={label}
    required= {true}
    // button={button}
    button={<CustomButton label={title} onClick={()=> setModalContent(!modalContent)} />}
    >
      
  <Select
  showSearch
    mode={selectMultiple? "multiple": 'single'}
  style={{ width: defaultSelectWidth }}
  // placeholder="Select a Medium"
  optionFilterProp="children"
  filterOption={(input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }
>
      
        { medium.data &&
          medium.data.map(cat => (<Option value={cat.value} >{cat.label}</Option>))
        }
</Select> 
    </FormGroup>
    </>
  )
}