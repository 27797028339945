import React from 'react';
import {Button} from 'antd'
import {PlusSquareOutlined} from '@ant-design/icons'


export default function ({onClick, label}) {
    return(
  <Button type="primary" size="small" onClick={onClick} style={{marginTop: '0px'}}>
           {/* {label} */}
           <PlusSquareOutlined style={{fontSize: '20px'}} />
          </Button>

    )
  }
