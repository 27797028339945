import React, {useMemo} from 'react';
import { Drawer, PageTitle } from '../../units/molecules';
import {useAllContacts} from '../../../hooks';
import {formatDate} from '../../../helpers';
import {ReactTable, Link} from '../../units/atoms';

export default function ViewContacts() {
    const breadcrumbs = [
        {'label': 'Dashboard', path: "/"},
        {'label' : 'View Companies'}
    ]

    let {contacts} = useAllContacts();

    const columns = useMemo(() => [
        {
            Header: 'Date Added',
            id: 'dateAdded',
            accessor: d=> (formatDate(d.created_at)),
            Cell: d =><span>{d.value}</span>,
        },
        {
            id: 'name',
            Header: 'Name',
            accessor: d => d.user.name,
            Cell: d =><span>{d.value}</span>,
        },
        {
            Header: 'Email',
            accessor: 'c_email'
        },
        {
            Header: 'Phone',
            accessor: 'phone_number'
        },
        {
            Header: 'Address',
            accessor: 'address'
        },
        {
            Header: 'City',
            accessor: 'city'
        },
        {
            id: 'state',
            Header: 'State',
            accessor: d => d.state? d.state.name: ''
        },
        {
            id: 'country',
            Header: 'Country',
            accessor: d => d.country? d.country.name: ''
        }
    ], contacts)
    return (
       <Drawer>
           <PageTitle title="Contacts" breadcrumbs={breadcrumbs} />
           <ReactTable columns={columns}
       filterable={true}
       data={contacts} 
       />
       </Drawer>
    )
}
