import _ from 'lodash';


export const addLabelToArray = (data) => {
    let returnData =  data.map(dat=>{
        if(dat.name){
            dat = {...dat, 'label': dat.name};
            return dat
        } else {
            dat = {...dat, 'label': dat.first_name};
            return dat;
        }
        
    })
    return returnData
}

export const isStage = (substageId, stageId) => {
    let endOfResponseTime = [1, 1];
    let endOfFirstStage = [2, 5];
    let endOfSecondStage = [3, 9];
    let endOfThirdStage = [4, 11];
    let endOfFourthStage = [5, 14];

    let thisStageAndSubstage = [stageId, substageId];

    if(
        (JSON.stringify(thisStageAndSubstage) === JSON.stringify(endOfFirstStage)) || 
          (JSON.stringify(thisStageAndSubstage) === JSON.stringify(endOfSecondStage)) ||
          (JSON.stringify(thisStageAndSubstage) === JSON.stringify(endOfThirdStage)) ||
          (JSON.stringify(thisStageAndSubstage) === JSON.stringify(endOfFourthStage) || 
          (JSON.stringify(thisStageAndSubstage) === JSON.stringify(endOfResponseTime)))

    ) {
        console.log('it matches this ', [substageId, stageId])
        return true;
    }

    return false;
}

export const stageNames = {
    1: 'Lead Response Time',
    2: 'Lead Introduction',
    3: 'Lead Qualification',
    4: 'Follow Up',
    5: 'Sale',
   
}

export const subStageNames = {
    1: 'Lead Response Time',
    2: 'Materials',
    3: 'Meetings',
    4: 'Key Influencer/Authority figure',
    5: 'Authority',
    6: 'Budget',
    7: 'Need', 
    8: 'Time',
    9: 'Demo Request',
    10: 'Negotiations',
    11: 'Invoicing',
    12: 'Approval Process'
}

export const stageAndSubstage = {
    'Lead Response Time' : [{}],

    'Introduction': [
        {substageNumber: 2, 
            substageName: 'Materials'},
        {substageNumber: 3,
            substageName: 'Meetings'},

        {substageNumber: 4, 
            substageName: 'Business Deal Size'}
    ],
    'Qualification':[

     {substageNumber: 5,
        substageName:'Authority' },
        
       {substageNumber: 6, 
    substageName:'Budget' },
        {substageNumber: 7, 
            substageName: 'Need'},
        {substageId: 8,
         substageName:'Time'}
    ], 
    'Follow Ups': [
       {substageNumber: 9, 
        substageName: 'Demo Request'},

        {substageNumber: 10, 
            substageName: 'Negotiations'},
        {substageNumber: 11,
            substageName: 'Invoicing'},
        {substageNumber: 12, 
            substageName: 'Approval Process'}
    ]
}

export const nextStageLabel = (stageId, substageId) => {
    // if( substageId == 4|| substageId == 8 || substageId == 12){
    //     return "Finish final comments for " + stageNames[Number(stageId) ]
    // }
    if ( 
    isStage(substageId, stageId)
    ) {
        console.log('end of stage');
        return "Finish " + stageNames[Number(stageId) ]
    }
    return "Finish "+subStageNames[substageId ];
}

export const roundUpValue = (dealSize) => {
    let integeredValue = parseInt(dealSize);
    let roundUpNumber = Math.ceil(integeredValue/100);
    return (roundUpNumber * 100);
  }

  export const setHtmlFormatting = (input) => {
      return {__html: `${input}`}
  }

  export  const getNameForAvatar = (input) => {
      if(!input){
          return;
      }
      let splitString = input.split(" ");
      let finalString = splitString.map(split => split.substring(0,1));
      return finalString.join('')
  }