import React from 'react';
import { PageHeader, Breadcrumb } from 'antd';
import {Link } from 'react-router-dom';
import "./pageTitle.css";

export default function PageTitle(props) {
    return (
        <>
           {/* <Breadcrumb>
    <Breadcrumb.Item>Home</Breadcrumb.Item>
    <Breadcrumb.Item>
      <a href="">Application Center</a>
    </Breadcrumb.Item>
    <Breadcrumb.Item>
      <a href="">Application List</a>
    </Breadcrumb.Item>
    <Breadcrumb.Item>An Application</Breadcrumb.Item>
  </Breadcrumb> */}


  {props.breadcrumbs && (
      <Breadcrumb>
        {
            props.breadcrumbs.map((bread, index, fullArray) => {
                if(fullArray[index + 1]){
                    return (
                        <Breadcrumb.Item>
                        <Link to={bread.path}>{bread.label}
                        </Link>
                        </Breadcrumb.Item>
                    )
                }

                return (
                    <Breadcrumb.Item>
                        {bread.label}
                        
                        </Breadcrumb.Item>
                )
            })
        }
      </Breadcrumb>
  )}
   <PageHeader
    className="site-page-header"
    // onBack={() => null}
    title={props.title}
    
    // subTitle="This is a subtitle"
  />
        </>
    )
}
