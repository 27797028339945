import {useState, useEffect} from 'react';
import {useApi} from '../context';

export function useVariables(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState([]);
    const [controlVariables, setControlVariables] = useState([]);
    const [uncontrolledVariables, setUncontrolledVariables] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getVariables();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setVariables(data)
                    setControlVariables(data.filter(dat => dat.type.toLowerCase() === 'controllable'));
                    setUncontrolledVariables(data.filter(dat=> dat.type.toLowerCase() === 'uncontrollable'));
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [variables, controlVariables, uncontrolledVariables, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {variables, controlVariables, uncontrolledVariables, isLoading, hasError}
}

export function useControlVariables() {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [variables, setVariables] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getControlVariables();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setVariables(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [variables, isLoading, hasError ]
        }
    }, [])

    return {variables, isLoading, hasError}
}