import {useState, useEffect} from 'react';
import {addLabelToArray, isStage} from '../helpers';
import {useApi} from '../context';

export function useUsers(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getUsers();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setUsers(data.reverse())
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [users, isLoading, hasError ]
        }
    }, [props])

    return {users, isLoading, hasError}
}

export function useRoles(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [roles, setRoles] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getRoles();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setRoles(addDisplayName(data))
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [roles, isLoading, hasError ]
        }
    }, [props])

    const addDisplayName = (data) => {
        let returnData =  data.map(dat=>{
            dat = {...dat, 'label': dat.display_name};
            return dat
        })
        return returnData
    }

    return {roles, isLoading, hasError}
}

export function useOneUser(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneUser(props.userId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    let firstIndex = data[0];
                    setUser({
                        name: firstIndex.name,
                        email: firstIndex.email,
                        phoneNumber: firstIndex.phone_number,
                        role: firstIndex.role.role_name.display_name
                    })
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [user, isLoading, hasError ]
        }
    }, [])

    return {user, isLoading, hasError}
}