import React from 'react';
import {Select} from 'antd';
import {useCompanies} from '../../../../hooks';

const {Option} = Select;
export default function CompanyDropdown({size, placeholder, width}) {
let {companies} = useCompanies();

    return (
        <Select
          
        size={size}
        placeholder={placeholder}
        style={{ width }}
      >
        {companies.map(company => (
            <Option key={company.id}>{company.name}</Option>
        ))}
      </Select>
    )
}
