import {useState, useEffect} from 'react';
import {addLabelToArray, isStage} from '../helpers';
import {useApi} from '../context';

export function useOneMeaningfulEngagement(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [engagements, setEngagements] = useState({});
    const [questionNumber, setQuestionNumber] = useState();
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getOneMeaningfulEngagement(props.stageId, props.substageId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    if(!Array.isArray(data)){
                    setEngagements(data.questions)
                    setQuestionNumber(data.question_type_id)
                    }
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 

            return [engagements , questionNumber]
        }
    }, [props.substageId])

    return {engagements, questionNumber, isLoading, hasError}
}

export function useAllMeaningfulEngagement(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [engagements, setEngagements] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAllMeaningfulEngagement(props.leadId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setEngagements(data)
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [engagements ]
        }
    }, [])

    return {engagements, isLoading, hasError}
}


export function useMeaningfulEngagementList(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [engagements, setEngagements] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getListOfMeaningfulEngagement();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setEngagements(data)
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [engagements ]
        }
    }, [])

    return {engagements, isLoading, hasError}
}

export function useBiQuestionnaire(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [questionniare, setQuestionnaire] = useState({});
    const [comment, setComment] = useState('');
    const [updatedAt, setUpdatedAt] = useState();
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getBIQuestionnaire(props.leadId);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    if(data[0]){
                        setQuestionnaire(data[0].answers);
                        setComment(data[0].comment);
                        setUpdatedAt(data[0].updated_at)
                    } else {
                        setQuestionnaire(data);
                    }
                    
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [questionniare, comment ]
        }
    }, [])

    return {questionniare, comment, isLoading, hasError}
}

export function useWeeklyEngagement(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [engagements, setEngagements] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getWeeklyEngagementReport(props.team);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setEngagements(data)
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [engagements ]
        }
    }, [props.team])

    return {engagements, isLoading, hasError}
}

export function useYearlyEngagementGraph(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [engagements, setEngagements] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getYearlyEngagementReport(props.team);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setEngagements(data)
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [engagements ]
        }
    }, [props.team])

    return {engagements, isLoading, hasError}
}

export function useExtensionRequestCount(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getExtensionRequestCount(props.team);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setCount(data)
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [count ]
        }
    }, [props.team])

    return {count, isLoading, hasError}
}

export function useAverageSalesCycleByTeam(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [cycle, setCycle] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAverageSalesCycleByTeam(props.team);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setCycle(data)
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [cycle ]
        }
    }, [props.team])

    return {cycle, isLoading, hasError}
}

export function useExtensionRequestByStages(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [extensionReport, setExtensionReport] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getExtensionReportByStages();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setExtensionReport(data)
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [extensionReport ]
        }
    }, [])

    return {extensionReport, isLoading, hasError}
}

export function useAverageSalesCycle(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [saleCycle, setSaleCycle] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAverageSalesCycle(2021);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setSaleCycle(data)
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [saleCycle ]
        }
    }, [])

    return {saleCycle, isLoading, hasError}
}

export function useTeamPipelineVelocity(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [teamPipeline, setTeamPipeline] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getTeamPipelineVelocity(props.team);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setTeamPipeline(data)
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [teamPipeline ]
        }
    }, [props.team])

    return {teamPipeline, isLoading, hasError}
}



export function useTeamPipelineVelocityGraph(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [teamPipelineGraph, setTeamPipelineGraph] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAveragePipelineVelocityGraph(props.team);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setTeamPipelineGraph(data)
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [teamPipelineGraph ]
        }
    }, [props.team])

    return {teamPipelineGraph, isLoading, hasError}
}

export function usePipelineVelocityAgainstSalesTargetGraph(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [velocityGraph, setVelocityGraph] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getPipelineVelocityAgainstSalesTargetGraph(props.team);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setVelocityGraph(data)
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [velocityGraph ]
        }
    }, [props.team])

    return {velocityGraph, isLoading, hasError}
}


export function useExecutivePipelineVelocity(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(false);
    const [executivePipeline, setExecutivePipeline] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getExecutivePipelineVelocity(props.executiveId, props.team);
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setExecutivePipeline([data])
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [executivePipeline ]
        }
    }, [props.executiveId, props.team])

    return {executivePipeline, isLoading, hasError}
}