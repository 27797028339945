import {useState, useEffect} from 'react';
import {useApi} from '../context';

export default function useCategories() {
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    let api = useApi();

         useEffect(() => {
        
            const fetchData = async() => {
                setIsLoading(true)
                let results = null;
    
                    results = await api.getCategories();
              
                if(results.statusCode === 200) {
                    let {data, message} = results;
    
                        setCategories(data)
                  
                } 
                else {
                    let {error} = results;
                    setHasError(error);
                }
                setIsLoading(false);
            }
    
            fetchData();
            return () => { 
                return [categories, isLoading, hasError ]
            }
        }, [])
    
        return {categories, isLoading, hasError}
   
}
