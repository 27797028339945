import React, {createContext, useContext, useState} from 'react';
import {setToken, setExpiresAt, getToken, getExpiresAt,
setUserId, setUserName, getUserName, getUserId}  from '../helpers'

const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const token = getToken();
    const expiresAt = getExpiresAt();
    const userName = getUserName();
    const userId = getUserId();

    const [authState, setAuthState] = useState({
        token, expiresAt, userName ,userId
    });

    
const isAuthenticated = () => {
    if(!authState.token || !authState.expiresAt) {
        console.log('is not authenticated');
        return false;
    }
    // console.log('expire token exists but');
    // console.log(new Date().getTime());
    // console.log(authState.expiresAt);
    return new Date().getTime()/1000 < authState.expiresAt;
}

const setLoginDetails = (token, expiresAt, name, userId) => {
    setToken(token);
    setExpiresAt(expiresAt);
    setUserName(name);
    setUserId(userId);
    setAuthState({token, expiresAt, name, userId});
}

    return (
        <AuthContext.Provider value={{token, isAuthenticated, setLoginDetails, expiresAt}}>
            {children}
        </AuthContext.Provider>
    )

}


const useAuth = () => {
    const context = useContext(AuthContext);

    if(context === undefined) {
        throw new Error( 'useAuth must be used within an AuthProvider');
    }

    return context;
}

export {AuthProvider, useAuth};