import React, {useState} from 'react';
import {InputWithMentions} from '../../atoms';
import {Form, Alert, Typography, Button} from 'antd';

import {FormGroup} from '../../utils';
import {useApi} from '../../../../context';

export default function AddLeadComment({stageId, substageId, leadId, ...props}) {
  let api = useApi();

  
  
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [success, setSuccess]  = useState(false);

  const onFinish = values => {
    console.log('logging this');
    console.log(values);
     
  
    
    // Move to the next substage
    const substageComment = async() => {
      setIsLoading(true)
     let data = {
         lead_id :leadId,
           subcomment: values.comment,
           comment: 'This is he dummy comment',
           stage_id: stageId,
           stage_category_id: substageId,
         //   priority: priority,
         //   stage: props.stageName,
         stage: 'Dummy Stage Name'
     }
     let results = await api.addLeadComment(data);
     let {statusCode} = results;
     setIsLoading(false);
     if(statusCode === 200) {
       setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 3000);
     }

    
    props.refreshVariable(Math.random() * 100)
     // Check if it is the end of a stage        
    
     }
     substageComment()
 }


    return (
        <div >
{success && <Alert message="Comment entered" type="success" showIcon />}
             <Form
      // {...layout}
      id="addCommentForm"
      layout="vertical"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      size={'large'}
      // onFinishFailed={onFinishFailed}
    >

<FormGroup 
    name={'comment'}
    label={'Enter Comments'}
    required= {true}>
        {/* <TextArea placeholder="Enter more details..." allowClear
        //  onChange={onChange} 
        style={{width: '450px', height: '100px'}}
        /> */}

        <InputWithMentions width={400} />
            </FormGroup>

            <Button key="submit" type="primary" 
            
          loading={isLoading} 
        //  onClick={() => document.forms[`addCommentForm`].onFinish}
         htmlType="submit" 
          >
            Submit
          </Button>
    </Form>
        </div>
    )
}
