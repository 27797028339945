import {useState, useEffect} from 'react';
import {addLabelToArray} from '../helpers';
import {useApi} from '../context';

export function useAllStages(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [allStages, setAllStages] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAllStages();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    let labelledData = (addLabelToArray(data));
                    setAllStages(labelledData)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [allStages, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {allStages, isLoading, hasError}
}


export function useAllTat(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [allTat, setAllTat] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getAllTat();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setAllTat(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [allTat, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {allTat, isLoading, hasError}
}

export function useRequalificationTAT(props) {
    let api = useApi();

    const [isLoading, setIsLoading] = useState(true);
    const [allTat, setAllTat] = useState([]);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        
        const fetchData = async() => {
            setIsLoading(true)
            let results = null;

                results = await api.getRequalificationTat();
          
            if(results.statusCode === 200) {
                let {data, message} = results;

                    // let labelledData = (addLabelToArray(data));
                    setAllTat(data)
              
            } 
            else {
                let {error} = results;
                setHasError(error);
            }
            // setIsLoading(false);
        }

        fetchData();
        return () => { 
            return [allTat, isLoading, hasError ]
        }
    }, [props.refreshPage])

    return {allTat, isLoading, hasError}
}