import React from 'react'
import PropTypes from 'prop-types';
import {Form, Button, Row, Input} from 'antd';
import 'antd/dist/antd.css';
import {FullFormGroup} from '../../utils' 

function CreateTagline(props) {
    
    const {TextArea} = Input;

    const onFinish = values => {
        console.log(values)
      }


    return (
        <div>
                 <Form
      // {...layout}
      layout="vertical"
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      size={'large'}
      // onFinishFailed={onFinishFailed}
    >
        <Row >
          <FullFormGroup 
            name={'name'}
            label={'Name'}
            required= {true}
            >
           <Input placeholder={"Telecommunication"} />
            </FullFormGroup>
        </Row> 

        <Row >
          <FullFormGroup 
            name={'name'}
            label={'Name'}
            required= {true}>
           
           <TextArea  allowClear
              //  onChange={onChange} 
              style={{width: '100%', height: '100px'}}
               />
               
            </FullFormGroup>
        </Row>
    </Form>
        </div>
    )
}

CreateTagline.propTypes = {

}

export default CreateTagline

