import React, {useState} from 'react';
import {useApi, usePageValue} from '../../../../context';
import {Form, Input, Checkbox, Typography, Col, Row, Button, DatePicker,
    Alert} from 'antd';
import {FormGroup} from '../../../units/utils';
import {Loader, Error, Success} from '../../../units/atoms'


const {TextArea} = Input;
const defaultSelectWidth = 400;

export default function AddReasonForNotRenewing(props) {
    let api = useApi();
    let [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [successMessage, setSuccessMessage] = useState();

    const onFinish = value => {
        setIsLoading(true);
        setErrorMessage();
        console.log(props)
        let data = {
            account_deal_id: props.accountDealId,
            renewal_account_id: props.renewalAccountId,
            comment: value.action
        }
        const postForm = async () => {
            let results = await api.addReasonForNotRenewing(data);
            let {statusCode} = results;
            setIsLoading(false)
            if(statusCode === 200) {
                 setSuccessMessage('Reason added');
                 setTimeout(() => {
                     form.resetFields();
                     props.refreshPage(Math.random() * 100);
                        props.closeModal(false);
                 }, 2000);
            }
            else{
                setErrorMessage(results.message)
            }
        }
        postForm()
    }

    return (
        <div>
            {isLoading && <Loader />}
            {errorMessage && <Error message={errorMessage} />}
            {successMessage && <Success message={successMessage} />}
           <Form
           form={form} 
           id="notRenewing"
           layout="vertical"
           initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          size={'large'}>

<FormGroup 
            name={'action'}
            label={'Reason'}
            required= {true}>
              
         <TextArea placeholder="" allowClear
             
             style={{width: '400px', height: '100px'}} />
            </FormGroup>
            
           </Form>
        </div>
    )
}
