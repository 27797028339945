import React from 'react';
import {Select} from 'antd';
import {useVariables} from '../../../../hooks';

const {Option} = Select;
export default function CompanyDropdown({size, name, placeholder, width}) {
let {variables} = useVariables();

    return (
        <Select
        size={size}
        placeholder={placeholder}
        style={{ width }}
      >
        {variables.map(variable => (
            <Option value={variable.id}>{variable.name}</Option>
        ))}
      </Select>
    )
}
